import React from 'react';
import MySpace from '../../components/mySpace/MySpace';
import IconRound from '../../components/iconRound/IconRound';
import MyButtonRound from '../../components/myButton/MyButtonRound';
import PlusBtnSVG from '../../icons/PlusBtnSVG';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Avatar, Tooltip } from 'antd';
import './Activity.scss';

const ActivityShare = () => {
  const currentUserId = useSelector((state) => {
    return state.currentUser?.idUser;
  });

  const db = useSelector((state) => {
    return state.currentDb;
  });

  const navigate = useNavigate();

  return (
    <>
      <MySpace className="activity-share-wrapper">
        <Avatar.Group>
          {db.accessUsers
            .filter((it) => currentUserId && it.idUser !== currentUserId)
            .map((us, index) => {
              return (
                <Tooltip key={index} title={us.displayName} placement="bottom">
                  <span>
                    <IconRound name={us.displayName} src={us.icon} width={30} />
                  </span>
                </Tooltip>
              );
            })}
        </Avatar.Group>
        <MyButtonRound
          onClick={() => navigate('/dbbudget/share')}
          className="share-icon-wrapper"
          icon={<PlusBtnSVG color="#984B82" />}
          disabled={db.idOwner !== currentUserId}
        />
      </MySpace>
    </>
  );
};

export default ActivityShare;
