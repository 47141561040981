import React from 'react';

const RedUpArrowSVG = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <rect opacity="0.15" width="16" height="16" rx="8" fill="#E2647B" />
      <mask
        id="mask0_3881_11739"
        maskUnits="userSpaceOnUse"
        x="3"
        y="3"
        width="10"
        height="10"
      >
        <rect
          x="3.54199"
          y="3.83325"
          width="8.625"
          height="8.625"
          fill="#2CB37A"
        />
      </mask>
      <g mask="url(#mask0_3881_11739)">
        <path
          d="M4.6877 11.6055L3.94826 11.1786L6.19924 7.24926L8.31192 8.46901L9.90319 5.74335L8.82015 6.03355L8.59691 5.20044L11.0962 4.53075L11.7659 7.03008L10.9328 7.25331L10.6426 6.17027L8.63003 9.6562L6.51734 8.43644L4.6877 11.6055Z"
          fill="#E2647B"
        />
      </g>
    </svg>
  );
};

export default RedUpArrowSVG;
