import useIsMounted from '@utilityjs/use-is-mounted/useIsMounted';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MySelect from '../mySelect/MySelect';

const SelectInvitePermissionValue = (props) => {
  const { withAll } = props;

  const isMounted = useIsMounted();
  const { t } = useTranslation();

  const [list, setlist] = useState([]);

  useEffect(() => {
    if (isMounted()) {
      let data = [
        { label: t('WORD_READ'), value: 1 },
        { label: t('WORD_WRITE'), value: 2 },
      ];
      if (withAll) data.unshift({ label: t('WORD_ALL'), value: '' });
      setlist(data);
    }
  }, [isMounted, t, withAll]);

  return <MySelect {...props} options={list} allowClear={false} />;
};

export default SelectInvitePermissionValue;
