import React from 'react';
import { Typography } from 'antd';

const { Text } = Typography;

const MyText = ({ children, size, bold, className, style, onClick }) => {
  return (
    <Text
      style={{ fontSize: size, ...style }}
      strong={bold || false}
      className={className}
      onClick={onClick}
    >
      {children}
    </Text>
  );
};

export default MyText;
