import React, { useState } from 'react';
import ForgotPasswordRequest from './ForgotPasswordRequest';
import ForgotPasswordInput4Digit from './ForgotPasswordInput4Digit';
import ForgotPasswordChange from './ForgotPasswordChange';

const ForgotPassword = () => {
  const [step, setStep] = useState(1);
  const [data, setData] = useState({});

  return (
    <>
      {step === 1 ? (
        <ForgotPasswordRequest
          onNext={(value) => {
            setData(value);
            setStep(2);
          }}
        />
      ) : step === 2 ? (
        <ForgotPasswordInput4Digit
          data={data}
          onComplete={(value) => {
            setData(value);
            setStep(3);
          }}
        />
      ) : (
        step === 3 && <ForgotPasswordChange data={data} />
      )}
    </>
  );
};

export default ForgotPassword;
