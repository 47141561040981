import { Layout } from 'antd';
import React, { memo } from 'react';
import './BlankLayout.scss';

const BlankLayout = (props) => {
  const { children, Top } = props;
  return (
    <Layout
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: Top ? 'start' : 'center',
        alignItems: 'center',
        paddingTop: Top ? 30 : 0,
      }}
      className="blank-layout-wrapper"
    >
      {children}
    </Layout>
  );
};

export default memo(BlankLayout);
