import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useIsMounted from '@utilityjs/use-is-mounted';
import MySelectFormItem from '../mySelectFormItem/MySelectFormItem';
import BudgetActions from '../../actions/BudgetActions';

const SelectCurrencyPlacement = (props) => {
  const { name, label, withAll, onChange, required, disabled } = props;

  const isMounted = useIsMounted();
  const { t } = useTranslation();

  const [list, setlist] = useState([]);

  useEffect(() => {
    if (isMounted()) {
      let data = BudgetActions.defaultCurrencyPlacements;
      if (withAll) data.unshift({ label: t('WORD_ALL'), value: '' });
      setlist(data);
    }
  }, [isMounted, t, withAll]);

  return (
    <MySelectFormItem
      name={name}
      label={label}
      options={list}
      onChange={onChange}
      required={required}
      disabled={disabled}
    />
  );
};

export default SelectCurrencyPlacement;
