import React from 'react';
import { Checkbox, Form } from 'antd';
import './CheckBoxFormItem.scss';

const CheckBoxFormItem = (props) => {
  const { name, label, onChange } = props;

  return (
    <Form.Item
      className="my-checkbox-form-item"
      name={name}
      valuePropName="checked"
      // wrapperCol={{ offset: 8, span: 16 }}
    >
      <Checkbox
        className="my-checkbox"
        onChange={(e) => {
          onChange && onChange(e.target.checked);
        }}
      >
        {label}
      </Checkbox>
    </Form.Item>
  );
};

export default CheckBoxFormItem;
