import React, { memo } from 'react';
import MyIcon from './MyIcon';

const SVGIcon = (props) => {
  const { color, size } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size || 14}
      height={size || 14}
      viewBox="0 0 19 18"
      fill={color}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.7007 16.4632C18.0915 16.4632 18.4087 16.7804 18.4087 17.1712C18.4087 17.562 18.0915 17.8792 17.7007 17.8792H10.8538C10.463 17.8792 10.1458 17.562 10.1458 17.1712C10.1458 16.7804 10.463 16.4632 10.8538 16.4632H17.7007ZM13.326 0.617048C13.3732 0.653864 14.9525 1.88108 14.9525 1.88108C15.5265 2.22282 15.9749 2.83359 16.1448 3.55671C16.3138 4.27227 16.191 5.01049 15.7974 5.63448C15.7948 5.63864 15.7921 5.64275 15.783 5.65499L15.7759 5.66444C15.712 5.74865 15.4343 6.09987 14.0324 7.8563C14.0193 7.87932 14.0046 7.90088 13.9885 7.92183C13.9648 7.95271 13.9391 7.98109 13.9116 8.00694C13.816 8.12733 13.715 8.25384 13.6088 8.38684L13.3937 8.65633C12.9497 9.21233 12.4231 9.87163 11.7983 10.6536L11.4777 11.0549C10.2716 12.5646 8.72687 14.4974 6.74807 16.973C6.31477 17.513 5.66529 17.8274 4.96766 17.8359L1.53239 17.8793H1.52295C1.19538 17.8793 0.910288 17.6546 0.833823 17.3346L0.0606758 14.0579C-0.0988625 13.3792 0.0597318 12.6787 0.494922 12.135L9.38753 1.01259C9.39131 1.00881 9.39414 1.00409 9.39792 1.00032C10.3731 -0.16554 12.1365 -0.33735 13.326 0.617048ZM8.39606 4.51899L1.60036 13.0195C1.43894 13.2215 1.37946 13.4821 1.43894 13.7322L2.08181 16.4557L4.95067 16.4198C5.22349 16.417 5.47554 16.2952 5.64263 16.0875C6.50308 15.011 7.58452 13.6579 8.69638 12.2665L9.08975 11.7743L9.48379 11.2812C10.5265 9.97623 11.5567 8.68688 12.4186 7.60768L8.39606 4.51899ZM10.4883 1.90374L9.2806 3.41261L13.3028 6.50041C14.077 5.53069 14.5863 4.89201 14.6334 4.83113C14.7882 4.58002 14.8487 4.22507 14.7675 3.88334C14.6844 3.53311 14.4663 3.23574 14.152 3.046C14.0849 2.99974 12.4943 1.76497 12.4452 1.72626C11.8467 1.2467 10.9735 1.32978 10.4883 1.90374Z"
      />
    </svg>
  );
};

const EditSVG = (props) => {
  return <MyIcon icon={<SVGIcon />} {...props} />;
};

export default memo(EditSVG);
