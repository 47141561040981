import React, { memo } from 'react';
import Icon from '@ant-design/icons';
import useMyTheme from '../theme/useMyTheme';

const MyIcon = (props) => {
  const { icon, size, onClick, rotate, isMainButtonIcon } = props;
  const { isDark } = useMyTheme();

  return (
    <Icon
      // style={{ cursor: 'default' }}
      component={() =>
        React.cloneElement(icon, {
          fill: isDark || isMainButtonIcon ? '#fff' : '#2e3e5f',
          color: isDark || isMainButtonIcon ? '#fff' : '#2e3e5f',
          size: size,
          rotate: rotate,
          ...props,
        })
      }
      onClick={onClick}
    />
  );
};

export default memo(MyIcon);
