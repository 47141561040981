import React, { memo, useEffect, useState } from 'react';
import { useForm } from 'antd/es/form/Form';
import useIsMounted from '@utilityjs/use-is-mounted/useIsMounted';
import { useTranslation } from 'react-i18next';
import UtilObject from '../../utils/UtilObject';
import CategoriesActions from '../../actions/CategoriesActions';
import UtilNotify from '../../utils/UtilNotify';
import { getRandomColor } from '../../components/defaultBudgetCategories/CategoriesDefaultColors';
import CategoryIconSelector from '../../components/defaultBudgetCategories/CategoryIconSelector';
import InputTextFormItem from '../../components/myInputForm/InputTextFormItem';
import GetCategoryIcons from '../../components/defaultBudgetCategories/GetCategoryIcons';
import SelectCategoryNature from '../../components/selects/SelectCategoryNature';
import SelectCategoryFrequency from '../../components/selects/SelectCategoryFrequency';
import InputNumberFormItem from '../../components/myInputForm/InputNumberFormItem';
import SelectAccount from '../../components/selects/SelectAccount';
import InputTextAreaFormItem from '../../components/myInputForm/InputTextAreaFormItem';
import MyColorPicker from '../../components/colorPicker/MyColorPicker';
import SelectCategoryTypeIncomeExpense from '../../components/selects/SelectCategoryTypeIncomeExpense';
import MySpace from '../../components/mySpace/MySpace';
import MyButtonSecondary from '../../components/myButton/MyButtonSecondary';
import MyButtonDelete from '../../components/myButton/MyButtonDelete';
import MyButtonMain from '../../components/myButton/MyButtonMain';
import FormApp from '../../components/formApp/FormApp';

const CategoryCreateNewContent = (props) => {
  const { isNew, item, isGroup, onChange, onDelete, visible, setvisible } =
    props;
  const [form] = useForm();
  const isMounted = useIsMounted();

  const { t } = useTranslation();

  const [saving, setsaving] = useState(false);
  const [icon, seticon] = useState(item?.icon);

  useEffect(() => {
    if (!UtilObject.isEmpty(item) && !isNew) {
      form.setFieldsValue({ ...item });
      seticon(item?.icon);
    } else {
      form.setFieldsValue({
        // idParent: isGroup ? 0 : item?.idCategory,
        idParent: item?.idCategory || 0,
        typeIncomeExpense:
          item?.typeIncomeExpense ||
          CategoriesActions.TYPEINCOMEEXPENSE.EXPENSE,
      });
      seticon('');
    }
  }, [form, item, isNew, isGroup, visible]);

  const onFinish = async (values) => {
    setsaving(true);
    let result;
    if (isNew) {
      result = await CategoriesActions.insert(values);
    } else {
      result = await CategoriesActions.update(values, item.idKey);
    }
    if (isMounted()) {
      setsaving(false);
      if (result?.success) {
        UtilNotify.notifySuccess(
          isNew ? 'WORD_CREATED_SUCCESSFULY' : 'WORD_SAVED_SUCCESSFULY'
        );
        onChange && onChange(Object.assign(isNew ? {} : item, result.data));
        close && close();
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
    }
  };

  const handleDelete = async () => {
    setsaving(true);
    let result = await CategoriesActions.delete(item?.idKey);
    if (isMounted()) {
      setsaving(false);
      if (result.success) {
        UtilNotify.notifywarning('WORD_DELETED_SUCCESSFULY');
        onDelete && onDelete(item);
        close();
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
    }
  };

  const close = () => {
    setvisible(false);
  };

  const handleHideCategory = async () => {
    setsaving(true);
    let result = await CategoriesActions.hide(item.idKey);
    if (isMounted()) {
      setsaving(false);
      if (result.success) {
        UtilNotify.notifywarning('WORD_HIDE_SUCCESSFULY');
        item.status = CategoriesActions.STATUS.HIDDEN;
        onDelete && onDelete(item);
        close();
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
    }
  };

  const handleShowCategory = async () => {
    setsaving(true);
    let result = await CategoriesActions.show(item.idKey);
    if (isMounted()) {
      setsaving(false);
      if (result.success) {
        UtilNotify.notifySuccess('WORD_SHOW_SUCCESSFULY');
        item.status = CategoriesActions.STATUS.ACTIVE;
        onDelete && onDelete(item);
        close();
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
    }
  };

  const formName = `edit-category_name_${item?.idKey || ''}`;

  return (
    <>
      <FormApp
        name={formName}
        onFinish={onFinish}
        form={form}
        initialValues={{
          categoryName: '',
          typeMainChild: isGroup
            ? CategoriesActions.TYPEMAINCHILD.MAIN
            : CategoriesActions.TYPEMAINCHILD.CHILD,
          icon: '',
          nature: CategoriesActions.NATURE.NONE,
          frequency: CategoriesActions.FREQUENCY.MONTHLY,
          ordering: 1000,
          categoryColor: getRandomColor(),
          idAccountConnect: '',
          idParent: item?.idCategory || 0,
        }}
      >
        <MySpace align="start">
          <CategoryIconSelector
            onChange={(label) => {
              seticon(label);
              form.setFieldsValue({ icon: label });
            }}
          >
            <div style={{ cursor: 'pointer' }}>
              <GetCategoryIcons icon={icon} />
            </div>
          </CategoryIconSelector>
          <InputTextFormItem
            name="categoryName"
            placeholder={
              isGroup ? t('WORD_CATEGORY_GROUP_NAME') : t('WORD_CATEGRY_NAME')
            }
            required
            style={{ width: 280 }}
          />
        </MySpace>
        {!isGroup && (
          <>
            <SelectCategoryNature
              name="nature"
              label={t('WORD_NATURE')}
              required
            />
            <SelectCategoryFrequency
              name="frequency"
              label={t('WORD_FREQUENCY')}
              hidden
            />
            <InputNumberFormItem
              name="defaultBudgetValue"
              label={t('WORD_AMOUNT')}
            />
            <SelectAccount
              name={'idAccountConnect'}
              label={t('WORD_CONNECT_ACCOUNT')}
              allowClear={true}
              // disabled={disableAccount}
              // onChangeItem={(e) => {
              //   form.setFieldsValue({ tag__idAccount: e.children });
              // }}
            />
          </>
        )}
        <InputTextAreaFormItem name="categoryNote" label={t('WORD_NOTES')} />
        <MyColorPicker name="categoryColor" label="Color" required />
        <SelectCategoryTypeIncomeExpense name="typeIncomeExpense" hidden />
        <InputTextFormItem name="icon" hidden />
        <InputTextFormItem name="categoryNote" hidden />
        <InputTextFormItem name="typeMainChild" hidden />
        <InputTextFormItem name="idParent" hidden />
        <InputTextFormItem name="ordering" hidden />
        <InputTextFormItem name="status" hidden />
      </FormApp>
      <MySpace fullWidth spaceBetween>
        {!isNew && (
          <MySpace>
            {Boolean(!isGroup) ? (
              item?.status === CategoriesActions.STATUS.HIDDEN ? (
                <MyButtonSecondary
                  onClick={handleShowCategory}
                  loading={saving}
                  label={t('WORD_SHOW')}
                />
              ) : (
                <MyButtonSecondary
                  onClick={handleHideCategory}
                  loading={saving}
                  label={t('WORD_HIDE')}
                />
              )
            ) : null}
            <MyButtonDelete
              onClick={handleDelete}
              loading={saving}
              label={t('WORD_DELETE')}
            />
            <div />
            <div />
          </MySpace>
        )}
        <MyButtonSecondary
          onClick={close}
          loading={saving}
          label={t('WORD_CANCEL')}
        />
        <MyButtonMain
          className="button button-span-white button-login"
          loading={saving}
          form={formName}
          label={t('WORD_OK')}
        />
      </MySpace>
    </>
  );
};

export default memo(CategoryCreateNewContent);
