import { initializeApp } from 'firebase/app';
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  getAdditionalUserInfo,
  OAuthProvider,
} from 'firebase/auth';

// const firebaseConfig = {
//   apiKey: 'AIzaSyCK6ngfQ7VWRHapYFPHAofdw-hju09i9vk',
//   authDomain: 'moneyscope-7d4f8.firebaseapp.com',
//   // authDomain: "app.moneyscope.ai",
//   projectId: 'moneyscope-7d4f8',
//   storageBucket: 'moneyscope-7d4f8.appspot.com',
//   messagingSenderId: '854330675513',
//   appId: '1:854330675513:web:ba5efbec2f7a231debd8e8',
// };

const firebaseConfig = {
  apiKey: 'AIzaSyCK6ngfQ7VWRHapYFPHAofdw-hju09i9vk',
  authDomain: 'moneyscope-7d4f8.firebaseapp.com',
  // authDomain: 'auth.moneyscope.ai',
  // authDomain: 'moneyscope-7d4f8.web.app',
  projectId: 'moneyscope-7d4f8',
  storageBucket: 'moneyscope-7d4f8.appspot.com',
  messagingSenderId: '854330675513',
  appId: '1:854330675513:web:e7112051dea811d2ebd8e8',
  measurementId: 'G-JXX3YH0F56',
};

const app = initializeApp(firebaseConfig);
const providerGoogle = new GoogleAuthProvider();
const providerApple = new OAuthProvider('apple.com');
const auth = getAuth(app);

const UtilFirebase = {
  STATUS: { PENDING: 0, ACTIVE: 1, BLOCKED: 2 },
  REGISTERTYPE: { OURSITE: 1, APPLE: 2, GOOGLE: 3 },
  loginGmail: async () => {
    try {
      let result = await signInWithPopup(auth, providerGoogle);
      if (result.hasOwnProperty('user')) {
        const details = getAdditionalUserInfo(result);
        return {
          success: true,
          data: {
            displayName: result.user.displayName,
            phoneNumber: result.user.phoneNumber,
            email: result.user.email,
            emailVerified: result.user.emailVerified,
            icon: result.user.photoURL,
            registerType: UtilFirebase.REGISTERTYPE.GOOGLE,
            uid: result.user.uid,
            lang: details.locale,
          },
        };
      }
    } catch (error) {
      return { success: false, errMsg: error.code };
    }
    return { success: false, errMsg: 'Error ' };
  },
  loginApple: async () => {
    try {
      let result = await signInWithPopup(auth, providerApple);
      if (result.hasOwnProperty('user')) {
        const details = getAdditionalUserInfo(result);
        return {
          success: true,
          data: {
            displayName: result.user.displayName,
            phoneNumber: result.user.phoneNumber,
            email: result.user.email,
            emailVerified: result.user.emailVerified,
            icon: result.user.photoURL,
            registerType: UtilFirebase.REGISTERTYPE.GOOGLE,
            uid: result.user.uid,
            lang: details.locale,
          },
        };
      }
    } catch (error) {
      return { success: false, errMsg: error.code };
    }
    return { success: false, errMsg: 'Error ' };
  },
};

export default UtilFirebase;
