import React, { memo } from 'react';
import MySpaceCompact from '../../components/mySpace/MySpaceCompact';
import MyText from '../../components/myText/MyText';
import UtilNumber from '../../utils/UtilNumber';

const TotalsRender = (props) => {
  const { value, label, color } = props;
  return (
    <MySpaceCompact direction="vertical">
      <MyText style={{ color: color }} className="value-label " bold>
        {label}
      </MyText>
      <MyText className="value-amount" bold>
        {UtilNumber.formatDouble(value)}
      </MyText>
    </MySpaceCompact>
  );
};

export default memo(TotalsRender);
