import { Avatar, Box, Card, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { BarChart } from '@mui/x-charts/BarChart';

const MaterialActivity = () => {
  const [
    value,
    // setValue
  ] = useState(dayjs('2022-04-17'));
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack
        direction="row"
        spacing={5}
        justifyContent="space-between"
        alignItems="center"
      >
        <Card sx={{ padding: 2, width: 200 }}>
          <DatePicker
            format="MMM"
            value={value}
            views={['month', 'year']}
            openTo="month"
          />
        </Card>
        <Stack direction="row" spacing={0.5}>
          <Avatar>N</Avatar>
          <Avatar>N</Avatar>
        </Stack>
      </Stack>
      <Stack direction="row" spacing={2} mt={2} sx={{ width: '79%' }}>
        <Card sx={{ width: 300, height: 150, padding: 2 }}>
          <Typography fontWeight="bold" fontSize={20} gutterBottom>
            Income
          </Typography>
          <Typography fontWeight="bold" fontSize={20} gutterBottom>
            0
          </Typography>
          <Stack direction="row" mt={3} spacing={1}>
            <Typography variant="subtitle1" sx={{ color: 'green' }}>
              0%
            </Typography>
            <Typography variant="subtitle1">from Jul 1</Typography>
          </Stack>
        </Card>
        <Card sx={{ width: 300, height: 150, padding: 2 }}>
          <Typography fontWeight="bold" fontSize={20} gutterBottom>
            Expense
          </Typography>
          <Typography fontWeight="bold" fontSize={20} gutterBottom>
            0
          </Typography>
          <Stack direction="row" mt={3} spacing={1}>
            <Typography variant="subtitle1" sx={{ color: 'red' }}>
              0%
            </Typography>
            <Typography variant="subtitle1">from Jul 1</Typography>
          </Stack>
        </Card>
      </Stack>
      <Stack direction="row" mt={7} spacing={5} sx={{ width: '70%' }}>
        <Stack direction="column" spacing={2}>
          <Card sx={{ width: 350, height: 130, padding: 2 }}>
            <Typography fontWeight="bold" fontSize={20} gutterBottom>
              Current balance
            </Typography>
            <Typography fontWeight="bold" fontSize={20} gutterBottom>
              1500
            </Typography>
            <Stack direction="row" spacing={1}>
              {/* <Typography variant="subtitle1" sx={{ color: 'red' }}>
              0%
            </Typography> */}
              <Typography variant="subtitle1">
                Funds available to budget
              </Typography>
            </Stack>
          </Card>
          <Card sx={{ width: 350, height: 130, padding: 2 }}>
            <Typography fontWeight="bold" fontSize={20} gutterBottom>
              Net worth
            </Typography>
            <Typography fontWeight="bold" fontSize={20} gutterBottom>
              51500
            </Typography>
            <Stack direction="row" spacing={1}>
              <Typography variant="subtitle1">Inflation rate</Typography>
              <Typography variant="subtitle1" sx={{ color: 'green' }}>
                0%
              </Typography>
            </Stack>
          </Card>
        </Stack>
        <Box>
          <Card>
            <BarChart
              series={[
                { data: [3, 4, 1, 6, 5], stack: 'A', label: 'series A1' },
                { data: [4, 3, 1, 5, 8], stack: 'A', label: 'series A2' },
                { data: [4, 2, 5, 4, 1], stack: 'B', label: 'series B1' },
                { data: [2, 8, 1, 3, 1], stack: 'B', label: 'series B2' },
                { data: [10, 6, 5, 8, 9], label: 'series C1' },
              ]}
              width={850}
              height={500}
            />
          </Card>
        </Box>
      </Stack>
    </LocalizationProvider>
  );
};

export default MaterialActivity;
