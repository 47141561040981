import React from 'react';
import colors from '../Color.scss';

const SuccessArrowSVG = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        opacity="0.1"
        d="M0 7C0 3.13401 3.13401 0 7 0C10.866 0 14 3.13401 14 7C14 10.866 10.866 14 7 14C3.13401 14 0 10.866 0 7Z"
        fill="#ACC930"
      />
      <path
        d="M9.44372 5.43115L6.12493 8.74993L4.99365 7.61865"
        stroke="url(#paint0_linear_3748_11657)"
        strokeWidth="1.05"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3748_11657"
          x1="4.99365"
          y1="8.74993"
          x2="4.99366"
          y2="2.49265"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={colors.successColor} />
          <stop offset="1" stopColor={colors.successColor} />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default SuccessArrowSVG;
