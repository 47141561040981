// import md5 from 'md5';
const { v4: uuidv4 } = require('uuid');

const UtilString = {
  getAvatarName: (name) => {
    if (name) {
      return `${name?.split(' ')[0][0].toUpperCase()}${
        name && name.includes(' ') ? name?.split(' ')[1][0] : ''
      }`;
    }
    return '';
  },
  getStringToColor: (string) => {
    let hash = 0;
    let i;
    /* eslint-disable no-bitwise */
    for (i = 0; i < string?.length; i += 1) {
      hash = string?.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#';
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value?.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
    return color;
  },
  getUniqueString: () => {
    // return uniqueString();
    // return md5(new Date().valueOf().toString() + Math.random().toString());
    const uniqueId = uuidv4();
    return uniqueId;
  },
};

export default UtilString;
