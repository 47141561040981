import { primaryColor } from './themeLightConfig';

const themeDarkConfig = {
  colorPrimary: primaryColor,
  borderRadius: 20,
  wireframe: false,
  controlHeight: 40,
  colorLink: primaryColor,
  // colorText: primaryColor,
  // colorBorder: primaryColor,

  colorIcon: 'white',
  colorIconHover: 'red',

  // colorBgTextActive: primaryColor,
};

export default themeDarkConfig;
