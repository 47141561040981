import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import MyModal from '../../../components/myModal/MyModal';
import FormApp from '../../../components/formApp/FormApp';
import InputTextFormItem from '../../../components/myInputForm/InputTextFormItem';
import { useTranslation } from 'react-i18next';
import InputNumberFormItem from '../../../components/myInputForm/InputNumberFormItem';
import SelectAccountType from '../../../components/selects/SelectAccountType';
import MySwitchFormItem from '../../../components/mySwitchFormItem/MySwitchFormItem';
import InputTextAreaFormItem from '../../../components/myInputForm/InputTextAreaFormItem';
import { useSelector } from 'react-redux';
import { useForm } from 'antd/es/form/Form';
import useIsMounted from '@utilityjs/use-is-mounted/useIsMounted';
import { useNavigate } from 'react-router-dom';
import AccountActions from '../../../actions/AccountActions';
import UtilNotify from '../../../utils/UtilNotify';
import MyButtonSecondary from '../../../components/myButton/MyButtonSecondary';
import MyButtonDelete from '../../../components/myButton/MyButtonDelete';
import MySpace from '../../../components/mySpace/MySpace';
import SelectCategory from '../../../components/selects/SelectCategory';
import { Form } from 'antd';
import AccountLoanSection from './AccountLoanSection';
import UtilDate from '../../../utils/UtilDate';

const AccountEditModal = forwardRef((props, ref) => {
  const { onChange } = props;

  const idCurrency = useSelector((state) => {
    return state.currentDbSettings.idCurrency;
  });

  const { t } = useTranslation();
  const [form] = useForm();
  const isMounted = useIsMounted();
  const navigate = useNavigate();

  const idType = Form.useWatch('idType', form);
  const accountName = Form.useWatch('accountName', form);

  const refModal = useRef();

  const [saving, setsaving] = useState(false);
  const [loading, setloading] = useState(true);
  const [visible, setvisible] = useState(false);
  const [idKey, setidKey] = useState('');

  useImperativeHandle(ref, () => ({
    edit: (idKey) => {
      setidKey(idKey);
      refModal.current.open();
    },
    close: () => {
      refModal.current.close();
    },
  }));

  useEffect(() => {
    const getOne = async () => {
      setloading(true);
      if (idKey) {
        let result = await AccountActions.getOneByidKey(idKey);
        if (isMounted()) {
          if (result.success) {
            form.setFieldsValue({
              ...result.data,
              currentBalanceNew: result.data?.currentBalance,
              loanStartDate: UtilDate.getDate(result.data?.loanStartDate),
            });
          } else {
            UtilNotify.notifyErrorServer(result.errMsg);
          }
        }
      } else {
        form?.resetFields();
      }
      setloading(false);
    };
    getOne();
  }, [idCurrency, form, idKey, isMounted, visible]);

  const onFinish = async (values) => {
    setsaving(true);
    let result;
    if (idKey) {
      result = await AccountActions.update(values, idKey);
    } else {
      values.ordering = 1000;
      values.isLinked = false;
      values.status = AccountActions.STATUS.ACTIVE;
      result = await AccountActions.insert(values);
    }
    if (isMounted()) {
      if (result.success) {
        UtilNotify.notifySuccess('WORD_SAVED_SUCCESSFULY');
        refModal.current.close();
        onChange && onChange(result.data);
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
      setsaving(false);
    }
  };

  const handleHideAccount = async () => {
    setsaving(true);
    let result = await AccountActions.hide(idKey);
    if (isMounted()) {
      setsaving(false);
      if (result.success) {
        UtilNotify.notifywarning('WORD_HIDE_SUCCESSFULY');
        refModal.current.close();
        navigate('/transaction/list');
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
    }
  };

  const handleShowAccount = async () => {
    setsaving(true);
    let result = await AccountActions.show(idKey);
    if (isMounted()) {
      setsaving(false);
      if (result.success) {
        UtilNotify.notifywarning('WORD_SHOW_SUCCESSFULY');
        refModal.current.close();
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
    }
  };

  const isLoanType = idType?.toString()?.startsWith(AccountActions.GROUP.LOAN);

  return (
    <MyModal
      ref={refModal}
      labelOk={t('WORD_APPLY')}
      loading={loading}
      saving={saving}
      onOk={() => form.submit()}
      onVisibleChange={(vis) => {
        setvisible(vis);
      }}
      title={idKey ? t('WORD_ACCOUNT_SETTINGS') : 'Create Account'}
      footerExtra={
        idKey && (
          <>
            {/* <MyButtonDelete
              onClick={handleDeleteAccount}
              label={t('WORD_DELETE')}
            /> */}
            {form.getFieldsValue(true).status ===
            AccountActions.STATUS.CLOSED ? (
              <MyButtonSecondary
                onClick={handleShowAccount}
                label={t('WORD_SHOW')}
              />
            ) : (
              <MyButtonDelete
                icon=" "
                label={t('WORD_CLOSE_ACCOUNT')}
                onClick={handleHideAccount}
              />
              // <MyButtonSecondary
              //   onClick={handleHideAccount}
              //   label={t('WORD_HIDE')}
              // />
            )}
          </>
        )
      }
    >
      <FormApp
        onFinish={onFinish}
        form={form}
        name={idKey}
        initialValues={{
          accountName: '',
          idCurrency: idCurrency,
          idType: 101,
          statingBalance: 0,
        }}
      >
        <MySpace
          className="account-edit-vertical-gap"
          direction="vertical"
          fullWidth
        >
          <InputTextFormItem
            name="accountName"
            label={'Label'}
            placeholder=" "
            required
          />
          <SelectAccountType name="idType" label={'Type'} required />
          {!isLoanType ? (
            <>
              {idKey ? (
                <InputNumberFormItem
                  name="currentBalanceNew"
                  label={t('WORD_CURRENT_BALANCE')}
                  fullWidth
                />
              ) : (
                <InputNumberFormItem
                  name="startingBalance"
                  label={t('WORD_CURRENT_BALANCE')}
                />
              )}
              <MySwitchFormItem
                className="switch-row"
                name="isExcludedFromBudget"
                label={t('WORD_NON_BUDGET_ACCOUNT')}
              />
            </>
          ) : null}
          <SelectCategory
            name="idCategory"
            label={t('WORD_CONNECTED_CATEGORY')}
            placeholder={accountName}
            allowClear
          />
          {isLoanType ? <AccountLoanSection form={form} /> : null}
          <InputTextAreaFormItem
            name="accountNote"
            label={t('WORD_ACCOUNT_NOTES')}
            placeholder=" "
          />
        </MySpace>
        <InputNumberFormItem name="idCurrency" hidden />
      </FormApp>
    </MyModal>
  );
});

export default AccountEditModal;
