import React, { useCallback, useEffect, useMemo, useState } from 'react';
import MyTable from '../../components/myTable/MyTable';
import { useTranslation } from 'react-i18next';
import useIsMounted from '@utilityjs/use-is-mounted/useIsMounted';
import TransactionActions from '../../actions/TransactionActions';
import UtilNotify from '../../utils/UtilNotify';
import GetTransactionIcons from '../../components/defaultBudgetCategories/GetTransactionIcons';
import HourglassSVG from '../../icons/HourglassSVG';

const ActivityTransactionSection = (props) => {
  const { date } = props;

  const { t } = useTranslation();
  const isMounted = useIsMounted();

  const [loading, setloading] = useState(true);
  const [list, setlist] = useState([]);

  const fill = useCallback(async () => {
    setloading(true);
    let result = await TransactionActions.fillPaginationList({
      search: '',
      pagination: {
        page: 0,
        perPage: 1000,
      },
      filter: {
        dateFrom: date,
        dateTo: date,
      },
      sort: '',
    });
    if (isMounted()) {
      if (result.success) {
        setlist(result.data);
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
      setloading(false);
    }
  }, [isMounted, date]);

  useEffect(() => {
    fill();
  }, [fill]);

  const columns = useMemo(() => {
    let col = [
      {
        title: ' ',
        dataIndex: 'Icon',
        width: '60px',
        render: (_, trr) => {
          return <GetTransactionIcons item={trr} />;
        },
      },
      {
        title: t('WORD_ACCOUNT'),
        dataIndex: 'accountName',
        key: 'accountName',
        align: 'left',
      },
      {
        title: t('WORD_CATEGORY'),
        dataIndex: 'categoryName',
        key: 'categoryName',
        render: (value, { accountNameTo, type, idParent }) => {
          if (type === TransactionActions.TYPE.TRANSFER) {
            if (parseInt(idParent) > 0) {
              return `Transfer From ${accountNameTo}`;
            } else {
              return `Transfer To ${accountNameTo}`;
            }
          } else {
            return value;
          }
        },
      },
      {
        title: t('WORD_AMOUNT'),
        dataIndex: 'value',
      },
      {
        title: t('WORD_CLEARED'),
        dataIndex: 'approved',
        key: 'approved',
        width: '100px',
        align: 'center',
        borderTopRightRadius: '20px',
        render: (value) => {
          return Boolean(value) ? null : <HourglassSVG />;
        },
      },
    ];
    return col;
  }, [t]);

  return (
    <MyTable
      rowKey={'idKey'}
      dataSource={list}
      columns={columns}
      loading={loading}
    />
  );
};

export default ActivityTransactionSection;
