import React, { useState } from 'react';
import MyCard from '../../../components/myCard/MyCard';
import LogoSVG from '../../../icons/LogoSVG';
import MyTextTitle from '../../../components/myText/MyTextTitle';
import MyTextSecondary from '../../../components/myText/MyTextSecondary';
import { Space } from 'antd';
import MyReactCodeInput from '../../../components/myReactCodeInput/MyReactCodeInput';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useIsMounted from '@utilityjs/use-is-mounted/useIsMounted';
import { useTranslation } from 'react-i18next';
import UtilObject from '../../../utils/UtilObject';
import md5 from 'md5';
import UsersActions from '../../../actions/UsersActions';
import UtilNotify from '../../../utils/UtilNotify';

const Activate = () => {
  const currentUser = useSelector((state) => {
    return state.currentUser;
  });

  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();

  const [saving, setSaving] = useState(false);

  let isEmpty = UtilObject.isEmpty(currentUser);

  const handleComplete = async (value) => {
    if (!isEmpty && currentUser.email) {
      setSaving(true);
      const code = md5(value);
      let result = await UsersActions.activate(code, currentUser.email);
      if (isMounted()) {
        if (result.success) {
          if (location.state && location.state.hasOwnProperty('from')) {
            navigate(location.state.from.pathname, { replace: true });
          } else {
            navigate('/home', { replace: true });
          }
        } else {
          UtilNotify.notifyErrorServer(result.errMsg);
        }
        setSaving(false);
      }
    }
  };

  const handleResendCode = async () => {
    if (!isEmpty && currentUser.email) {
      let result = await UsersActions.resendCode(currentUser.email);
      if (result.success) {
        UtilNotify.notifySuccess('WORD_CODE_RESEND_SUCCESSFULY');
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
    }
  };

  return (
    <MyCard className="login-card">
      <Space direction="vertical" size={'large'} style={{ marginTop: 10 }}>
        <LogoSVG size={50} />
        <Space
          direction="vertical"
          size={'middle'}
          style={{ marginBottom: 20, marginTop: 20 }}
        >
          <MyTextTitle size={30}>
            {t('WORD_PLEASE_CHECK_YOUR_EMAIL')}
          </MyTextTitle>
          <Space.Compact direction="vertical">
            <MyTextSecondary> {t('WORD_WE`VE_SENT_A_CODE_TO')}</MyTextSecondary>
            <MyTextSecondary>{currentUser.email}</MyTextSecondary>
          </Space.Compact>
        </Space>
        <MyReactCodeInput
          onComplete={handleComplete}
          loading={saving}
          onClick={handleResendCode}
        />
      </Space>
    </MyCard>
  );
};

export default Activate;
