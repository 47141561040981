import React from 'react';
import MyCard from '../../components/myCard/MyCard';
import MySpace from '../../components/mySpace/MySpace';
import MyText from '../../components/myText/MyText';
import { useTranslation } from 'react-i18next';
import UtilNumber from '../../utils/UtilNumber';
import MyTextSecondary from '../../components/myText/MyTextSecondary';
import {
  successColor,
  // errorColor
} from '../../theme/themeLightConfig';
import MyStatistic from '../../components/myStatistic/MyStatistic';

const ActivityBlockCard = (props) => {
  const { title, amount, label, hasPercent, percentValue } = props;
  const { t } = useTranslation();

  //  const color = () => {
  //    const colorNegative = `${errorColor}`;
  //    const colorPositive = `${successColor}`;
  //    const isPoistivePer = UtilNumber.getIsPercentagePositive(item?.percentage);
  //    if (isPoistivePer === 0) {
  //      if (item?.typeIncomeExpense === 1) {
  //        return colorPositive;
  //      } else {
  //        return colorNegative;
  //      }
  //    }
  //    if (isPoistivePer) {
  //      if (item?.typeIncomeExpense === 1) {
  //        return colorPositive;
  //      } else {
  //        return colorNegative;
  //      }
  //    } else {
  //      if (item?.typeIncomeExpense === 1) {
  //        return colorNegative;
  //      } else {
  //        return colorPositive;
  //      }
  //    }
  //  };

  return (
    <MyCard
      className="activity-amount-card"
      style={{ width: 300, height: 150 }}
    >
      <MySpace fullWidth direction="vertical">
        <MyText bold size={20}>
          {t(title)}
        </MyText>
        <MySpace>
          <MyStatistic
            style={{ fontWeight: 'bold' }}
            value={UtilNumber.formatDouble(amount)}
          />
        </MySpace>
        <MySpace>
          <MyTextSecondary>{t(label)}</MyTextSecondary>
          {hasPercent ? (
            <MyStatistic
              suffix="%"
              value={UtilNumber.formatDouble(percentValue)}
              style={{
                fontSize: 14,
                fontWeight: 500,
                color: successColor,
              }}
            />
          ) : null}
        </MySpace>
      </MySpace>
    </MyCard>
  );
};

export default ActivityBlockCard;
