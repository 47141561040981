import React from 'react';
import { Form } from 'antd';
import UtilNumber from '../../utils/UtilNumber';
import LoadingIconApp from '../loadingIconApp/LoadingIconApp';

const FormApp = ({
  children,
  name = 'FormApp',
  className = '',
  onFinish,
  initialValues,
  validateTrigger = ['onBlur', 'onChange'],
  size = 'middle',
  labelCol,
  wrapperCol,
  layout = 'vertical',
  autoComplete = 'off',
  form,
  loading,
  onFinishFailed,
  style,
}) => {
  const handleonFinishFailed = (error) => {
    console.warn('Error : ', error);
    onFinishFailed && onFinishFailed(error);
  };

  return (
    <Form
      name={name || UtilNumber.getNewUniqID()}
      className={`form-app ${className}`}
      labelCol={labelCol}
      wrapperCol={wrapperCol}
      initialValues={initialValues}
      onFinish={onFinish}
      onFinishFailed={handleonFinishFailed}
      autoComplete={autoComplete}
      scrollToFirstError
      requiredMark="optional"
      size={size}
      validateTrigger={validateTrigger}
      layout={layout}
      form={form}
      preserve={false}
      style={style}
    >
      {loading ? <LoadingIconApp /> : children}
    </Form>
  );
};

export default FormApp;
