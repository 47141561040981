const intState = {
  lang: 'en',
  sideMenuColapsed: false,
  currentUser: {},
  accessToken: '',
  currentDb: {},
  currentDbSettings: {},
  deviceKey: '',
  canEdit: true,
  selectedTheme: 'system',
};

const AuthReducer = (state = intState, action) => {
  switch (action.type) {
    case 'SET_LANG':
      return { ...state, lang: action.payload };
    case 'SET_DEVICEKEY':
      return { ...state, deviceKey: action.payload };
    case 'SET_CANEDIT':
      return { ...state, canEdit: action.payload };
    case 'SET_CURRENT_USER':
      return { ...state, currentUser: action.payload };
    case 'SET_ACCESS_TOKEN':
      return { ...state, accessToken: action.payload };
    case 'SET_SIDE_MENU_OPENED':
      return { ...state, sideMenuColapsed: action.payload };
    case 'SET_CURRENT_DB':
      return { ...state, currentDb: action.payload };
    case 'SET_CURRENT_DB_SETTINGS':
      return { ...state, currentDbSettings: action.payload };
    case 'SET_SELECTED_THEME':
      return { ...state, selectedTheme: action.payload };
    default:
      return state;
  }
};

export default AuthReducer;
