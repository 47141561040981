import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
// import PageNotFoundSVG from '../../images/PageNotFoundSVG';
// import './ErrorPage404.scss';

const ErrorPage404 = () => {
  const navigate = useNavigate();

  return (
    <div className="page-error">
      <div className="svg-div">{/* <PageNotFoundSVG /> */}</div>
      <div>
        <h1>404</h1>
        <p>Page not found</p>
        <div className="buttons-con">
          <div className="action-link-wrap">
            <Button
              className="link-button"
              onClick={() => {
                navigate(-1, { replace: true });
              }}
            >
              Go Back
            </Button>
            <Button
              className="link-button"
              onClick={() => {
                navigate('/home', { replace: true });
              }}
            >
              Go to Home Page
            </Button>
            <Button
              className="link-button"
              onClick={() => {
                navigate('/login', { replace: true });
              }}
            >
              Login
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage404;
