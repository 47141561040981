import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { Form, InputNumber } from 'antd';
import { useTranslation } from 'react-i18next';
// import UtilNumber from '../../utils/UtilNumber';
import './MyInputForm.scss';

const InputNumberFormItem = forwardRef(
  (
    {
      classNameInput = '',
      controls = false,
      className = '',
      label,
      name,
      onEscape,
      placeholder,
      required = false,
      hidden = false,
      readOnly,
      onChange,
      onPressEnter,
      onBlur,
      hasFeedback = false,
      errorMsg,
      autoFocus = false,
    },
    ref
  ) => {
    const refComponent = useRef();
    const { t } = useTranslation();

    useImperativeHandle(ref, () => ({
      focus: () => {
        refComponent.current.focus();
        refComponent.current.select();
      },
    }));

    // function formatValue(value) {
    //   return UtilNumber.formatDouble(value);
    // }
    // // ! formatting wrong

    // function parseValue(value) {
    //   return value.replaceAll(',', '');
    // }

    return (
      <Form.Item
        name={name}
        label={label}
        hidden={hidden}
        hasFeedback={hasFeedback}
        className={`app-input ${className}`}
        style={{ width: '100%', height: hidden ? 0 : undefined }}
        rules={[
          {
            required: required && !hidden,
            message:
              errorMsg ||
              `${t('WORD_VALUE_FOR')} ${label} ${t('WORD_IS_REQUAIRED')}`,
          },
        ]}
      >
        <InputNumber
          ref={refComponent}
          className={`my-input input-number ${classNameInput}`}
          placeholder={placeholder || label || ''}
          autoFocus={autoFocus}
          readOnly={readOnly}
          autoComplete={'nope'}
          onChange={onChange}
          onPressEnter={onPressEnter}
          controls={controls}
          onBlur={onBlur}
          style={{ width: '100%' }}
          type="number"
          // formatter={formatValue}
          // parser={parseValue}
          onFocus={() => {
            refComponent?.current?.select();
          }}
          onKeyUp={(e) => {
            if (e.key === 'Escape') {
              onEscape && onEscape();
            }
          }}
        />
      </Form.Item>
    );
  }
);

export default InputNumberFormItem;
