import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MySelectFormItem from '../mySelectFormItem/MySelectFormItem';
import useIsMounted from '@utilityjs/use-is-mounted/useIsMounted';

const SelectInvitePermissionForm = (props) => {
  const { name, label, withAll, required, className, style } = props;

  const isMounted = useIsMounted();
  const { t } = useTranslation();

  const [list, setlist] = useState([]);

  useEffect(() => {
    if (isMounted()) {
      let data = [
        { label: t('WORD_VIEW'), value: 1 },
        { label: t('WORD_EDIT'), value: 2 },
      ];
      if (withAll) data.unshift({ label: t('WORD_ALL'), value: '' });
      setlist(data);
    }
  }, [isMounted, t, withAll]);

  return (
    <MySelectFormItem
      name={name}
      label={label}
      options={list}
      required={required}
      className={className}
      allowClear={false}
      style={style}
    />
  );
};

export default SelectInvitePermissionForm;
