import { ColorPicker, Form } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './ColorPicker.scss';

const MyColorPicker = (props) => {
  const { color, onChange, hidden, name, label, required, placeholder } = props;

  const { t } = useTranslation();

  return (
    <div className="color-picker-wrapper">
      <Form.Item
        name={name}
        label={label}
        rules={[
          {
            required: required,
            message: `${placeholder || label} ${t('WORD_IS_REQUAIRED')}`,
          },
        ]}
        hidden={hidden}
        getValueFromEvent={(_, ee) => ee}
      >
        <ColorPicker
          value={color}
          onChange={(_, ee) => onChange && onChange(ee)}
          // showArrow={true}
          // style={{ width: '100%', height: '40px', borderRadius: '12px' }}
        />
      </Form.Item>
    </div>
  );
};

export default MyColorPicker;
