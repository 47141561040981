import ApiStorage from '../redux/ApiStorage';

const UtilFormats = {
  getUserNumberFormat: (value, toDivide) => {
    let numberFormat;

    const returnValue = (value, divide) => {
      if (isNaN(value)) {
        return value;
      }

      try {
        if (divide && toDivide) {
          value = value / 100;
        } else {
          value = Math.floor(value);
        }
        return numberFormat.format(value);
      } catch (error) {
        return value;
      }
    };

    let settings = ApiStorage.getCurrentDbSettings();

    let formatNumber = settings?.formatNumber || 11;

    switch (formatNumber) {
      case 1:
        numberFormat = new Intl.NumberFormat('en-US', {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
          minimumIntegerDigits: 1,
        });
        return returnValue(value, true);
      case 7:
        numberFormat = new Intl.NumberFormat('ja-JP', {
          maximumFractionDigits: 2,
        });
        return returnValue(value, false);
      case 9:
        numberFormat = new Intl.NumberFormat('ru-RU', {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        });
        return returnValue(value, true);
      case 11:
        numberFormat = new Intl.NumberFormat('ru-RU', {
          maximumFractionDigits: 2,
        });
        return returnValue(value, false);
      default:
        return value;
    }
  },
  getUserCurrencyFormat: (value) => {
    let settings = ApiStorage.getCurrentDbSettings();
    let currency = settings?.currencySymbol || '$';
    let operand = '';
    let currencyPlacement = settings?.currencyPlacement || 1;
    if (!value) {
      value = 0;
    }
    if (!currency) {
      currency = '';
    }
    if (typeof value === 'number') {
      value = value + '';
    }
    if (value[0] === '-') {
      value = value.replace('-', ' ');
      operand = '-';
    }
    value = value.replace(currency, ' ');
    switch (currencyPlacement) {
      case 1:
        return operand + currency + (value?.trim() || '');
      case 2:
        return operand + (value?.trim() || '') + currency;
      default:
        return operand + (value?.trim() || '');
    }
  },
  getUserCurrencyFormatWithValueForView: (value) => {
    value = UtilFormats.getUserNumberFormat(value);
    return UtilFormats.getUserCurrencyFormat(value);
  },
};

export default UtilFormats;
