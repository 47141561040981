import { Progress } from 'antd';
import React from 'react';
import UtilNumber from '../../utils/UtilNumber';
import './MyProgressBar.scss';

const MyProgressBar = (props) => {
  const {
    className,
    percent,
    size,
    shouldBePerc,
    totalSpent,
    totalBudgeted,
    color,
    isSelected,
    style,
  } = props;

  let per = UtilNumber.calcTwoNumbersPercentage(
    totalSpent || 0,
    totalBudgeted || 0
  );

  if (per > 100) per = 100;

  let overSpentPercentage =
    UtilNumber.calcTwoNumbersPercentage(
      totalSpent - totalBudgeted,
      totalBudgeted
    ) > 0
      ? UtilNumber.calcTwoNumbersPercentage(
          totalSpent - totalBudgeted,
          totalBudgeted
        )
      : 0;

  if (overSpentPercentage >= 100) {
    if (totalBudgeted) {
      overSpentPercentage =
        100 - UtilNumber.calcTwoNumbersPercentage(totalBudgeted, totalSpent);
    } else {
      overSpentPercentage = 100;
    }
  }

  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        // marginTop: 10,
        // width: '100%',
      }}
    >
      <Progress
        percent={percent || per}
        showInfo={false}
        size={size}
        style={style}
        className={
          isSelected
            ? 'my-progress-bar-selected'
            : `my-progress-bar ${className || ''}`
        }
        strokeColor={color || undefined}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: `${overSpentPercentage}%`,
          // backgroundColor: 'rgba(255, 153, 0, 1)',
          height: '100%',
          position: 'absolute',
          top: 0,
          right: 0,
          borderTopRightRadius: 100,
          borderBottomRightRadius: 100,
          borderTopLeftRadius: overSpentPercentage === 100 ? 100 : 0,
          borderBottomLeftRadius: overSpentPercentage === 100 ? 100 : 0,
        }}
      />
      {Boolean(shouldBePerc) && (
        <div
          style={{
            position: 'absolute',
            left: `${shouldBePerc}%`,
            width: 2,
            height: '130%',
            // backgroundColor: `${colors.secondaryColor}`,
          }}
        />
      )}
    </div>
  );
};

export default MyProgressBar;
