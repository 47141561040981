import React from 'react';
import MyDefaultIconImage from './MyDefaultIconImage';

const DATA = {
  StartingBalance: {
    icon: <MyDefaultIconImage url={require('./PNG/MoneyWithWings.png')} />,
    parent: [],
    title: 'MoneyWithWings',
  },
  Default: {
    icon: <MyDefaultIconImage url={require('./PNG/Default.png')} />,
    parent: [],
    title: 'Default',
  },
  Transfer: {
    icon: <MyDefaultIconImage url={require('./PNG/Transfer.png')} />,
    parent: [],
    title: 'Transfer',
  },
  Inflow: {
    icon: <MyDefaultIconImage url={require('./PNG/EuroBanknote.png')} />,
    parent: [],
    title: 'EuroBanknote',
  },
  OfficeBuilding: {
    icon: <MyDefaultIconImage url={require('./PNG/OfficeBuilding.png')} />,
    parent: [],
    title: 'OfficeBuilding',
  },
  Plus: {
    icon: <MyDefaultIconImage url={require('./PNG/Plus.png')} />,
    parent: [],
    title: 'Plus',
  },
  FileFolder: {
    icon: <MyDefaultIconImage url={require('./PNG/FileFolder.png')} />,
    parent: [],
    title: 'FileFolder',
  },
  RightArrowCurvingLeft: {
    icon: (
      <MyDefaultIconImage url={require('./PNG/RightArrowCurvingLeft.png')} />
    ),
    parent: [],
    title: 'RightArrowCurvingLeft',
  },
  EuroBanknote: {
    icon: <MyDefaultIconImage url={require('./PNG/EuroBanknote.png')} />,
    parent: [],
    title: 'EuroBanknote',
  },
  BookmarkTabs: {
    icon: <MyDefaultIconImage url={require('./PNG/BookmarkTabs.png')} />,
    parent: [],
    title: 'BookmarkTabs',
  },
  OldMan: {
    icon: <MyDefaultIconImage url={require('./PNG/OldMan.png')} />,
    parent: [],
    title: 'OldMan',
  },
  Ribbon: {
    icon: <MyDefaultIconImage url={require('./PNG/Ribbon.png')} />,
    parent: [],
    title: 'Ribbon',
  },
  BriefCase: {
    icon: <MyDefaultIconImage url={require('./PNG/BriefCase.png')} />,
    parent: [],
    title: 'BriefCase',
  },
  Laptop: {
    icon: <MyDefaultIconImage url={require('./PNG/Laptop.png')} />,
    parent: [],
    title: 'Laptop',
  },
  House: {
    icon: <MyDefaultIconImage url={require('./PNG/House.png')} />,
    parent: ['Housing', 'Insurance'],
    title: 'House',
  },
  MoneyWithWings: {
    icon: <MyDefaultIconImage url={require('./PNG/MoneyWithWings.png')} />,
    parent: ['Housing', 'Retirement', 'Gifts/Donations', 'Savings'],
    title: 'MoneyWithWings',
  },
  Receipt: {
    icon: <MyDefaultIconImage url={require('./PNG/Receipt.png')} />,
    parent: ['Housing', 'Transportation'],
    title: 'Receipt',
  },
  Bus: {
    icon: <MyDefaultIconImage url={require('./PNG/Bus.png')} />,
    parent: ['Transportation'],
    title: 'Bus',
  },
  Locomotive: {
    icon: <MyDefaultIconImage url={require('./PNG/Locomotive.png')} />,
    parent: ['Transportation'],
    title: 'Locomotive',
  },
  MiniBus: {
    icon: <MyDefaultIconImage url={require('./PNG/MiniBus.png')} />,
    parent: ['Transportation'],
    title: 'MiniBus',
  },
  RailwayCar: {
    icon: <MyDefaultIconImage url={require('./PNG/RailwayCar.png')} />,
    parent: ['Transportation'],
    title: 'RailwayCar',
  },
  Tram: {
    icon: <MyDefaultIconImage url={require('./PNG/Tram.png')} />,
    parent: ['Transportation'],
    title: 'Tram',
  },
  Trolleybus: {
    icon: <MyDefaultIconImage url={require('./PNG/Trolleybus.png')} />,
    parent: ['Transportation'],
    title: 'Trolleybus',
  },
  Taxi: {
    icon: <MyDefaultIconImage url={require('./PNG/Taxi.png')} />,
    parent: ['Transportation'],
    title: 'Taxi',
  },
  Scroll: {
    parent: ['Housing'],
    icon: <MyDefaultIconImage url={require('./PNG/Scroll.png')} />,
    title: 'Scroll',
  },
  HammerAndWrench: {
    icon: <MyDefaultIconImage url={require('./PNG/HammerAndWrench.png')} />,
    parent: ['Housing', 'Transportation'],
    title: 'HammerAndWrench',
  },
  HammerAndPick: {
    icon: <MyDefaultIconImage url={require('./PNG/HammerAndPick.png')} />,
    parent: ['Housing', 'Transportation'],
    title: 'HammerAndPick',
  },
  Plunger: {
    icon: <MyDefaultIconImage url={require('./PNG/Plunger.png')} />,
    parent: ['Housing'],
    title: 'Plunger',
  },
  Toilet: {
    parent: ['Housing'],
    icon: <MyDefaultIconImage url={require('./PNG/Toilet.png')} />,
    title: 'Toilet',
  },
  Clipboard: {
    icon: <MyDefaultIconImage url={require('./PNG/Clipboard.png')} />,
    parent: ['Housing', 'Transportation', 'Insurance'],
    title: 'Clipboard',
  },
  Automobile: {
    icon: <MyDefaultIconImage url={require('./PNG/Automobile.png')} />,
    parent: ['Transportation', 'Insurance'],
    title: 'Automobile',
  },
  SportUtilityVehicle: {
    icon: <MyDefaultIconImage url={require('./PNG/SportUtilityVehicle.png')} />,
    parent: ['Transportation', 'Insurance'],
    title: 'SportUtilityVehicle',
  },
  OnComingAutomobile: {
    icon: <MyDefaultIconImage url={require('./PNG/OnComingAutomobile.png')} />,
    parent: ['Transportation'],
    title: 'OnComingAutomobile',
  },
  PoliceCar: {
    icon: <MyDefaultIconImage url={require('./PNG/PoliceCar.png')} />,
    parent: ['Transportation'],
    title: 'PoliceCar',
  },
  PoliceCarLight: {
    icon: <MyDefaultIconImage url={require('./PNG/PoliceCarLight.png')} />,
    parent: ['Transportation'],
    title: 'PoliceCarLight',
  },
  PoliceOfficer: {
    icon: <MyDefaultIconImage url={require('./PNG/PoliceOfficer.png')} />,
    parent: ['Transportation'],
    title: 'PoliceOfficer',
  },
  Coin: {
    parent: ['Transportation', 'Retirement', 'Savings'],
    icon: <MyDefaultIconImage url={require('./PNG/Coin.png')} />,
    title: 'Coin',
  },
  MoneyBag: {
    icon: <MyDefaultIconImage url={require('./PNG/MoneyBag.png')} />,
    parent: ['Transportation', 'Savings'],
    title: 'MoneyBag',
  },
  Memo: {
    parent: ['Transportation'],
    icon: <MyDefaultIconImage url={require('./PNG/Memo.png')} />,
    title: 'Memo',
  },
  FuelPump: {
    icon: <MyDefaultIconImage url={require('./PNG/FuelPump.png')} />,
    parent: ['Transportation'],
    title: 'FuelPump',
  },
  DropOfBlood: {
    icon: <MyDefaultIconImage url={require('./PNG/DropOfBlood.png')} />,
    parent: ['Transportation'],
    title: 'DropOfBlood',
  },
  Wheel: {
    parent: ['Transportation'],
    icon: <MyDefaultIconImage url={require('./PNG/Wheel.png')} />,
    title: 'Wheel',
  },
  Wrench: {
    parent: ['Transportation', 'Housing'],
    icon: <MyDefaultIconImage url={require('./PNG/Wrench.png')} />,
    title: 'Wrench',
  },
  ScrewDriver: {
    icon: <MyDefaultIconImage url={require('./PNG/ScrewDriver.png')} />,
    parent: ['Transportation', 'Housing'],
    title: 'ScrewDriver',
  },
  OilDrum: {
    icon: <MyDefaultIconImage url={require('./PNG/OilDrum.png')} />,
    parent: ['Transportation'],
    title: 'OilDrum',
  },
  Gear: {
    parent: ['Transportation'],
    icon: <MyDefaultIconImage url={require('./PNG/Gear.png')} />,
    title: 'Gear',
  },
  NutAndBolt: {
    icon: <MyDefaultIconImage url={require('./PNG/NutAndBolt.png')} />,
    parent: ['Transportation'],
    title: 'NutAndBolt',
  },
  PageWithCurl: {
    icon: <MyDefaultIconImage url={require('./PNG/PageWithCurl.png')} />,
    parent: ['Transportation'],
    title: 'PageWithCurl',
  },
  PageFacingUp: {
    icon: <MyDefaultIconImage url={require('./PNG/PageFacingUp.png')} />,
    parent: ['Transportation'],
    title: 'PageFacingUp',
  },
  DollarBankNote: {
    icon: <MyDefaultIconImage url={require('./PNG/DollarBankNote.png')} />,
    parent: ['Transportation', 'Debt', 'Savings'],
    title: 'DollarBankNote',
  },
  RedApple: {
    icon: <MyDefaultIconImage url={require('./PNG/RedApple.png')} />,
    parent: ['Food'],
    title: 'RedApple',
  },
  Avocado: {
    icon: <MyDefaultIconImage url={require('./PNG/Avocado.png')} />,
    parent: ['Food'],
    title: 'Avocado',
  },
  Carrot: {
    parent: ['Food'],
    icon: <MyDefaultIconImage url={require('./PNG/Carrot.png')} />,
    title: 'Carrot',
  },
  Bread: {
    parent: ['Food'],
    icon: <MyDefaultIconImage url={require('./PNG/Bread.png')} />,
    title: 'Bread',
  },
  Broccoli: {
    icon: <MyDefaultIconImage url={require('./PNG/Broccoli.png')} />,
    parent: ['Food'],
    title: 'Broccoli',
  },
  CheeseWedge: {
    icon: <MyDefaultIconImage url={require('./PNG/CheeseWedge.png')} />,
    parent: ['Food'],
    title: 'CheeseWedge',
  },
  Cherries: {
    icon: <MyDefaultIconImage url={require('./PNG/Cherries.png')} />,
    parent: ['Food'],
    title: 'Cherries',
  },
  CutOfMeat: {
    icon: <MyDefaultIconImage url={require('./PNG/CutOfMeat.png')} />,
    parent: ['Food'],
    title: 'CutOfMeat',
  },
  Pear: {
    parent: ['Food'],
    icon: <MyDefaultIconImage url={require('./PNG/Pear.png')} />,
    title: 'Pear',
  },
  Tomato: {
    parent: ['Food'],
    icon: <MyDefaultIconImage url={require('./PNG/Tomato.png')} />,
    title: 'Tomato',
  },
  Hamburger: {
    icon: <MyDefaultIconImage url={require('./PNG/Hamburger.png')} />,
    parent: ['Food'],
    title: 'Hamburger',
  },
  Taco: {
    parent: ['Food'],
    icon: <MyDefaultIconImage url={require('./PNG/Taco.png')} />,
    title: 'Taco',
  },
  SteamingBowl: {
    icon: <MyDefaultIconImage url={require('./PNG/SteamingBowl.png')} />,
    parent: ['Food'],
    title: 'SteamingBowl',
  },
  Shrimp: {
    parent: ['Food'],
    icon: <MyDefaultIconImage url={require('./PNG/Shrimp.png')} />,
    title: 'Shrimp',
  },
  BentoBox: {
    icon: <MyDefaultIconImage url={require('./PNG/BentoBox.png')} />,
    parent: ['Food'],
    title: 'BentoBox',
  },
  HotDog: {
    parent: ['Food'],
    icon: <MyDefaultIconImage url={require('./PNG/HotDog.png')} />,
    title: 'HotDog',
  },
  GreenSalad: {
    icon: <MyDefaultIconImage url={require('./PNG/GreenSalad.png')} />,
    parent: ['Food'],
    title: 'GreenSalad',
  },
  Pizza: {
    parent: ['Food'],
    icon: <MyDefaultIconImage url={require('./PNG/Pizza.png')} />,
    title: 'Pizza',
  },
  Dog: {
    parent: ['Food'],
    icon: <MyDefaultIconImage url={require('./PNG/Dog.png')} />,
    title: 'Dog',
  },
  DogFace: {
    icon: <MyDefaultIconImage url={require('./PNG/DogFace.png')} />,
    parent: ['Food'],
    title: 'DogFace',
  },
  Poodle: {
    parent: ['Food'],
    icon: <MyDefaultIconImage url={require('./PNG/Poodle.png')} />,
    title: 'Poodle',
  },
  CatFace: {
    icon: <MyDefaultIconImage url={require('./PNG/CatFace.png')} />,
    parent: ['Food'],
    title: 'CatFace',
  },
  Cat: {
    parent: ['Food'],
    icon: <MyDefaultIconImage url={require('./PNG/Cat.png')} />,
    title: 'Cat',
  },
  BlackCat: {
    icon: <MyDefaultIconImage url={require('./PNG/BlackCat.png')} />,
    parent: ['Food'],
    title: 'BlackCat',
  },
  Frog: {
    parent: ['Food'],
    icon: <MyDefaultIconImage url={require('./PNG/Frog.png')} />,
    title: 'Frog',
  },
  TropicalFish: {
    icon: <MyDefaultIconImage url={require('./PNG/TropicalFish.png')} />,
    parent: ['Food'],
    title: 'TropicalFish',
  },
  Fish: {
    parent: ['Food'],
    icon: <MyDefaultIconImage url={require('./PNG/Fish.png')} />,
    title: 'Fish',
  },
  Turtle: {
    parent: ['Food'],
    icon: <MyDefaultIconImage url={require('./PNG/Turtle.png')} />,
    title: 'Turtle',
  },
  RabbitFace: {
    icon: <MyDefaultIconImage url={require('./PNG/RabbitFace.png')} />,
    parent: ['Food'],
    title: 'RabbitFace',
  },
  Rabbit: {
    parent: ['Food'],
    icon: <MyDefaultIconImage url={require('./PNG/Rabbit.png')} />,
    title: 'Rabbit',
  },
  MouseFace: {
    icon: <MyDefaultIconImage url={require('./PNG/MouseFace.png')} />,
    parent: ['Food'],
    title: 'MouseFace',
  },
  Hamster: {
    icon: <MyDefaultIconImage url={require('./PNG/Hamster.png')} />,
    parent: ['Food'],
    title: 'Hamster',
  },
  HedgeHog: {
    icon: <MyDefaultIconImage url={require('./PNG/HedgeHog.png')} />,
    parent: ['Food'],
    title: 'HedgeHog',
  },
  Parrot: {
    parent: ['Food'],
    icon: <MyDefaultIconImage url={require('./PNG/Parrot.png')} />,
    title: 'Parrot',
  },
  HighVoltage: {
    icon: <MyDefaultIconImage url={require('./PNG/HighVoltage.png')} />,
    parent: ['Utilities'],
    title: 'HighVoltage',
  },
  LightBulb: {
    icon: <MyDefaultIconImage url={require('./PNG/LightBulb.png')} />,
    parent: ['Utilities'],
    title: 'LightBulb',
  },
  Flashlight: {
    icon: <MyDefaultIconImage url={require('./PNG/Flashlight.png')} />,
    parent: ['Utilities'],
    title: 'Flashlight',
  },
  Droplet: {
    icon: <MyDefaultIconImage url={require('./PNG/Droplet.png')} />,
    parent: ['Utilities'],
    title: 'Droplet',
  },
  Wastebasket: {
    icon: <MyDefaultIconImage url={require('./PNG/Wastebasket.png')} />,
    parent: ['Utilities'],
    title: 'Wastebasket',
  },
  Bucket: {
    parent: ['Utilities', 'Housing'],
    icon: <MyDefaultIconImage url={require('./PNG/Bucket.png')} />,
    title: 'Bucket',
  },
  TelephoneReceiver: {
    icon: <MyDefaultIconImage url={require('./PNG/TelephoneReceiver.png')} />,
    parent: ['Utilities'],
    title: 'TelephoneReceiver',
  },
  Telephone: {
    icon: <MyDefaultIconImage url={require('./PNG/Telephone.png')} />,
    parent: ['Utilities'],
    title: 'Telephone',
  },
  MobilePhone: {
    icon: <MyDefaultIconImage url={require('./PNG/MobilePhone.png')} />,
    parent: ['Utilities', 'Entertainment'],
    title: 'MobilePhone',
  },
  Luggage: {
    icon: <MyDefaultIconImage url={require('./PNG/Luggage.png')} />,
    parent: ['Entertainment'],
    title: 'Luggage',
  },
  OrangeBook: {
    icon: <MyDefaultIconImage url={require('./PNG/OrangeBook.png')} />,
    parent: ['Entertainment'],
    title: 'OrangeBook',
  },
  AntennaBars: {
    icon: <MyDefaultIconImage url={require('./PNG/AntennaBars.png')} />,
    parent: ['Utilities'],
    title: 'AntennaBars',
  },
  ElectrigPlug: {
    icon: <MyDefaultIconImage url={require('./PNG/ElectrigPlug.png')} />,
    parent: ['Utilities'],
    title: 'ElectrigPlug',
  },
  Pill: {
    parent: ['Medical/Healthcare'],
    icon: <MyDefaultIconImage url={require('./PNG/Pill.png')} />,
    title: 'Pill',
  },
  Eye: {
    parent: ['Medical/Healthcare'],
    icon: <MyDefaultIconImage url={require('./PNG/Eye.png')} />,
    title: 'Eye',
  },
  Eyes: {
    parent: ['Medical/Healthcare'],
    icon: <MyDefaultIconImage url={require('./PNG/Eyes.png')} />,
    title: 'Eyes',
  },
  Stethoscope: {
    icon: <MyDefaultIconImage url={require('./PNG/Stethoscope.png')} />,
    parent: ['Medical/Healthcare', 'Insurance'],
    title: 'Stethoscope',
  },
  Hospital: {
    icon: <MyDefaultIconImage url={require('./PNG/Hospital.png')} />,
    parent: ['Medical/Healthcare', 'Insurance'],
    title: 'Hospital',
  },
  HealthWorker: {
    icon: <MyDefaultIconImage url={require('./PNG/HealthWorker.png')} />,
    parent: ['Medical/Healthcare', 'Insurance'],
    title: 'HealthWorker',
  },
  MedicalSymbol: {
    icon: <MyDefaultIconImage url={require('./PNG/MedicalSymbol.png')} />,
    parent: ['Medical/Healthcare', 'Insurance'],
    title: 'MedicalSymbol',
  },
  Dna: {
    parent: ['Medical/Healthcare'],
    icon: <MyDefaultIconImage url={require('./PNG/Dna.png')} />,
    title: 'Dna',
  },
  Crutch: {
    parent: ['Medical/Healthcare'],
    icon: <MyDefaultIconImage url={require('./PNG/Crutch.png')} />,
    title: 'Crutch',
  },
  AdhesiveBandage: {
    icon: <MyDefaultIconImage url={require('./PNG/AdhesiveBandage.png')} />,
    parent: ['Medical/Healthcare'],
    title: 'AdhesiveBandage',
  },
  Syringe: {
    icon: <MyDefaultIconImage url={require('./PNG/Syringe.png')} />,
    parent: ['Medical/Healthcare'],
    title: 'Syringe',
  },
  Tooth: {
    parent: ['Medical/Healthcare'],
    icon: <MyDefaultIconImage url={require('./PNG/Tooth.png')} />,
    title: 'Tooth',
  },
  Xray: {
    parent: ['Medical/Healthcare', 'Insurance'],
    icon: <MyDefaultIconImage url={require('./PNG/Xray.png')} />,
    title: 'Xray',
  },
  LiftingWeights: {
    icon: <MyDefaultIconImage url={require('./PNG/LiftingWeights.png')} />,
    parent: ['Personal'],
    title: 'LiftingWeights',
  },
  BarberPole: {
    icon: <MyDefaultIconImage url={require('./PNG/BarberPole.png')} />,
    parent: ['Personal'],
    title: 'BarberPole',
  },
  Running: {
    icon: <MyDefaultIconImage url={require('./PNG/Running.png')} />,
    parent: ['Personal'],
    title: 'Running',
  },
  Swimming: {
    icon: <MyDefaultIconImage url={require('./PNG/Swimming.png')} />,
    parent: ['Personal'],
    title: 'Swimming',
  },
  Biking: {
    parent: ['Personal'],
    icon: <MyDefaultIconImage url={require('./PNG/Biking.png')} />,
    title: 'Biking',
  },
  Skier: {
    parent: ['Personal'],
    icon: <MyDefaultIconImage url={require('./PNG/Skier.png')} />,
    title: 'Skier',
  },
  SoftBall: {
    icon: <MyDefaultIconImage url={require('./PNG/SoftBall.png')} />,
    parent: ['Personal'],
    title: 'SoftBall',
  },
  BoxingGlove: {
    icon: <MyDefaultIconImage url={require('./PNG/BoxingGlove.png')} />,
    parent: ['Personal'],
    title: 'BoxingGlove',
  },
  Tennis: {
    parent: ['Personal', 'Entertainment'],
    icon: <MyDefaultIconImage url={require('./PNG/Tennis.png')} />,
    title: 'Tennis',
  },
  GettingHaircutW: {
    icon: <MyDefaultIconImage url={require('./PNG/GettingHaircutW.png')} />,
    parent: ['Personal'],
    title: 'GettingHaircutW',
  },
  Scissors: {
    icon: <MyDefaultIconImage url={require('./PNG/Scissors.png')} />,
    parent: ['Personal'],
    title: 'Scissors',
  },
  GettingHaircutM: {
    icon: <MyDefaultIconImage url={require('./PNG/GettingHaircutM.png')} />,
    parent: ['Personal'],
    title: 'GettingHaircutM',
  },
  NailPolish: {
    icon: <MyDefaultIconImage url={require('./PNG/NailPolish.png')} />,
    parent: ['Personal'],
    title: 'NailPolish',
  },
  GettingMassage: {
    icon: <MyDefaultIconImage url={require('./PNG/GettingMassage.png')} />,
    parent: ['Personal'],
    title: 'GettingMassage',
  },
  Mouth: {
    parent: ['Personal'],
    icon: <MyDefaultIconImage url={require('./PNG/Mouth.png')} />,
    title: 'Mouth',
  },
  Lipstick: {
    icon: <MyDefaultIconImage url={require('./PNG/Lipstick.png')} />,
    parent: ['Personal'],
    title: 'Lipstick',
  },
  Coat: {
    parent: ['Clothing'],
    icon: <MyDefaultIconImage url={require('./PNG/Coat.png')} />,
    title: 'Coat',
  },
  Dress: {
    parent: ['Clothing'],
    icon: <MyDefaultIconImage url={require('./PNG/Dress.png')} />,
    title: 'Dress',
  },
  BackPack: {
    parent: ['Clothing'],
    icon: <MyDefaultIconImage url={require('./PNG/BackPack.png')} />,
    title: 'BackPack',
  },
  HandBag: {
    parent: ['Clothing'],
    icon: <MyDefaultIconImage url={require('./PNG/HandBag.png')} />,
    title: 'HandBag',
  },
  Necktie: {
    icon: <MyDefaultIconImage url={require('./PNG/Necktie.png')} />,
    parent: ['Clothing'],
    title: 'Necktie',
  },
  Jeans: {
    parent: ['Clothing'],
    icon: <MyDefaultIconImage url={require('./PNG/Jeans.png')} />,
    title: 'Jeans',
  },
  WomansClothing: {
    icon: <MyDefaultIconImage url={require('./PNG/WomansClothing.png')} />,
    parent: ['Clothing'],
    title: 'WomansClothing',
  },
  RunningShoe: {
    icon: <MyDefaultIconImage url={require('./PNG/RunningShoe.png')} />,
    parent: ['Clothing'],
    title: 'RunningShoe',
  },
  FlatShoeW: {
    icon: <MyDefaultIconImage url={require('./PNG/FlatShoeW.png')} />,
    parent: ['Clothing'],
    title: 'FlatShoeW',
  },
  FlatShoeM: {
    icon: <MyDefaultIconImage url={require('./PNG/FlatShoeM.png')} />,
    parent: ['Clothing'],
    title: 'FlatShoeM',
  },
  HighHeeledShoe: {
    icon: <MyDefaultIconImage url={require('./PNG/HighHeeledShoe.png')} />,
    parent: ['Clothing'],
    title: 'HighHeeledShoe',
  },
  TShirt: {
    parent: ['Clothing', 'Housing'],
    icon: <MyDefaultIconImage url={require('./PNG/TShirt.png')} />,
    title: 'TShirt',
  },
  Bed: {
    parent: ['Housing'],
    icon: <MyDefaultIconImage url={require('./PNG/Bed.png')} />,
    title: 'Bed',
  },
  Chair: {
    parent: ['Housing'],
    icon: <MyDefaultIconImage url={require('./PNG/Chair.png')} />,
    title: 'Chair',
  },
  CouchAndLamp: {
    parent: ['Housing'],
    icon: <MyDefaultIconImage url={require('./PNG/CouchAndLamp.png')} />,
    title: 'CouchAndLamp',
  },
  ShoppingBags: {
    parent: ['Housing'],
    icon: <MyDefaultIconImage url={require('./PNG/ShoppingBags.png')} />,
    title: 'ShoppingBags',
  },
  Socks: {
    parent: ['Clothing', 'Housing'],
    icon: <MyDefaultIconImage url={require('./PNG/Socks.png')} />,
    title: 'Socks',
  },
  Shorts: {
    parent: ['Clothing'],
    icon: <MyDefaultIconImage url={require('./PNG/Shorts.png')} />,
    title: 'Shorts',
  },
  HikingBoot: {
    icon: <MyDefaultIconImage url={require('./PNG/HikingBoot.png')} />,
    parent: ['Clothing'],
    title: 'HikingBoot',
  },
  BalletShoes: {
    icon: <MyDefaultIconImage url={require('./PNG/BalletShoes.png')} />,
    parent: ['Clothing'],
    title: 'BalletShoes',
  },
  CardIndexDividers: {
    icon: <MyDefaultIconImage url={require('./PNG/CardIndexDividers.png')} />,
    parent: ['Insurance'],
    title: 'CardIndexDividers',
  },
  HouseWithGarden: {
    icon: <MyDefaultIconImage url={require('./PNG/HouseWithGarden.png')} />,
    parent: ['Insurance'],
    title: 'HouseWithGarden',
  },
  DeliveryTruck: {
    icon: <MyDefaultIconImage url={require('./PNG/DeliveryTruck.png')} />,
    parent: ['Insurance'],
    title: 'DeliveryTruck',
  },
  Motorcycle: {
    icon: <MyDefaultIconImage url={require('./PNG/Motorcycle.png')} />,
    parent: ['Insurance'],
    title: 'Motorcycle',
  },
  MotorScooter: {
    icon: <MyDefaultIconImage url={require('./PNG/MotorScooter.png')} />,
    parent: ['Insurance'],
    title: 'MotorScooter',
  },
  RingBuoy: {
    icon: <MyDefaultIconImage url={require('./PNG/RingBuoy.png')} />,
    parent: ['Insurance'],
    title: 'RingBuoy',
  },
  ManualWheelChair: {
    icon: <MyDefaultIconImage url={require('./PNG/ManualWheelChair.png')} />,
    parent: ['Insurance'],
    title: 'ManualWheelChair',
  },
  InManualWheelChair: {
    icon: <MyDefaultIconImage url={require('./PNG/InManualWheelChair.png')} />,
    parent: ['Insurance'],
    title: 'InManualWheelChair',
  },
  LotionBottle: {
    icon: <MyDefaultIconImage url={require('./PNG/LotionBottle.png')} />,
    parent: ['Housing'],
    title: 'LotionBottle',
  },
  Razor: {
    parent: ['Housing'],
    icon: <MyDefaultIconImage url={require('./PNG/Razor.png')} />,
    title: 'Razor',
  },
  Bubbles: {
    icon: <MyDefaultIconImage url={require('./PNG/Bubbles.png')} />,
    parent: ['Housing'],
    title: 'Bubbles',
  },
  Soap: {
    parent: ['Housing'],
    icon: <MyDefaultIconImage url={require('./PNG/Soap.png')} />,
    title: 'Soap',
  },
  ToothBrush: {
    icon: <MyDefaultIconImage url={require('./PNG/ToothBrush.png')} />,
    parent: ['Housing'],
    title: 'ToothBrush',
  },
  Sponge: {
    parent: ['Housing'],
    icon: <MyDefaultIconImage url={require('./PNG/Sponge.png')} />,
    title: 'Sponge',
  },
  ForkAndKnifeWithPlate: {
    icon: (
      <MyDefaultIconImage url={require('./PNG/ForkAndKnifeWithPlate.png')} />
    ),
    parent: ['Housing'],
    title: 'ForkAndKnifeWithPlate',
  },
  Broom: {
    parent: ['Housing'],
    icon: <MyDefaultIconImage url={require('./PNG/Broom.png')} />,
    title: 'Broom',
  },
  CarpentrySaw: {
    icon: <MyDefaultIconImage url={require('./PNG/CarpentrySaw.png')} />,
    parent: ['Housing'],
    title: 'CarpentrySaw',
  },
  Toolbox: {
    icon: <MyDefaultIconImage url={require('./PNG/Toolbox.png')} />,
    parent: ['Housing'],
    title: 'Toolbox',
  },
  Axe: {
    parent: ['Housing'],
    icon: <MyDefaultIconImage url={require('./PNG/Axe.png')} />,
    title: 'Axe',
  },
  SpiralCalendar: {
    icon: <MyDefaultIconImage url={require('./PNG/SpiralCalendar.png')} />,
    parent: ['Debt', 'Gifts/Donations', 'Savings'],
    title: 'SpiralCalendar',
  },
  Bank: {
    parent: ['Debt'],
    icon: <MyDefaultIconImage url={require('./PNG/Bank.png')} />,
    title: 'Bank',
  },
  GraduationCap: {
    icon: <MyDefaultIconImage url={require('./PNG/GraduationCap.png')} />,
    parent: ['Debt'],
    title: 'GraduationCap',
  },
  Student: {
    icon: <MyDefaultIconImage url={require('./PNG/Student.png')} />,
    parent: ['Debt'],
    title: 'Student',
  },
  CreditCard: {
    icon: <MyDefaultIconImage url={require('./PNG/CreditCard.png')} />,
    parent: ['Debt', 'Savings'],
    title: 'CreditCard',
  },
  ChartDecreasing: {
    icon: <MyDefaultIconImage url={require('./PNG/ChartDecreasing.png')} />,
    parent: ['Retirement', 'Savings'],
    title: 'ChartDecreasing',
  },
  ChartIncreasing: {
    icon: <MyDefaultIconImage url={require('./PNG/ChartIncreasing.png')} />,
    parent: ['Retirement'],
    title: 'ChartIncreasing',
  },
  BarChart: {
    icon: <MyDefaultIconImage url={require('./PNG/BarChart.png')} />,
    parent: ['Retirement'],
    title: 'BarChart',
  },
  HeavyDollarSign: {
    icon: <MyDefaultIconImage url={require('./PNG/HeavyDollarSign.png')} />,
    parent: ['Retirement'],
    title: 'HeavyDollarSign',
  },
  BirthdayCake: {
    icon: <MyDefaultIconImage url={require('./PNG/BirthdayCake.png')} />,
    parent: ['Gifts/Donations'],
    title: 'BirthdayCake',
  },
  PartyPopper: {
    icon: <MyDefaultIconImage url={require('./PNG/PartyPopper.png')} />,
    parent: ['Gifts/Donations'],
    title: 'PartyPopper',
  },
  Balloon: {
    icon: <MyDefaultIconImage url={require('./PNG/Balloon.png')} />,
    parent: ['Gifts/Donations'],
    title: 'Balloon',
  },
  WrappedGift: {
    icon: <MyDefaultIconImage url={require('./PNG/WrappedGift.png')} />,
    parent: ['Gifts/Donations'],
    title: 'WrappedGift',
  },
  Sparkler: {
    icon: <MyDefaultIconImage url={require('./PNG/Sparkler.png')} />,
    parent: ['Gifts/Donations'],
    title: 'Sparkler',
  },
  ConfettiBall: {
    icon: <MyDefaultIconImage url={require('./PNG/ConfettiBall.png')} />,
    parent: ['Gifts/Donations'],
    title: 'ConfettiBall',
  },
  GemStone: {
    icon: <MyDefaultIconImage url={require('./PNG/GemStone.png')} />,
    parent: ['Gifts/Donations'],
    title: 'GemStone',
  },
  Ring: {
    parent: ['Gifts/Donations'],
    icon: <MyDefaultIconImage url={require('./PNG/Ring.png')} />,
    title: 'Ring',
  },
  WithVeil: {
    icon: <MyDefaultIconImage url={require('./PNG/WithVeil.png')} />,
    parent: ['Gifts/Donations'],
    title: 'WithVeil',
  },
  InTuxedo: {
    icon: <MyDefaultIconImage url={require('./PNG/InTuxedo.png')} />,
    parent: ['Gifts/Donations'],
    title: 'InTuxedo',
  },
  ChristmasTree: {
    icon: <MyDefaultIconImage url={require('./PNG/ChristmasTree.png')} />,
    parent: ['Gifts/Donations'],
    title: 'ChristmasTree',
  },
  Sparkles: {
    icon: <MyDefaultIconImage url={require('./PNG/Sparkles.png')} />,
    parent: ['Gifts/Donations'],
    title: 'Sparkles',
  },
  PalmUpHand: {
    icon: <MyDefaultIconImage url={require('./PNG/PalmUpHand.png')} />,
    parent: ['Gifts/Donations'],
    title: 'PalmUpHand',
  },
  HeartWithRibbon: {
    icon: <MyDefaultIconImage url={require('./PNG/HeartWithRibbon.png')} />,
    parent: ['Gifts/Donations'],
    title: 'HeartWithRibbon',
  },
  Warning: {
    icon: <MyDefaultIconImage url={require('./PNG/Warning.png')} />,
    parent: ['Savings'],
    title: 'Warning',
  },
  MusicalNote: {
    icon: <MyDefaultIconImage url={require('./PNG/MusicalNote.png')} />,
    parent: ['Entertainment'],
    title: 'MusicalNote',
  },
  ClapperBoard: {
    icon: <MyDefaultIconImage url={require('./PNG/ClapperBoard.png')} />,
    parent: ['Entertainment'],
    title: 'ClapperBoard',
  },
  WorldMap: {
    icon: <MyDefaultIconImage url={require('./PNG/WorldMap.png')} />,
    parent: ['Entertainment'],
    title: 'WorldMap',
  },
  FilmFrames: {
    icon: <MyDefaultIconImage url={require('./PNG/FilmFrames.png')} />,
    parent: ['Entertainment'],
    title: 'FilmFrames',
  },
  CounterClockWise: {
    icon: <MyDefaultIconImage url={require('./PNG/CounterClockWise.png')} />,
    parent: ['Entertainment'],
    title: 'CounterClockWise',
  },
  BottleWithPoppingCork: {
    icon: (
      <MyDefaultIconImage url={require('./PNG/BottleWithPoppingCork.png')} />
    ),
    parent: ['Entertainment'],
    title: 'BottleWithPoppingCork',
  },
  WineGlass: {
    icon: <MyDefaultIconImage url={require('./PNG/WineGlass.png')} />,
    parent: ['Entertainment'],
    title: 'WineGlass',
  },
  CocktailGlass: {
    icon: <MyDefaultIconImage url={require('./PNG/CocktailGlass.png')} />,
    parent: ['Entertainment'],
    title: 'CocktailGlass',
  },
  BeerMug: {
    icon: <MyDefaultIconImage url={require('./PNG/BeerMug.png')} />,
    parent: ['Entertainment'],
    title: 'BeerMug',
  },
  ClinkingGlasses: {
    icon: <MyDefaultIconImage url={require('./PNG/ClinkingGlasses.png')} />,
    parent: ['Entertainment'],
    title: 'ClinkingGlasses',
  },
  Pool8Ball: {
    icon: <MyDefaultIconImage url={require('./PNG/Pool8Ball.png')} />,
    parent: ['Entertainment'],
    title: 'Pool8Ball',
  },
  SlotMachine: {
    icon: <MyDefaultIconImage url={require('./PNG/SlotMachine.png')} />,
    parent: ['Entertainment'],
    title: 'SlotMachine',
  },
  SoccerBall: {
    icon: <MyDefaultIconImage url={require('./PNG/SoccerBall.png')} />,
    parent: ['Entertainment'],
    title: 'SoccerBall',
  },
  Basketball: {
    icon: <MyDefaultIconImage url={require('./PNG/Basketball.png')} />,
    parent: ['Entertainment'],
    title: 'Basketball',
  },
  JoyStick: {
    icon: <MyDefaultIconImage url={require('./PNG/JoyStick.png')} />,
    parent: ['Entertainment'],
    title: 'JoyStick',
  },
  Bowling: {
    icon: <MyDefaultIconImage url={require('./PNG/Bowling.png')} />,
    parent: ['Entertainment'],
    title: 'Bowling',
  },
  GameDie: {
    icon: <MyDefaultIconImage url={require('./PNG/GameDie.png')} />,
    parent: ['Entertainment'],
    title: 'GameDie',
  },
  SpadeSuit: {
    icon: <MyDefaultIconImage url={require('./PNG/SpadeSuit.png')} />,
    parent: ['Entertainment'],
    title: 'SpadeSuit',
  },
  FlagInHole: {
    icon: <MyDefaultIconImage url={require('./PNG/FlagInHole.png')} />,
    parent: ['Entertainment'],
    title: 'FlagInHole',
  },
  MovieCamera: {
    icon: <MyDefaultIconImage url={require('./PNG/MovieCamera.png')} />,
    parent: ['Entertainment'],
    title: 'MovieCamera',
  },
  Television: {
    icon: <MyDefaultIconImage url={require('./PNG/Television.png')} />,
    parent: ['Entertainment'],
    title: 'Television',
  },
  MusicalNotes: {
    icon: <MyDefaultIconImage url={require('./PNG/MusicalNotes.png')} />,
    parent: ['Entertainment'],
    title: 'MusicalNotes',
  },
  Saxophone: {
    icon: <MyDefaultIconImage url={require('./PNG/Saxophone.png')} />,
    parent: ['Entertainment'],
    title: 'Saxophone',
  },
  Trumpet: {
    icon: <MyDefaultIconImage url={require('./PNG/Trumpet.png')} />,
    parent: ['Entertainment'],
    title: 'Trumpet',
  },
  Guitar: {
    parent: ['Entertainment'],
    icon: <MyDefaultIconImage url={require('./PNG/Guitar.png')} />,
    title: 'Guitar',
  },
  Microphone: {
    icon: <MyDefaultIconImage url={require('./PNG/Microphone.png')} />,
    parent: ['Entertainment'],
    title: 'Microphone',
  },
  Globe: {
    parent: ['Entertainment', 'Utilities'],
    icon: <MyDefaultIconImage url={require('./PNG/Globe.png')} />,
    title: 'Globe',
  },
  SnowCappedMountain: {
    icon: <MyDefaultIconImage url={require('./PNG/SnowCappedMountain.png')} />,
    parent: ['Entertainment'],
    title: 'SnowCappedMountain',
  },
  BeachWithUmbrella: {
    icon: <MyDefaultIconImage url={require('./PNG/BeachWithUmbrella.png')} />,
    parent: ['Entertainment'],
    title: 'BeachWithUmbrella',
  },
  Camping: {
    icon: <MyDefaultIconImage url={require('./PNG/Camping.png')} />,
    parent: ['Entertainment'],
    title: 'Camping',
  },
  Airplane: {
    icon: <MyDefaultIconImage url={require('./PNG/Airplane.png')} />,
    parent: ['Entertainment'],
    title: 'Airplane',
  },
  MotorBoat: {
    icon: <MyDefaultIconImage url={require('./PNG/MotorBoat.png')} />,
    parent: ['Entertainment'],
    title: 'MotorBoat',
  },
  DesertIsland: {
    icon: <MyDefaultIconImage url={require('./PNG/DesertIsland.png')} />,
    parent: ['Entertainment'],
    title: 'DesertIsland',
  },
  Cigarette: {
    icon: <MyDefaultIconImage url={require('./PNG/Cigarette.png')} />,
    parent: ['Entertainment'],
    title: 'Cigarette',
  },
  Key: {
    icon: <MyDefaultIconImage url={require('./PNG/Key.png')} />,
    parent: ['Housing'],
    title: 'Key',
  },
  Label: {
    icon: <MyDefaultIconImage url={require('./PNG/Label.png')} />,
    parent: ['Housing'],
    title: 'Label',
  },
  ShoppingCart: {
    icon: <MyDefaultIconImage url={require('./PNG/ShoppingCart.png')} />,
    parent: ['Housing'],
    title: 'ShoppingCart',
  },
  BabyChick: {
    icon: <MyDefaultIconImage url={require('./PNG/BabyChick.png')} />,
    parent: ['Personal'],
    title: 'BabyChick',
  },
  Baby: {
    icon: <MyDefaultIconImage url={require('./PNG/Baby.png')} />,
    parent: ['Personal'],
    title: 'Baby',
  },
  BabySitter: {
    icon: <MyDefaultIconImage url={require('./PNG/BabySitter.png')} />,
    parent: ['Personal'],
    title: 'BabySitter',
  },
  Boy: {
    icon: <MyDefaultIconImage url={require('./PNG/Boy.png')} />,
    parent: ['Personal'],
    title: 'Boy',
  },
  Girl: {
    icon: <MyDefaultIconImage url={require('./PNG/Girl.png')} />,
    parent: ['Personal'],
    title: 'Girl',
  },
  Fire: {
    icon: <MyDefaultIconImage url={require('./PNG/Fire.png')} />,
    parent: ['Utilities'],
    title: 'Fire',
  },
};

export default DATA;
