import React, { useEffect } from 'react';
import MyCard from '../../components/myCard/MyCard';
import MyCollapse from '../../components/myCollapse/MyCollapse';
import { useSelector } from 'react-redux';
import { useForm } from 'antd/es/form/Form';
import { useTranslation } from 'react-i18next';
import UtilObject from '../../utils/UtilObject';
import CategoriesActions from '../../actions/CategoriesActions';
import UtilNotify from '../../utils/UtilNotify';
import MySpace from '../../components/mySpace/MySpace';
import MyTextTitle from '../../components/myText/MyTextTitle';
import FormApp from '../../components/formApp/FormApp';
import InputTextAreaFormItem from '../../components/myInputForm/InputTextAreaFormItem';
import MyDivider from '../../components/myDivider/MyDivider';

const formName = 'category-note-form';

const CategoryNoteSection = (props) => {
  const { item, onChange } = props;

  const { t } = useTranslation();
  const [form] = useForm();

  const canEdit = useSelector((state) => state.canEdit);

  useEffect(() => {
    if (!UtilObject.isEmpty(item)) {
      form.setFieldsValue({ categoryNote: item.categoryNote });
    }
  }, [item, form]);

  const onFinish = async (values) => {
    let result = await CategoriesActions.updateNote(values, item.idKey);
    if (result.success) {
      onChange && onChange(Object.assign(item, values));
    } else {
      UtilNotify.notifyErrorServer(result.errMsg);
    }
  };

  const getItem = () => {
    return {
      key: '1',
      label: (
        <MySpace fullWidth spaceBetween>
          <MyTextTitle size={14}>{t('WORD_NOTES')}</MyTextTitle>
        </MySpace>
      ),
      children: (
        <>
          <MyDivider />
          <FormApp
            name={formName}
            onFinish={onFinish}
            form={form}
            initialValues={{ categoryNote: '' }}
          >
            <InputTextAreaFormItem
              name="categoryNote"
              placeholder={t('WORD_ENTER_NOTE_HERE')}
              rows={2}
              bordered={false}
              style={{
                backgroundColor: 'transparent',
                boxShadow: 'none',
                padding: 0,
                resize: 'none',
              }}
              noStyle
              readOnly={!canEdit}
              allowClear={false}
              onBlur={() => {
                form.submit();
              }}
            />
          </FormApp>
        </>
      ),
    };
  };

  return (
    <MyCard className="auto-assign-card">
      <MyCollapse
        key={'1'}
        // loading={loading}
        expandIconPosition="end"
        items={[getItem()]}
        defaultActiveKey={'1'}
        collapsible="header"
      />
    </MyCard>
  );
};

export default CategoryNoteSection;
