import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import MyModal from '../../../components/myModal/MyModal';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm } from 'antd/es/form/Form';
import useIsMounted from '@utilityjs/use-is-mounted/useIsMounted';
import TransactionActions from '../../../actions/TransactionActions';
import UtilDate from '../../../utils/UtilDate';
import MyButtonDelete from '../../../components/myButton/MyButtonDelete';
import UtilNotify from '../../../utils/UtilNotify';
import FormApp from '../../../components/formApp/FormApp';
import InputNumberFormItem from '../../../components/myInputForm/InputNumberFormItem';
import {
  errorColor,
  successColor,
  primaryColor,
  whiteColor,
} from '../../../theme/themeLightConfig';
import MySegmentedForm from '../../../components/mySegmented/MySegmentedForm';
import SelectAccount from '../../../components/selects/SelectAccount';
import SelectCategory from '../../../components/selects/SelectCategory';
import UtilObject from '../../../utils/UtilObject';
import SelectCurrency from '../../../components/selects/SelectCurrency';
import InputFormDatePicker from '../../../components/inputFormDatePicker/InputFormDatePicker';
import SelectPayee from '../../../components/selects/SelectPayee';
import MySwitchFormItem from '../../../components/mySwitchFormItem/MySwitchFormItem';
import InputTextAreaFormItem from '../../../components/myInputForm/InputTextAreaFormItem';
import './TransactionEditModal.scss';

const TransactionEditModal = forwardRef((props, ref) => {
  const { onSave, trigger, account } = props;

  const canEdit = useSelector((state) => state.canEdit);

  const { t } = useTranslation();
  const [form] = useForm();
  const isMounted = useIsMounted();
  const refModal = useRef();

  const [saving, setsaving] = useState(false);
  const [idKey, setidKey] = useState('');
  const [loading, setloading] = useState(false);
  const [segmentValue, setSegmentValue] = useState(
    TransactionActions.TYPE.EXPENSE
  );

  useImperativeHandle(ref, () => ({
    modal: refModal.current,
    open: (idKey) => {
      setidKey('');
      setidKey(idKey);
      setTimeout(() => {
        refModal.current.open();
      }, 400);
    },
    close: () => {
      refModal.current.close();
    },
  }));

  useEffect(() => {
    const fill = async () => {
      setloading(true);
      if (idKey) {
        const resultGetOne = await TransactionActions.getOneByidKeyForEdit(
          idKey
        );
        if (isMounted) {
          if (resultGetOne.success) {
            form.setFieldsValue({
              ...resultGetOne.data,
              idAccountTo: resultGetOne.data.idAccountTo || '',
              idAccount: resultGetOne.data.idAccount || '',
              idPayee: resultGetOne.data.idPayee || '',
              idCategory: resultGetOne.data.idCategory || '',
              transactionDate: UtilDate.getDate(
                resultGetOne.data.transactionDate
              ),
            });
            setSegmentValue(resultGetOne.data.type);
          }
        }
      } else {
        form.resetFields();
      }
      setloading(false);
    };
    fill();
  }, [idKey, form, isMounted]);

  const onFinish = async (values) => {
    console.log('values = ', values);
    setsaving(true);
    // if (values.type === TransactionActions.TYPE.INCOME) {
    //   values.valueExpense = 0;
    //   values.valueIncome = Math.abs(values.value);
    // } else {
    //   values.valueExpense = Math.abs(values.value);
    //   values.valueIncome = 0;
    // }

    // if (values.type === TransactionActions.TYPE.TRANSFER) {
    //   values.idCategory = 0;
    //   values.idPayee = 0;
    // } else {
    //   values.idAccountTo = 0;
    // }

    // if (
    //   UtilDate.getDate(values.transactionDate).isAfter(UtilDate.getDateToday())
    // ) {
    //   values.status = TransactionActions.STATUS.SCHEDULED;
    // } else {
    //   values.status = TransactionActions.STATUS.ACTIVE;
    // }
    // let result;
    // if (idKey) {
    //   result = await TransactionActions.update(values, idKey);
    // } else {
    //   result = await TransactionActions.insert(values);
    // }
    let result = await TransactionActions.saveTransactionForm(values, idKey);
    if (isMounted()) {
      if (result.success) {
        if (!idKey) {
          form.resetFields();
        }
        UtilNotify.notifySuccess('WORD_SAVED_SUCCESSFULY');
        onSave && onSave(result.data);
        // ApiStorage.refreshAccountListSideMenu();
        refModal.current.close();
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
      setsaving(false);
    }
  };

  const handleDelete = async () => {
    let result = await TransactionActions.delete(idKey);
    if (isMounted()) {
      if (result.success) {
        if (!idKey) {
          form.resetFields();
        }
        UtilNotify.notifySuccess('WORD_SAVED_SUCCESSFULY');
        onSave && onSave(result.data);
        // ApiStorage.refreshAccountListSideMenu();
        refModal.current.close();
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
      setsaving(false);
    }
  };

  return (
    <MyModal
      ref={refModal}
      trigerText={trigger}
      saving={saving}
      forceRender={false}
      destroyOnClose={true}
      onOk={() => form.submit()}
      disableOk={!canEdit}
      title={idKey ? t('WORD_EDIT_TRANSACTION') : t('WORD_ADD_TRANSACTION')}
      labelOk="Save"
      onVisibleChange={(v) => {
        if (!v) {
          setidKey('');
          setSegmentValue(TransactionActions.TYPE.EXPENSE);
          form.resetFields();
        }
      }}
      footerExtra={
        idKey && (
          <>
            <MyButtonDelete onClick={handleDelete} label={t('WORD_DELETE')} />
          </>
        )
      }
    >
      <FormApp
        onFinish={onFinish}
        form={form}
        loading={loading}
        initialValues={{
          idCategory: '',
          idAccount: account?.idAccount || '',
          idAccountTo: '',
          idCurrency: account?.idCurrency || '',
          idPayee: '',
          type: segmentValue,
          value: '-',
          transactionNote: '',
          approved: false,
          transactionDate: UtilDate.getDateToday(),
          // transactionDate: dayjs(),

          valueIncome: 0,
          valueExpense: 0,
        }}
      >
        <InputNumberFormItem
          className="transaction-number-input"
          name="value"
          required
          readOnly={!canEdit}
          autoFocus={true}
          onPressEnter={() => {}}
          onChange={(v) => {
            if (segmentValue !== TransactionActions.TYPE.TRANSFER) {
              let typ = segmentValue;
              if (v > 0) {
                typ = TransactionActions.TYPE.INCOME;
              } else if (v < 0) {
                typ = TransactionActions.TYPE.EXPENSE;
              }
              setSegmentValue(typ);
              form.setFieldsValue({ type: typ });
            }
          }}
        />
        <MySegmentedForm
          className="transactions-segment-form"
          options={[
            {
              label: (
                <div
                  className="transaction-segment-item"
                  style={{
                    background:
                      segmentValue === TransactionActions.TYPE.EXPENSE
                        ? errorColor
                        : '',
                    color:
                      segmentValue === TransactionActions.TYPE.EXPENSE
                        ? whiteColor
                        : '',
                  }}
                >
                  {t('WORD_OUTFLOW')}
                </div>
              ),
              value: TransactionActions.TYPE.EXPENSE,
            },
            {
              label: (
                <div
                  className="transaction-segment-item"
                  style={{
                    background:
                      segmentValue === TransactionActions.TYPE.INCOME
                        ? successColor
                        : '',
                    color:
                      segmentValue === TransactionActions.TYPE.INCOME
                        ? whiteColor
                        : '',
                  }}
                >
                  {t('WORD_INFLOW')}
                </div>
              ),
              value: TransactionActions.TYPE.INCOME,
            },
            {
              label: (
                <div
                  className="transaction-segment-item"
                  style={{
                    background:
                      segmentValue === TransactionActions.TYPE.TRANSFER
                        ? primaryColor
                        : '',
                    color:
                      segmentValue === TransactionActions.TYPE.TRANSFER
                        ? whiteColor
                        : '',
                  }}
                >
                  {t('WORD_TRANSFER')}
                </div>
              ),
              value: TransactionActions.TYPE.TRANSFER,
            },
          ]}
          name="type"
          onChange={(v) => {
            setSegmentValue(v);
            let val = form.getFieldsValue(true).value || 0;
            form.setFieldsValue({
              value:
                v === TransactionActions.TYPE.EXPENSE ? -val : Math.abs(val),
            });
          }}
          required
          disabled={!canEdit}
        />
        <SelectAccount
          name="idAccountTo"
          label={t('WORD_ACCOUNT_TO')}
          hidden={segmentValue !== TransactionActions.TYPE.TRANSFER}
          required
          disabled={!canEdit}
        />
        <SelectCategory
          name="idCategory"
          label={t('WORD_CATEGORY')}
          hidden={segmentValue === TransactionActions.TYPE.TRANSFER}
          transactionType={segmentValue}
          required
          disabled={!canEdit}
          onChangeItem={(val) => {
            let obj = { idCategory: val.idCategory };
            if (val.idAccountDefault) {
              obj.idAccount = val.idAccountDefault;
              obj.idCurrency = val.idCurrencyDefault;
            }
            form.setFieldsValue(obj);
          }}
        />
        <SelectAccount
          name="idAccount"
          label={t('WORD_ACCOUNT')}
          required
          disabled={!canEdit}
          onChangeItem={(acc) => {
            if (!UtilObject.isEmpty(acc)) {
              form.setFieldsValue({ idCurrency: acc.idCurrency });
            }
          }}
        />
        <SelectCurrency
          name="idCurrency"
          label={t('WORD_CURRENCY')}
          required
          hidden
        />
        <InputFormDatePicker
          name="transactionDate"
          label={t('WORD_DATE')}
          required
          readOnly={!canEdit}
        />
        <SelectPayee
          name={'idPayee'}
          label={t('WORD_PAYEE')}
          hidden={segmentValue === TransactionActions.TYPE.TRANSFER}
          onChange={(idPayee) => {
            form.setFieldsValue({ idPayee: idPayee });
          }}
          disabled={!canEdit}
        />
        <MySwitchFormItem
          className="switch-row"
          name="approved"
          label={t('WORD_CLEARED')}
          disabled={!canEdit}
        />
        <InputTextAreaFormItem
          name="transactionNote"
          label={t('WORD_MEMO')}
          readOnly={!canEdit}
        />
      </FormApp>
    </MyModal>
  );
});

export default TransactionEditModal;
