import React from 'react';
import ArrowSVG from '../../icons/ArrowSVG';
import MyText from '../myText/MyText';
import MySpace from '../mySpace/MySpace';

const MyPageHeader = (props) => {
  const { title, extraLeft, extraRight, subTitle, onBack, style } = props;

  return (
    <MySpace fullWidth spaceBetween>
      <MySpace>
        <MySpace size={'middle'} style={style}>
          {onBack && (
            <MyText className="arrow-icon">
              <ArrowSVG onClick={onBack} />
            </MyText>
          )}
          <MyText fontWeight={500} size={20}>
            {title}
          </MyText>
        </MySpace>
        <MyText>{subTitle}</MyText>
        <div>{extraLeft}</div>
      </MySpace>
      <div>{extraRight}</div>
    </MySpace>
  );
};

export default MyPageHeader;
