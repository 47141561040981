import React, { memo } from 'react';
import MaterialCustomSvg from './MaterialCustomSvg';

const MSvgIcon = (props) => {
  const { color } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 24 24"
      fill={color}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.80687 3.73684C7.67058 3.73684 7.55157 3.78978 7.47329 3.86541L4.95582 6.29793L21.0443 6.29815L18.5262 3.86547C18.4479 3.78983 18.3289 3.73684 18.1926 3.73684H7.80687ZM22.3361 8.03501L3.66398 8.03476L3.66385 17.342C3.66382 20.0599 5.77447 22.2632 8.37811 22.2632H17.6219C20.2255 22.2632 22.3361 20.0599 22.3361 17.3421V8.03501ZM6.34304 2.59081C6.74146 2.20584 7.269 2 7.80687 2H18.1926C18.7305 2 19.258 2.20584 19.6564 2.59081L23.7331 6.52925C23.9033 6.69361 24 6.92462 24 7.1666V17.3421C24 21.0192 21.1444 24 17.6219 24H8.37811C4.85554 24 1.99995 21.0191 2 17.342L2.00014 7.16665C2.00014 6.9247 2.09684 6.69372 2.26694 6.52937L6.34304 2.59081ZM9.18686 9.98246C9.64632 9.98246 10.0188 10.3713 10.0188 10.8509C10.0188 12.344 11.2917 13.6667 12.9997 13.6667C14.7078 13.6667 15.9807 12.344 15.9807 10.8509C15.9807 10.3713 16.3531 9.98246 16.8126 9.98246C17.2721 9.98246 17.6445 10.3713 17.6445 10.8509C17.6445 13.4272 15.5033 15.4035 12.9997 15.4035C10.4962 15.4035 8.35493 13.4272 8.35493 10.8509C8.35493 10.3713 8.72739 9.98246 9.18686 9.98246Z"
      />
    </svg>
  );
};

const MBudgetOutline = () => {
  return <MaterialCustomSvg children={<MSvgIcon />} />;
};

export default memo(MBudgetOutline);
