import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  Link,
  TextField,
  Typography,
  Grid,
  Divider,
  Chip,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { Google, Apple, VisibilityOff, Visibility } from '@mui/icons-material';
import React, { useState } from 'react';

const LoginMaterial = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setPasswordError(false);
  };

  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const validatePassword = (password) => {
    return password.length >= 6;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isEmailValid = validateEmail(email);
    const isPasswordValid = validatePassword(password);

    setEmailError(!isEmailValid);
    setPasswordError(!isPasswordValid);
  };

  const handleCheckboxChange = (e) => {
    setRememberMe(e.target.checked);
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Card
      variant="outlined"
      style={{
        padding: 30,
      }}
    >
      <form onSubmit={handleSubmit}>
        <Box
          sx={{
            marginTop: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="h4"
            component="h4"
            gutterBottom
            fontWeight="bold"
          >
            Login
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            Gain total control of your money
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          marginTop={5}
          // marginBottom={2}
        >
          <Button
            variant="outlined"
            startIcon={<Apple />}
            size="large"
            sx={{
              height: 50,
            }}
            fullWidth
          >
            Continue with Apple
          </Button>
          <Button
            style={{ marginTop: 10 }}
            variant="contained"
            startIcon={<Google />}
            size="large"
            sx={{
              height: 50,
            }}
            fullWidth
          >
            Continue with Google
          </Button>
        </Box>
        <Divider>
          <Chip label="Or" />
        </Divider>
        <Box>
          <TextField
            margin="normal"
            fullWidth
            name="email"
            label="Email"
            autoComplete="email"
            value={email}
            onChange={handleEmailChange}
            required
            autoFocus
            error={emailError}
            helperText={emailError ? 'Invalid email address' : ''}
          />
          <TextField
            margin="normal"
            fullWidth
            name="password"
            label="Password"
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            id="password"
            value={password}
            onChange={handlePasswordChange}
            required
            error={passwordError}
            helperText={
              passwordError ? 'Password must be at least 6 characters' : ''
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={togglePasswordVisibility}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Grid container>
            <Grid item xs>
              <FormControlLabel
                control={
                  <Checkbox
                    value="remember"
                    color="primary"
                    checked={rememberMe}
                    onChange={handleCheckboxChange}
                  />
                }
                label={<Typography variant="body2">Remember me</Typography>}
              />
            </Grid>
            <Grid item style={{ marginTop: 10 }}>
              <Typography>
                <Link href="#">Forgot password?</Link>
              </Typography>
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2, height: 50 }}
            size="large"
          >
            Login
          </Button>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="body2" align="center">
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </form>
    </Card>
  );
};

export default LoginMaterial;
