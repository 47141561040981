import { useSelector } from 'react-redux';
import useThemeDetector from '../theme/useThemeDetector';
import { theme } from 'antd';

const { useToken } = theme;

export default function useMyTheme() {
  const selectedTheme = useSelector((state) => {
    return state.selectedTheme;
  });

  const { systemTheme } = useThemeDetector();

  const { token } = useToken();

  const isDark =
    selectedTheme === 'dark' ||
    (selectedTheme === 'system' && systemTheme === 'dark');

  return { theme: selectedTheme, isDark, token };
}
