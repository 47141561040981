import React, { useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MainLayoutHeaderMaterial from './MainLayoutHeaderMaterial';
import MaterialMenuSvg from '../../icons/MaterialMenuSvg';
import MActivitySvg from '../../icons/MaterialActivityOutline';
import MaterialBudgetOutline from '../../icons/MaterialBudgetOutline';
import MaterialTransactionsOutline from '../../icons/MaterialTransactionsOutline';
import MaterialAccountsOutline from '../../icons/MaterialAccountsOutline';
// import AlertSubscription from '../../components/Material/AlertSub/AlertSubscription';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import './MainLayoutMaterial.scss';

const drawerWidth = 300;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  backgroundColor: theme.palette.primary.background,
  borderRight: 'none',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  backgroundColor: theme.palette.primary.background,
  borderRight: 'none',
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  // backgroundColor: theme.palette.primary.background,
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  // color: theme.palette.primary.color,
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export default function MainLayoutMaterial(props) {
  const { children } = props;

  const [open, setOpen] = useState(true);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleMenuClick = () => {
    setOpen(!open);
  };

  const items = [
    {
      label: 'Activity',
      icon: <MActivitySvg />,
      key: '1',
      url: '/material/activity',
    },
    {
      label: 'Budget',
      icon: <MaterialBudgetOutline />,
      key: '2',
    },
    {
      label: 'Transactions',
      icon: <MaterialTransactionsOutline />,
      key: '3',
    },
    {
      label: 'Accounts',
      icon: <MaterialAccountsOutline />,
      key: '4',
    },
  ];

  const handleMenuNav = (url, key) => () => {
    setSelectedItem(key);
  };

  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        height: '100vh',
        backgroundColor: theme.palette.primary.background,
      }}
    >
      <Drawer variant="permanent" open={open}>
        <MainLayoutHeaderMaterial open={open} />
        {/* <AlertSubscription /> */}
        <List sx={{ height: '95%' }}>
          {items.map((item) => (
            <ListItem
              key={item.key}
              disablePadding
              sx={{ display: 'block', marginTop: 1 }}
            >
              <ListItemButton
                className="selected-test"
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
                selected={item.key === selectedItem}
                onClick={handleMenuNav(item.url, item.key)}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  primary={item.label}
                  sx={{
                    opacity: open ? 1 : 0,
                  }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        {/* <Divider /> */}
        {open ? (
          <Box sx={{ padding: 3 }}>
            <Button fullWidth variant="contained" startIcon={<AddIcon />}>
              {open ? <span>Add account</span> : null}
            </Button>
          </Box>
        ) : // <IconButton sx={{ borderRadius: 5 }}>
        //   <AddIcon />
        // </IconButton>
        null}
        <DrawerHeader>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleMenuClick}
            edge="start"
          >
            <MaterialMenuSvg />
          </IconButton>
        </DrawerHeader>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        {children}
      </Box>
    </Box>
  );
}
