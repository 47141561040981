import React from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import MyButtonConfirm from './MyButtonConfirm';
import './MyButton.scss';

const MyButtonDelete = ({
  onClick,
  loading,
  label,
  icon,
  className,
  style,
}) => {
  return (
    <MyButtonConfirm
      className={className}
      onClick={(e) => {
        e.stopPropagation();
      }}
      icon={icon || <DeleteOutlined />}
      onConfirm={onClick}
      loading={loading}
      label={label}
      style={style}
      danger
    />
  );
};

export default MyButtonDelete;
