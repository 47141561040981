import React from 'react';

import { Button, Space } from 'antd';
import './Test.scss';
import ApiStorage from '../redux/ApiStorage';
import useMyTheme from '../theme/useMyTheme';
import MySwitch from '../components/mySwitch/MySwitch';
import MyText from '../components/myText/MyText';
import MyTextTitle from '../components/myText/MyTextTitle';
import MyButtonSecondary from '../components/myButton/MyButtonSecondary';
import { PlusOutlined, FilterOutlined } from '@ant-design/icons';
import MySpace from '../components/mySpace/MySpace';
import MyCard from '../components/myCard/MyCard';

const Test = () => {
  const { isDark } = useMyTheme();
  return (
    <MyCard>
      <MyTextTitle>Title</MyTextTitle>
      <br />
      <br />
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <MySwitch
          label="Theme"
          onChange={(e) => ApiStorage.setSelectedTheme(e ? 'dark' : 'light')}
          checked={isDark}
        />
        <Space>
          <Button type="primary">Primary Button</Button>
          <Button>Default Button</Button>
          <Button type="dashed">Dashed Button</Button>
          <Button type="text">Text Button</Button>
          <Button type="link">Link Button</Button>
          <MyButtonSecondary label="Secondary Button" />
        </Space>
        <MyText bold>Text</MyText>
        <MyText>Text</MyText>
        <Space>
          <Button icon={<PlusOutlined />} type="primary">
            Add transaction
          </Button>
          <MyButtonSecondary
            label="Filter"
            icon={<FilterOutlined />}
            fullWidth
          />
        </Space>
        <MyButtonSecondary label="Filter" icon={<FilterOutlined />} fullWidth />
        <MySpace fullWidth>
          <Button type="primary">Primary Button</Button>
          <Button>Default Button</Button>
          <Button type="dashed">Dashed Button</Button>
          <Button type="text">Text Button</Button>
          <Button type="link">Link Button</Button>
          <MyButtonSecondary label="Secondary Button" />
        </MySpace>
      </Space>
    </MyCard>
  );
};

export default Test;
