import React from 'react';
import { useNavigate } from 'react-router-dom';
import MySpace from '../../../components/mySpace/MySpace';
import MyText from '../../../components/myText/MyText';
import EditSVG from '../../../icons/EditSVG';
import UtilObject from '../../../utils/UtilObject';
import { successColor } from '../../../theme/themeLightConfig';
import MyStatistic from '../../../components/myStatistic/MyStatistic';
import UtilNumber from '../../../utils/UtilNumber';

const AccountListItem = (props) => {
  const { item, onClickEdit, hoveredKey, onHover } = props;

  const navigate = useNavigate();

  const handleClickonAccount = () => {
    if (!UtilObject.isEmpty(item)) {
      navigate(`/transaction/list`, { state: { accountObject: item } });
    }
  };

  return (
    <>
      <MySpace
        style={{ height: 30 }}
        // onMouseLeave={}
        onMouseEnter={() => onHover && onHover(item?.idKey)}
        fullWidth
      >
        <div onClick={handleClickonAccount} style={{ cursor: 'pointer' }}>
          <MyText bold>{item?.accountName}</MyText>
        </div>
        {hoveredKey === item?.idKey ? (
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => onClickEdit && onClickEdit(item)}
          >
            <EditSVG />
          </div>
        ) : null}
      </MySpace>
      <MyStatistic
        style={{
          fontSize: 14,
          color: successColor,
          fontWeight: 700,
        }}
        value={UtilNumber.formatDouble(item?.currentBalance)}
      />
    </>
  );
};

export default AccountListItem;
