import { SvgIcon } from '@mui/material';
import React from 'react';
import useMyTheme from '../theme/useMyTheme';

const MaterialCustomSvg = (props) => {
  const { children } = props;
  const { isDark } = useMyTheme();

  return (
    <SvgIcon
      sx={{
        color: isDark ? '#fff' : '#2e3e5f',
        // 'rgb(167, 170, 181)'
        // #2e3e5f
        marginLeft: 1,
        marginTop: 1,
      }}
    >
      {children}
    </SvgIcon>
  );
};

export default MaterialCustomSvg;
