import TransactionActions from '../../actions/TransactionActions';
import DATA from './categoriesData';

const GetTransactionIcons = ({ item }) => {
  if (item.icon) {
    if (DATA[item.icon]) {
      return DATA[item.icon].icon;
    } else {
      return DATA.Default.icon;
    }
  } else {
    if (item.type === TransactionActions.TYPE.TRANSFER) {
      return DATA.Transfer.icon;
    } else if (item.type === TransactionActions.TYPE.INCOME) {
      return DATA.Inflow.icon;
    } else {
      return DATA.Default.icon;
    }
  }
};

export default GetTransactionIcons;
