import React from 'react';
import { Form, DatePicker } from 'antd';
import './InputFormRangeDatePicker.scss';

const { RangePicker } = DatePicker;

const InputFormRangeDatePicker = ({
  label,
  name = 'DateValue',
  required = false,
  placeholder = 'Select Date',
  hidden,
  style,
  readOnly = false,
  allowClear = true,
  onChange,
  hasFeedback = false,
  format = 'DD/MM/YYYY',
}) => {
  // const [isOpen, setIsOpen] = useState(false);

  return (
    <Form.Item
      label={label}
      name={name}
      rules={[
        {
          required: required && !hidden,
          message: `Please input ${placeholder}!`,
        },
      ]}
      layout="vertical"
      hidden={hidden}
      hasFeedback={hasFeedback}
      style={style}
      className="input-range-picker"
    >
      <RangePicker
        style={{ width: '100%' }}
        showNow={true}
        inputReadOnly={readOnly}
        allowClear={(!readOnly && allowClear) || false}
        className="my-date-picker"
        allowEmpty={[true, true]}
        placeholder={['From', 'To']}
        format={format}
        // onFocus={(e) => {
        //   if (!readOnly) {
        //     // e.target.select();
        //     setIsOpen(true);
        //   }
        // }}
        // onClick={(e) => {
        //   if (!readOnly) {
        //     setIsOpen(true);
        //   }
        // }}
        // onBlur={() => {
        //   if (!readOnly) {
        //     setIsOpen(false);
        //   }
        // }}
        onChange={(e) => {
          if (!readOnly) {
            const localDateFrom = e[0]?.startOf('day').format('YYYY-MM-DD');
            const localDateTo = e[1]?.startOf('day').format('YYYY-MM-DD');
            // setIsOpen(false);
            onChange && onChange(e, localDateFrom, localDateTo);
          }
        }}
        // open={isOpen}
      />
    </Form.Item>
  );
};

export default InputFormRangeDatePicker;
