import React from 'react';
import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import './MyInputForm.scss';

const InputPasswordFormItem = ({
  classNameInput = '',
  className = '',
  label,
  name,
  placeholder,
  required = true,
  prefix,
  hidden = false,
  readOnly,
  allowClear = true,
}) => {
  const { t } = useTranslation();

  return (
    <Form.Item
      label={label}
      name={name}
      hidden={hidden}
      className={`app-input ${className}`}
      rules={[
        {
          required: required && !hidden,
          message: `${t('WORD_PLEASE_INPUT_YOUR_PASSWORD')}`,
        },
      ]}
    >
      <Input.Password
        className={`my-input input-password ${classNameInput}`}
        readOnly={readOnly}
        placeholder={placeholder || label || ''}
        prefix={prefix}
        autoComplete={'nope'}
        allowClear={allowClear}
      />
    </Form.Item>
  );
};

export default InputPasswordFormItem;
