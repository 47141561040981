import { List } from 'antd';
import React, { useState } from 'react';
import './MyList.scss';

const MyList = (props) => {
  const {
    data,
    renderItem,
    loading,
    onSelectItem,
    selectable = true,
    grid,
    emptyText,
    split,
    header,
    footer,
    size,
    bordered,
    className,
    style,
    rowKey,
    onMouseLeave,
  } = props;

  const [selectIndex, setselectIndex] = useState();

  const handleClick = (_, item, index) => {
    setselectIndex(index);
    onSelectItem && onSelectItem(item, index);
  };

  return (
    <List
      className={`my-list ${className || ''}`}
      style={{ ...style }}
      itemLayout="horizontal"
      loading={loading}
      dataSource={data}
      grid={grid}
      size={size}
      bordered={bordered}
      split={split}
      header={header}
      footer={footer}
      rowKey={rowKey}
      locale={{ emptyText: emptyText }}
      onMouseLeave={onMouseLeave}
      renderItem={(item, index) => (
        <List.Item
          className={`list-item ${
            index === selectIndex && selectable ? 'list-item-selected' : ''
          }`}
          onClick={(e) => {
            handleClick(e, item, index);
          }}
        >
          {renderItem && renderItem(item)}
        </List.Item>
      )}
    />
  );
};

export default MyList;
