import React, { memo } from 'react';
import MaterialCustomSvg from './MaterialCustomSvg';

const MSvgIcon = (props) => {
  const { color } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 24 24"
      fill={color}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.83019 17C7.65302 17 7.47474 16.9371 7.32415 16.8089C6.96095 16.497 6.8923 15.9164 7.17245 15.512L10.4866 10.7166C10.6217 10.5206 10.8222 10.3936 11.0414 10.3628C11.2651 10.3307 11.4843 10.3997 11.6582 10.5538L14.7808 13.2844L17.5126 9.36051C17.7938 8.95493 18.3143 8.87973 18.6775 9.19532C19.0407 9.50845 19.1071 10.0891 18.8258 10.4922L15.5814 15.1521C15.4463 15.3469 15.247 15.4738 15.0277 15.5034C14.8063 15.5367 14.587 15.4652 14.4121 15.3136L11.2917 12.5842L8.48905 16.6388C8.32516 16.8755 8.07934 17 7.83019 17Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.0262 6H22.974C21.3321 5.98594 20.0001 4.64545 20.0001 2.99949C20.0001 1.34483 21.3462 0 23.0001 0C24.6486 0 25.9896 1.33439 26 2.98019V3.0188C25.9896 4.65592 24.6627 5.986 23.0262 6ZM21.6843 2.99986C21.6843 2.27356 22.2748 1.68421 23.0001 1.68421C23.7254 1.68421 24.316 2.27356 24.316 2.99986C24.316 3.72503 23.7254 4.31662 23.0001 4.31662C22.2748 4.31662 21.6843 3.72503 21.6843 2.99986Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.7652 24H8.23495C4.5055 24 2 21.3803 2 17.4812V8.52877C2 4.62414 4.5055 2 8.23495 2H16.2853C16.7439 2 17.1161 2.37219 17.1161 2.83077C17.1161 3.28936 16.7439 3.66155 16.2853 3.66155H8.23495C5.45697 3.66155 3.66147 5.57122 3.66147 8.52877V17.4812C3.66147 20.4775 5.41377 22.3385 8.23495 22.3385H17.7652C20.5431 22.3385 22.3386 20.4321 22.3386 17.4812V9.6841C22.3386 9.22552 22.7108 8.85333 23.1694 8.85333C23.6279 8.85333 24.0001 9.22552 24.0001 9.6841V17.4812C24.0001 21.3803 21.4946 24 17.7652 24Z"
      />
    </svg>
  );
};

const MActivitySvg = () => {
  return <MaterialCustomSvg children={<MSvgIcon />} />;
};

export default memo(MActivitySvg);
