import React from 'react';
import MyText from './MyText';

const MyTextTitle = ({ children, size = 16, style }) => {
  return (
    <MyText bold size={size} style={style}>
      {children}
    </MyText>
  );
};

export default MyTextTitle;
