import React from 'react';
import { Link } from 'react-router-dom';
import './MyLink.scss';

const MyLink = (props) => {
  const {
    path,
    search,
    state,
    _blank = false,
    style,
    className,
    children,
  } = props;

  let to = {
    pathname: `${path}`,
    search: search,
    state: state,
  };

  return (
    <Link
      style={style}
      className={`my-link ${className}`}
      to={to}
      target={_blank ? '_blank' : ''}
      state={state}
    >
      {children}
    </Link>
  );
};

export default MyLink;
