import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useIsMounted from '@utilityjs/use-is-mounted';
import MySelect from '../mySelect/MySelect';

const SelectTheme = (props) => {
  const { label, style, onChange, value } = props;

  const isMounted = useIsMounted();
  const { t } = useTranslation();

  const [list, setlist] = useState([]);

  useEffect(() => {
    if (isMounted()) {
      let data = [
        { label: t('WORD_THEME_LIGHT'), value: 'light' },
        { label: t('WORD_THEME_DARK'), value: 'dark' },
        { label: t('WORD_THEME_SYSTEM'), value: 'system' },
      ];
      setlist(data);
    }
  }, [isMounted, t]);

  return (
    <MySelect
      label={label}
      options={list}
      style={style}
      onChange={onChange}
      value={value}
      {...props}
    />
  );
};

export default SelectTheme;
