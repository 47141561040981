import { fetchFromUrlPOSTAsync } from '../utils/UrlHelper';

const URL_SAVE_UPDATE = '/cdb/categories/update';
const URL_SAVE_UPDATE_NOTE = '/cdb/categories/update_note';
const URL_SAVE_CREATE = '/cdb/categories/create';
const URL_LIST = '/cdb/categories/list';
// const URL_GET_TOTALS = '/cdb/categories/getTotals';
const URL_INSERT_CATEGORY_BUDGET_VALUE = '/cdb/categories_budget/create';
const URL_UPDATE_CATEGORY_BUDGET_VALUE = '/cdb/categories_budget/update';
const URL_DELETE = '/cdb/categories/delete';
const URL_HIDE = '/cdb/categories/set_hidden';
const URL_SHOW = '/cdb/categories/set_active';
const URL_AUTO_ASSIGN_TOTLA_FILL = '/cdb/categories_budget/assign/fill';
const URL_SET_ASSIGN_BUDGETED = '/cdb/categories_budget/assign/set_budgeted';
// const URL_SET_ASSIGN_LAST_MONTH_SPENT =
//   '/cdb/categories_budget/assign/set_last_month_spent';
// const URL_SSET_AVERAGE_BUDGETED =
//   '/cdb/categories_budget/assign/set_average_budgeted';

const fs = {
  insert: async (values) => {
    let result = await fetchFromUrlPOSTAsync(URL_SAVE_CREATE, {
      saveData: values,
    });
    return result;
  },
  update: async (values, idKey) => {
    let result = await fetchFromUrlPOSTAsync(URL_SAVE_UPDATE + '/' + idKey, {
      saveData: values,
    });
    return result;
  },
  updateNote: async (values, idKey) => {
    let result = await fetchFromUrlPOSTAsync(
      URL_SAVE_UPDATE_NOTE + '/' + idKey,
      { saveData: values }
    );
    return result;
  },
  delete: async (idKey) => {
    let result = await fetchFromUrlPOSTAsync(URL_DELETE + '/' + idKey, []);
    return result;
  },
  hide: async (idKey) => {
    let result = await fetchFromUrlPOSTAsync(URL_HIDE + '/' + idKey, []);
    return result;
  },
  show: async (idKey) => {
    let result = await fetchFromUrlPOSTAsync(URL_SHOW + '/' + idKey, []);
    return result;
  },
  fillPaginationList: async (params) => {
    let result = await fetchFromUrlPOSTAsync(URL_LIST, params);
    return result;
  },
  // getTotals: async (params) => {
  //   let result = await fetchFromUrlPOSTAsync(URL_GET_TOTALS, params);
  //   return result;
  // },
  insertCategoryBudgetValue: async (values) => {
    let result = await fetchFromUrlPOSTAsync(URL_INSERT_CATEGORY_BUDGET_VALUE, {
      saveData: values,
    });
    return result;
  },
  updateCategoryBudgetValue: async (values, id) => {
    let result = await fetchFromUrlPOSTAsync(
      URL_UPDATE_CATEGORY_BUDGET_VALUE + '/' + id,
      { saveData: values }
    );
    return result;
  },
  fillAutoAssignTotal: async (params) => {
    let result = await fetchFromUrlPOSTAsync(URL_AUTO_ASSIGN_TOTLA_FILL, {
      filter: params,
    });
    return result;
  },
  setAssignBudgeted: async (params, type) => {
    let result = await fetchFromUrlPOSTAsync(
      URL_SET_ASSIGN_BUDGETED + '/' + type,
      {
        filter: params,
      }
    );
    return result;
  },
  // setAssignLastMontSpent: async (params) => {
  //   let result = await fetchFromUrlPOSTAsync(URL_SET_ASSIGN_LAST_MONTH_SPENT, {
  //     filter: params,
  //   });
  //   return result;
  // },
  // setAssignAverageBudgeted: async (params) => {
  //   let result = await fetchFromUrlPOSTAsync(URL_SSET_AVERAGE_BUDGETED, {
  //     filter: params,
  //   });
  //   return result;
  // },
};

const loc = {
  TYPE: { DEFAULT: 1, OPERATION: 2 },
  TYPEMAINCHILD: { MAIN: 1, CHILD: 2 },
  STATUS: { ACTIVE: 1, HIDDEN: 2, DELETED: 3 },
  OPERATION_IDS: {
    INFLOW_GROUP: 83,
    READY_TO_ASSIGN_ID: 85,
    STARTING_BALANCE: 1,
    BALANCE_CORRECTION: 2,
    Hidden: -1,
  },
  NATURE: {
    NONE: 1,
    MUST: 2,
    NEED: 3,
    WANT: 4,
    EARNED: 11,
    PASSIVE: 12,
    PORTFOLIO: 13,
  },
  FREQUENCY: { MONTHLY: 1, NONMONTHLY: 2, ONETIME: 3 },
  TYPEINCOMEEXPENSE: { INCOME: 1, EXPENSE: 2 },

  getNatureLabel: (nature) => {
    switch (nature) {
      case 1:
        return 'None';
      case 2:
        return 'Must';
      case 3:
        return 'Need';
      case 4:
        return 'Want';
      default:
        return '';
    }
  },
  getNatureTypesExpense: () => {
    return defaultNatureTypes.filter(
      (nt) => nt.idNature === 0 || nt.idNature < 10
    );
  },
  getNatureTypesIncome: () => {
    return defaultNatureTypes.filter(
      (nt) => nt.idNature === 0 || nt.idNature > 10
    );
  },
};

const defaultNatureTypes = [
  {
    title: 'None',
    idNature: loc.NATURE.NONE,
  },
  {
    title: 'Must',
    idNature: loc.NATURE.MUST,
  },
  {
    title: 'Need',
    idNature: loc.NATURE.NEED,
  },
  {
    title: 'Want',
    idNature: loc.NATURE.WANT,
  },
  {
    title: 'Earned',
    idNature: loc.NATURE.EARNED,
  },
  {
    title: 'Passive',
    idNature: loc.NATURE.PASSIVE,
  },
  {
    title: 'Portfolio',
    idNature: loc.NATURE.PORTFOLIO,
  },
];

const CategoriesActions = Object.assign(fs, loc);

export default CategoriesActions;
