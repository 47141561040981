import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useIsMounted from '@utilityjs/use-is-mounted';
import CategoriesActions from '../../actions/CategoriesActions';
import MySelectFormItem from '../mySelectFormItem/MySelectFormItem';

const SelectCategoryTypeIncomeExpense = (props) => {
  const { name, label, withAll, required, hidden } = props;

  const isMounted = useIsMounted();
  const { t } = useTranslation();

  const [list, setlist] = useState([]);

  useEffect(() => {
    if (isMounted()) {
      let data = [
        {
          label: t('WORD_INCOME'),
          value: CategoriesActions.TYPEINCOMEEXPENSE.INCOME,
        },
        {
          label: t('WORD_EXPENSE'),
          value: CategoriesActions.TYPEINCOMEEXPENSE.EXPENSE,
        },
      ];
      if (withAll) data.unshift({ label: t('WORD_ALL'), value: '' });
      setlist(data);
    }
  }, [isMounted, t, withAll]);

  return (
    <MySelectFormItem
      name={name}
      label={label}
      options={list}
      required={required}
      hidden={hidden}
    />
  );
};

export default SelectCategoryTypeIncomeExpense;
