const CategoriesDefaultColors = [
  '#F44336',
  '#E91E63',
  '#9C27B0',
  '#673AB7',
  '#3F51B5',
  '#2196F3',
  '#00BCD4',
  '#009688',
  '#4CAF50',
  '#8BC34A',
  '#CDDC39',
  '#FFEB3B',
  '#FFC107',
  '#FF9800',
  '#FF5722',
  '#D32F2F',
  '#C2185B',
  '#7B1FA2',
  '#512DA8',
  '#303F9F',
  '#1976D2',
  '#0288D1',
  '#0097A7',
  '#00796B',
  '#388E3C',
  '#689F38',
  '#AFB42B',
  '#FBC02D',
  '#FFA000',
  '#F57C00',
  '#E64A19',
];

export const CategoriesDefaultColorsInflow = [
  '#4caf50',
  '#5fb54e',
  '#6bb94d',
  '#8bc34a',
  '#9dca45',
  '#afd141',
  '#bed63d',
  '#cddc39',
  '#e7e43a',
  '#ffeb3b',
];

export const getRandomColor = () => {
  return CategoriesDefaultColors[
    Math.floor(Math.random() * CategoriesDefaultColors.length)
  ];
};

export default CategoriesDefaultColors;
