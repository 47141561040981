import { Statistic } from 'antd';
import React from 'react';

const MyStatistic = (props) => {
  const { value, style, suffix, prefix, className } = props;
  return (
    <Statistic
      prefix={prefix}
      suffix={suffix}
      value={value || 0}
      valueStyle={style}
      className={className}
    />
  );
};

export default MyStatistic;
