import {
  Box,
  Divider,
  Grid,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import React from 'react';
import LogoSVG from '../../icons/LogoSVG';
import { useState } from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';

const MainLayoutHeaderMaterial = (props) => {
  const { open } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const Isopen = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuItems = [
    {
      label: 'New budget',
      icon: <AddCircleOutlineIcon />,
      onClick: handleClose,
    },
    {
      label: 'View all budgets',
      icon: <ListAltOutlinedIcon />,
      onClick: handleClose,
    },
    {
      divider: true,
    },
    {
      label: 'Budget settings',
      icon: <SettingsOutlinedIcon />,
      onClick: handleClose,
      parent: 'Current budget',
    },
    {
      divider: true,
    },
    {
      label: 'Profile',
      icon: <PersonOutlineOutlinedIcon />,
      onClick: handleClose,
      parent: 'Account',
    },
    {
      label: 'Logout',
      icon: <LogoutOutlinedIcon />,
      onClick: handleClose,
    },
  ];

  return (
    <>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        sx={{
          width: '100%',
          height: 100,
          borderRadius: 1,
        }}
      >
        <Box
          display="flex"
          flexDirection="row"
          margin={1}
          justifyContent="space-evenly"
        >
          <Grid item xs={8} style={{ marginTop: 5, marginRight: 10 }}>
            <LogoSVG />
          </Grid>
          <Box>
            <Grid
              item
              xs={4}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              {open ? (
                <>
                  <Typography fontWeight="bold">Ubicross_2</Typography>
                  <Typography>Khatchig Nazarian</Typography>
                </>
              ) : null}
            </Grid>
          </Box>
        </Box>
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={Isopen}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {menuItems.map((item, index) => (
          <>
            {item.divider ? <Divider /> : null}
            {item.parent ? (
              <Typography sx={{ marginLeft: 1 }}>{item.parent}</Typography>
            ) : null}
            <MenuItem key={index} onClick={item.onClick}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              {item.label}
            </MenuItem>
          </>
        ))}
      </Menu>
    </>
  );
};

export default MainLayoutHeaderMaterial;
