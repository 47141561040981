import React, { useState, useEffect } from 'react';
import { PlaidLink } from 'react-plaid-link';
import PlaidActions from './actions/PlaidActions';
import UtilNotify from './utils/UtilNotify';

const TestPlaid = () => {
  const [linkToken, setLinkToken] = useState(null);
  const [transactionsList, settransactionsList] = useState([]);

  useEffect(() => {
    const createLinkToken = async () => {
      const result = await PlaidActions.createToken();
      console.log('result Connect :>> ', result);
      if (result.success) {
        setLinkToken(result.data.link_token);
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
    };
    createLinkToken();
  }, []);

  const onSuccess = async (public_token, data) => {
    console.log('public_token :>> ', public_token);
    console.log('data :>> ', data);
    const result = await PlaidActions.getToken(public_token);
    console.log('result Success:>> ', result);
    if (result.success) {
      //   setLinkToken(result.data.link_token);

      const resultTr = await PlaidActions.getTransactions(
        result.data.access_token
      );
      console.log('result Transactions :>> ', resultTr);
      if (resultTr.success) {
        settransactionsList(resultTr.data);
      } else {
        UtilNotify.notifyErrorServer(resultTr.errMsg);
      }
    } else {
      UtilNotify.notifyErrorServer(result.errMsg);
    }
  };

  return (
    <div className="App">
      {linkToken ? (
        <PlaidLink token={linkToken} onSuccess={onSuccess}>
          Connect a bank account
        </PlaidLink>
      ) : (
        <div>Loading...</div>
      )}

      {/* {transactionsList?.map((tr) => {
        return (
          <div key={tr.transaction_id}>
            {tr.date} {tr.amount} {tr.name} {tr.payment_channel}
          </div>
        );
      })} */}
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          <tr>
            <th
              style={{
                padding: '8px',
                backgroundColor: '#f2f2f2',
                borderBottom: '1px solid #ddd',
              }}
            >
              Date
            </th>
            <th
              style={{
                padding: '8px',
                backgroundColor: '#f2f2f2',
                borderBottom: '1px solid #ddd',
              }}
            >
              Amount
            </th>
            <th
              style={{
                padding: '8px',
                backgroundColor: '#f2f2f2',
                borderBottom: '1px solid #ddd',
              }}
            >
              Name
            </th>
            <th
              style={{
                padding: '8px',
                backgroundColor: '#f2f2f2',
                borderBottom: '1px solid #ddd',
              }}
            >
              Channel
            </th>
          </tr>
        </thead>
        <tbody>
          {transactionsList?.map((tr) => (
            <tr key={tr.transaction_id}>
              <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>
                {tr.date}
              </td>
              <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>
                {tr.amount}
              </td>
              <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>
                {tr.name}
              </td>
              <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>
                {tr.payment_channel}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TestPlaid;
