import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useIsMounted from '@utilityjs/use-is-mounted';
import MySelectFormItem from '../mySelectFormItem/MySelectFormItem';

const SelectUploadFileDataType = (props) => {
  const { name, label, withAll, required } = props;

  const isMounted = useIsMounted();
  const { t } = useTranslation();

  const [list, setlist] = useState([]);

  useEffect(() => {
    if (isMounted()) {
      let data = [{ label: t('WORD_YNAB_FILE'), value: 'YNAB_FILE' }];
      if (withAll) data.unshift({ label: t('WORD_ALL'), value: '' });
      setlist(data);
    }
  }, [isMounted, t, withAll]);

  return (
    <MySelectFormItem
      name={name}
      label={label}
      options={list}
      required={required}
    />
  );
};

export default SelectUploadFileDataType;
