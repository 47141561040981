import { Dropdown, Space } from 'antd';
import React, { useState } from 'react';
import ArrowSVG from '../../icons/ArrowSVG';
import './MyDropDown.scss';

const MyDropDown = ({ items = [], children, arrow = true }) => {
  const [isOpen, setisOpen] = useState(false);

  return (
    <Dropdown
      menu={{ items }}
      trigger={['click']}
      dropdownRender={(menu) => <div className="my-dropdown-menu">{menu}</div>}
      onOpenChange={setisOpen}
      //   arrow={arrow}
      className="dropdown-test"
    >
      <Space align="start">
        {children}
        <div style={{ marginTop: 5 }}>
          {arrow ? (
            <div className="arrow-bg">
              <ArrowSVG rotate={isOpen ? -90 : 90} color="#a7aab5" />
            </div>
          ) : null}
        </div>
      </Space>
    </Dropdown>
  );
};

export default MyDropDown;
