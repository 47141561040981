import React from 'react';

const GreenDownSquareArrowSVG = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
    >
      <rect
        opacity="0.15"
        width="34"
        height="34"
        rx="8"
        transform="matrix(1 1.74846e-07 1.74846e-07 -1 0 34)"
        fill="#2CB37A"
      />
      <mask
        id="mask0_3886_11734"
        maskUnits="userSpaceOnUse"
        x="7"
        y="7"
        width="19"
        height="19"
      >
        <rect
          width="18.3281"
          height="18.3281"
          transform="matrix(1 1.74846e-07 1.74846e-07 -1 7.52612 25.854)"
          fill="#2CB37A"
        />
      </mask>
      <g mask="url(#mask0_3886_11734)">
        <path
          d="M9.96168 9.33831L8.39037 10.2455L13.1737 18.5953L17.6631 16.0033L21.0446 21.7953L18.7431 21.1786L18.2687 22.949L23.5798 24.3721L25.0029 19.061L23.2326 18.5866L22.6159 20.8881L18.3391 13.4805L13.8497 16.0725L9.96168 9.33831Z"
          fill="#2CB37A"
        />
      </g>
    </svg>
  );
};

export default GreenDownSquareArrowSVG;
