import React, { useState } from 'react';
import { Form, InputNumber } from 'antd';
import { useTranslation } from 'react-i18next';

const PercentInput = React.forwardRef(
  (
    {
      name = 'numberValuePer',
      label,
      required = false,
      controls = false,
      className,
      style,
      placeholder,
      maxLength = 2,
      min = 0,
      max = 100,
      step = 0,
      disabled,
      hidden,
      getValueProps,
      getValueFromEvent,
      normalize,
      shouldUpdate,
      valuePropName,
      onBlur,
      onChange,
      readOnly,
    },
    ref
  ) => {
    const { t } = useTranslation();

    const [decimal] = useState(2);
    const [value, setValue] = useState(0);

    let errorMessage = `${t('WORD_VALUE_FOR')} ${label} ${t(
      'WORD_IS_REQUAIRED'
    )}`;

    let rules = [];
    if (required) {
      rules.push(() => ({
        validator(rule, value) {
          if (value >= 0 || hidden) {
            return Promise.resolve();
          }
          return Promise.reject(errorMessage);
        },
      }));
    }

    const parseValue = (value) =>
      value ? parseFloat(value.replaceAll(',', '')).toFixed(decimal) : 0;

    const validate = (el) => {
      if (el.target.value > 100) {
        el.target.value = 100;
      }
      setValue(el.target.value);
    };

    return (
      <Form.Item
        className="form-input"
        label={label}
        name={name}
        hidden={hidden}
        rules={rules}
        getValueProps={getValueProps}
        getValueFromEvent={getValueFromEvent}
        normalize={normalize}
        shouldUpdate={shouldUpdate}
        valuePropName={valuePropName}
        required={required}
      >
        <InputNumber
          className={`myInput-persentage ${className || ''}`}
          ref={ref}
          style={{ width: '100%', ...style }}
          controls={controls}
          placeholder={placeholder || label || ''}
          value={value}
          step={step}
          min={min}
          max={max}
          disabled={disabled}
          hidden={hidden}
          maxLength={maxLength}
          suffix="%"
          onBlur={(e) => {
            onBlur && onBlur(parseValue(e.target.value));
          }}
          onChange={onChange}
          onKeyUp={validate}
          readOnly={readOnly}
          onFocus={(e) => e.target.select()}
        />
      </Form.Item>
    );
  }
);

export default PercentInput;
