import React from 'react';
import { Table } from 'antd';
import MyPagination from '../myPagination/MyPagination';

const MyTable = ({
  dataSource = [],
  onRow,
  components,
  sticky,
  rowClassName,
  columns,
  className,
  rowKey,
  total = 0,
  pageSize = 10,
  onChangePage,
  page = 0,
  loading,
  title,
  fixed,
  expandable,
  showHeader = true,
  onRowClick,
  onRowDoubleClick,
}) => {
  return (
    <div className="table-wrapper">
      <Table
        className={`app-table ${className || ''}`}
        dataSource={dataSource}
        columns={columns}
        rowKey={rowKey}
        pagination={false}
        loading={loading}
        fixed={fixed}
        caption={title ? () => title : false}
        expandable={expandable}
        showHeader={showHeader}
        sticky={sticky}
        rowClassName={rowClassName}
        components={components}
        onRow={
          onRow ||
          ((record, rowIndex) => {
            return {
              onClick: onRowClick, // click row
              onDoubleClick: onRowDoubleClick, // double click row
            };
          })
        }
      />
      <MyPagination
        total={total}
        onShowSizeChange={(page, pageSize) => onChangePage(page, pageSize - 1)}
        pageSize={pageSize}
        onChange={onChangePage}
        current={page + 1}
      />
    </div>
  );
};

export default MyTable;
