import ApiStorage from '../redux/ApiStorage';
import {
  fetchFromUrlPOSTAsync,
  fetchFromUrlPOSTAsyncLong,
} from '../utils/UrlHelper';

const URL_CDB_SETTINGS_UPDATE = '/cdb/settings/update';
const URL_CDB_SETTINGS_GET = '/cdb/settings/get_one';
const URL_BUDGET_DELETE_BY_NAME = '/budgets_db/delete_dbName';
const URL_CREATE = '/budgets_db/create';
const URL_GET_DEFAULTS = '/budgets_db/get_defaults';
const URL_GET_ALL_BY_USER = '/budgets_db/get_all/mobile';

const fs = {
  createBudget: async (values) => {
    const result = await fetchFromUrlPOSTAsyncLong(URL_CREATE, {
      saveData: values,
    });
    if (result.success) {
      const currentUser = ApiStorage.getCurrentUser();
      ApiStorage.setCurrentUser(
        Object.assign({}, currentUser, { listBudgets: result.data })
      );
      let find = result.data.find((it) => {
        return it.dbName === values.dbName;
      });
      if (find) {
        ApiStorage.setCurrentDb(find);
      }
    }
    return result;
  },
  updateBudget: async (values) => {
    const result = await fetchFromUrlPOSTAsync(URL_CDB_SETTINGS_UPDATE, {
      saveData: values,
    });
    return result;
  },
  getDefaults: async () => {
    const result = await fetchFromUrlPOSTAsync(URL_GET_DEFAULTS, []);
    return result;
  },
  getAllByUser: async () => {
    const result = await fetchFromUrlPOSTAsync(URL_GET_ALL_BY_USER, []);
    return result;
  },
  getSettings: async () => {
    const result = await fetchFromUrlPOSTAsync(URL_CDB_SETTINGS_GET, []);
    if (result.success) {
      ApiStorage.setCurrentDbSettings(result.data);
    }
    return result;
  },
  deleteBudget: async (dbName) => {
    const result = await fetchFromUrlPOSTAsync(
      URL_BUDGET_DELETE_BY_NAME + '/' + dbName,
      []
    );
    const currentDb = ApiStorage.getCurrentDb();
    if (result.success && currentDb.dbName === dbName) {
      const currentUser = ApiStorage.getCurrentUser();
      ApiStorage.setCurrentUser(
        Object.assign({}, currentUser, { listBudgets: result.data })
      );
      ApiStorage.setCurrentDb({});
      ApiStorage.setCurrentDbSettings({});
    }
    return result;
  },
};

const loc = {
  defaultNumberFormats: [
    { label: '123,456.78', value: 1 },
    // { label: '123.456,78', value: 2 },
    // { label: '123,456.789', value: 3 },
    // { label: '123 4563.78', value: 4 },
    // { label: "123'456.78", value: 5 },
    // { label: '123.456', value: 6 },
    { label: '123,456', value: 7 },
    // { label: '123 456-78', value: 8 },
    { label: '123 456,78', value: 9 },
    // { label: '123,456/78', value: 20 },
    { label: '123 456', value: 11 },
    // { label: '1,23,456.78', value: 12 },
  ],
  defaultDateFormats: [
    { label: '2022/12/30', value: 1 },
    { label: '2022-12-30', value: 2 },
    { label: '30-12-2022', value: 3 },
    { label: '30/12/2022', value: 4 },
    { label: '30.12.2022', value: 5 },
    { label: '12/30/2022', value: 6 },
    { label: '2022.12.30', value: 7 },
  ],
  defaultCurrencyPlacements: [
    { label: '$12 346', value: 1 },
    { label: '12 346$.', value: 2 },
    { label: '12 346', value: 3 },
  ],
  DATA_TYPE: {
    DEFAULT: 'Default',
    BLANK: 'Blank',
    UPLOAD: 'Upload',
  },
};

const BudgetActions = Object.assign(fs, loc);

export default BudgetActions;
