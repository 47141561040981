import React, { useState } from 'react';
import { Form, DatePicker } from 'antd';
// import UtilDate from '../../utils/UtilDate';
// import './InputFormDatePicker.scss';

const InputFormDatePicker = ({
  label,
  name = 'DateValue',
  required = false,
  placeholder = 'Select Date',
  hidden,
  style,
  readOnly = false,
  allowClear = true,
  onChange,
  hasFeedback = false,
  format = 'DD/MM/YYYY',
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Form.Item
      label={label}
      name={name}
      rules={[
        {
          required: required && !hidden,
          message: `Please input ${placeholder}!`,
        },
      ]}
      layout="vertical"
      hidden={hidden}
      hasFeedback={hasFeedback}
      style={style}
      className="input-date-picker"
    >
      <DatePicker
        className="my-date-picker"
        style={{ width: '100%' }}
        placeholder={placeholder || label || ''}
        format={format}
        showToday={true}
        suffixIcon={null}
        picker="date"
        // mode="date"
        inputReadOnly={readOnly}
        allowClear={(!readOnly && allowClear) || false}
        // defaultDateRange={defaultDateRange}
        onFocus={(e) => {
          if (!readOnly) {
            e.target.select();
            setIsOpen(true);
          }
        }}
        onClick={(e) => {
          if (!readOnly) {
            setIsOpen(true);
          }
        }}
        onBlur={() => {
          if (!readOnly) {
            setIsOpen(false);
          }
        }}
        onChange={(e, ee) => {
          if (!readOnly) {
            setIsOpen(false);
            onChange && onChange(e);
          }
        }}
        open={isOpen}
      />
    </Form.Item>
  );
};

export default InputFormDatePicker;
