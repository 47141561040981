import i18n from 'i18next';
// import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ApiStorage from './redux/ApiStorage';
import translationEN from './translations/en.json';
import translationAR from './translations/ar.json';

const defLanguage = 'en';

i18n
  .use(initReactI18next)
  // .use(Backend)
  .use(LanguageDetector)
  .init({
    resources: {
      en: {
        translation: translationEN,
      },
      ar: {
        translation: translationAR,
      },
    },
    fallbackLng: defLanguage,
    supportedLngs: ['en', 'ar'],
    debug: false,
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
    load: 'unspecific',
    react: {
      useSuspense: true,
    },
    detection: {
      order: ['path'],
      cache: ['path'],
      lookupFromPathIndex: 0,
      checkWhitelist: true,
    },
  });

i18n.on('languageChanged', (lang) => {
  const html = document.querySelector('html');
  html && html.setAttribute('lang', lang);
  ApiStorage.setLanguage(lang);
});

export default i18n;
