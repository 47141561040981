import React, { useCallback, useEffect, useState } from 'react';
import MySpace from '../../components/mySpace/MySpace';
import MyMonthPicker from '../../components/myMonthPicker/MyMonthPicker';
import UtilDate from '../../utils/UtilDate';
import CategoryTotalsSection from './CategoryTotalsSection';
import CategoryAutoAssignSection from './CategoryAutoAssignSection';
import MyPageHeader from '../../components/myPageHeader/MyPageHeader';
import CategoryNoteSection from './CategoryNoteSection';
import { useSelector } from 'react-redux';
import CategoryAddNewSelect from './CategoryAddNewSelect';
import CategoriesActions from '../../actions/CategoriesActions';
import useIsMounted from '@utilityjs/use-is-mounted/useIsMounted';
import UtilNotify from '../../utils/UtilNotify';
import MyCollapse from '../../components/myCollapse/MyCollapse';
import MyCard from '../../components/myCard/MyCard';
import CategoryListItemMain from './CategoryListItemMain';
import MyList from '../../components/myList/MyList';
import CategoryListItemChild from './CategoryListItemChild';
import LoadingIconApp from '../../components/loadingIconApp/LoadingIconApp';
import MySpaceColumn from '../../components/mySpace/MySpaceColumn';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import UtilObject from '../../utils/UtilObject';

const CategoriesList = () => {
  const isMounted = useIsMounted();
  const { t } = useTranslation();

  const canEdit = useSelector((state) => state.canEdit);

  const budgetName = useSelector((state) => {
    return state.currentDbSettings?.displayName || '';
  });

  const [loading, setloading] = useState(true);
  const [listCategoriesGroup, setlistCategoriesGroup] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(UtilDate.getThisMonth());
  const [selectedYear, setSelectedYear] = useState(UtilDate.getThisYear());
  const [selectedCategory, setselectedCategory] = useState(null);
  const [editingKey, setEditingKey] = useState('');

  const setListWithCalc = (newData) => {
    setlistCategoriesGroup(
      newData.map((it) => {
        let budgetValue = 0;
        let currentAvilable = 0;
        let currentBalance = 0;
        let currentAvilableFinal = 0;
        let lastExpense = 0;
        let transactionCount = 0;
        if (it.children) {
          for (const ch of it.children) {
            budgetValue += ch.budgetValue;
            currentAvilable += ch.currentAvilable;
            currentBalance += ch.currentBalance;
            currentAvilableFinal += ch.currentAvilableFinal;
            lastExpense += ch.lastExpense;
            transactionCount += ch.transactionCount;
          }
        }
        let uu = {
          ...it,
          budgetValue,
          currentAvilable,
          currentBalance,
          currentAvilableFinal,
          lastExpense,
          transactionCount,
        };
        return uu;
      })
    );
  };

  const fillFS = useCallback(async () => {
    const fill = async () => {
      setloading(true);
      let result = await CategoriesActions.fillPaginationList({
        search: '',
        filter: {
          month: selectedMonth,
          year: selectedYear,
          type: CategoriesActions.TYPE.DEFAULT,
          typeIncomeExpense: CategoriesActions.TYPEINCOMEEXPENSE.EXPENSE,
        },
      });
      if (isMounted()) {
        if (result.success) {
          let listMain = result.data
            .filter((cat) => {
              return cat.typeMainChild === CategoriesActions.TYPEMAINCHILD.MAIN;
            })
            .map((main) => {
              return {
                ...main,
                children: result.data.filter((it) => {
                  return (
                    it.idParent === main.idCategory &&
                    it.status === CategoriesActions.STATUS.ACTIVE
                  );
                }),
              };
            });
          let listChieldFirstLevel = result.data.filter((cat) => {
            return (
              cat.typeMainChild === CategoriesActions.TYPEMAINCHILD.CHILD &&
              cat.idParent === 0 &&
              cat.type === CategoriesActions.TYPE.DEFAULT &&
              cat.status !== CategoriesActions.STATUS.HIDDEN
            );
          });

          if (listChieldFirstLevel.length) {
            listMain.push(...listChieldFirstLevel);
          }
          let listHidden = result.data.filter((it) => {
            return it.status === CategoriesActions.STATUS.HIDDEN;
          });
          if (listHidden.length) {
            listMain.push({
              idCategory: CategoriesActions.OPERATION_IDS.Hidden,
              idKey: 'Hiden',
              categoryName: 'Hiden',
              typeMainChild: CategoriesActions.TYPEMAINCHILD.MAIN,
              type: CategoriesActions.TYPE.OPERATION,
              idParent: 0,
              ordering: 100000,
              status: CategoriesActions.STATUS.ACTIVE,
              children: listHidden,
            });
          }
          setListWithCalc(listMain);
        } else {
          UtilNotify.notifyErrorServer(result.errMsg);
        }
        setloading(false);
      }
    };
    fill();
  }, [selectedMonth, selectedYear, isMounted]);

  useEffect(() => {
    fillFS();
  }, [fillFS]);

  const handleOnCreateCategory = (obj) => {
    if (obj.typeIncomeExpense === CategoriesActions.TYPEINCOMEEXPENSE.EXPENSE) {
      setlistCategoriesGroup((ov) => {
        obj.children = [];
        ov.push(obj);
        return [...ov];
      });
    }
  };

  const handleEditCategory = useCallback(
    (obj) => {
      if (!UtilObject.isEmpty(obj)) {
        if (obj.idCategory === selectedCategory?.idCategory) {
          Object.assign(selectedCategory, obj);
        }
        let list = listCategoriesGroup.map((parent) => {
          if (parent.idCategory === obj.idCategory) {
            Object.assign(parent, obj);
          } else if (parent.idCategory === obj.idParent) {
            parent.children = parent.children.map((oo) => {
              if (oo.idCategory === obj.idCategory) {
                Object.assign(oo, obj);
              }
              return oo;
            });
          }
          return parent;
        });
        setListWithCalc(list);
      }
    },
    [listCategoriesGroup, selectedCategory]
  );

  const handleDeleteCategory = useCallback(
    (obj) => {
      setselectedCategory({});
      // setEditingKey('');
      fillFS();
    },
    [fillFS]
  );

  const handleChangeNote = async (item, v) => {
    if (!UtilObject.isEmpty(item)) {
      handleEditCategory({
        idCategory: item.idCategory,
        categoryNote: item.categoryNote,
      });
    }
  };

  const getItem = (group) => {
    return {
      key: group.idKey,
      label: (
        <CategoryListItemMain
          item={group}
          canEdit={canEdit}
          onEdit={(obj) => {
            setlistCategoriesGroup((ov) => {
              return ov.map((oo) => {
                if (oo.idKey === obj.idKey) {
                  oo = Object.assign({}, oo, obj);
                }
                return oo;
              });
            });
          }}
          onCreate={(obj) => {
            setlistCategoriesGroup((ov) => {
              return ov.map((oo) => {
                if (oo.idKey === group.idKey) {
                  oo.children.push(obj);
                }
                return oo;
              });
            });
          }}
        />
      ),
      children: (
        <MyList
          data={group.children}
          renderItem={(item, i) => (
            <CategoryListItemChild
              item={item}
              selectedItem={selectedCategory}
              editingKey={editingKey}
              // onClickEdit={(acc) => refEditModal.current.edit(acc.idKey)}
              // onHover={(idKey) => sethoveredAccount(idKey)}
              canEdit={canEdit}
              onClick={(item) => {
                setselectedCategory(item);
              }}
              onDoubleClick={() => setEditingKey(item?.idKey)}
              // hoveredKey={hoveredAccount}
            />
          )}
        />
      ),
    };
  };

  return (
    <>
      <Helmet>
        <title>
          {t('WORD_BUDGET')} | {budgetName} | {t('WORD_MONEYSCOPE')}
        </title>
        <meta name="description" content="Some description." />
        <meta
          property="og:title"
          content={
            `${t('WORD_BUDGET')}` | `${budgetName}` | `${t('WORD_MONEYSCOPE')}`
          }
        />
        <meta property="og:image" content="public/favicon/mstile-150x150.png" />
      </Helmet>
      <MySpace
        direction="vertical"
        size="middle"
        fullWidth
        // onClick={() => setselectedCategory(null)}
      >
        <MyPageHeader
          title={
            <MyMonthPicker
              onChange={(month, year) => {
                setSelectedMonth(month);
                setSelectedYear(year);
                setselectedCategory(null);
                // setEditingKey('');
              }}
            />
          }
          extraRight={
            canEdit && (
              <CategoryAddNewSelect onChange={handleOnCreateCategory} />
            )
          }
        />
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: 15,
          }}
        >
          {loading ? (
            <LoadingIconApp />
          ) : (
            <MySpaceColumn fullWidth style={{ marginBottom: 15 }}>
              {listCategoriesGroup?.map((group, i) => {
                return (
                  <MyCard
                    key={group?.idKey}
                    className="categories-list-card"
                    style={{ flex: 1 }}
                    // fullHeight
                  >
                    {group.typeMainChild ===
                    CategoriesActions.TYPEMAINCHILD.MAIN ? (
                      <MyCollapse
                        key={i}
                        className="categories-list-collapse"
                        items={[getItem(group)]}
                        defaultActiveKey={listCategoriesGroup
                          ?.filter(
                            (ii) =>
                              ii.idCategory !==
                              CategoriesActions.OPERATION_IDS.Hidden
                          )
                          .map((it) => it.idKey)}
                        // onChange={() => {
                        //   setselectedCategory(null);
                        //   setEditingKey('');
                        // }}
                      />
                    ) : (
                      // <div>
                      //   <MyText strong>{group?.categoryName}</MyText>
                      // </div>
                      <CategoryListItemChild
                        item={group}
                        selectedItem={selectedCategory}
                        // onClickEdit={(acc) => refEditModal.current.edit(acc.idKey)}
                        // onHover={(idKey) => sethoveredAccount(idKey)}
                        canEdit={canEdit}
                        onClick={(item) => {
                          setselectedCategory(item);
                        }}
                        // hoveredKey={hoveredAccount}
                      />
                    )}
                  </MyCard>
                );
              })}
            </MySpaceColumn>
          )}
          <MySpace direction="vertical">
            <CategoryTotalsSection
              month={selectedMonth}
              year={selectedYear}
              item={selectedCategory}
              onChange={handleEditCategory}
              onDelete={handleDeleteCategory}
            />
            {selectedCategory?.typeMainChild ===
            CategoriesActions.TYPEMAINCHILD.CHILD ? (
              <CategoryNoteSection
                item={selectedCategory}
                onChange={handleChangeNote}
              />
            ) : null}
            <CategoryAutoAssignSection
              month={selectedMonth}
              year={selectedYear}
              item={selectedCategory}
            />
          </MySpace>
        </div>
      </MySpace>
    </>
  );
};

export default CategoriesList;
