import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, Navigate } from 'react-router-dom';
import UtilObject from '../utils/UtilObject';
import ApiStorage from '../redux/ApiStorage';
import { socketAction } from '../actions/socketAction';
import BudgetShareActions from '../actions/BudgetShareActions';

const AppRoutePrivate = ({ children }) => {
  const location = useLocation();

  const currentUser = useSelector((state) => {
    return state.currentUser;
  });

  const currentDb = useSelector((state) => {
    return state.currentDb;
  });

  let isEmptyUser = UtilObject.isEmpty(currentUser);
  let isEmptyDb = UtilObject.isEmpty(currentDb);

  let isOwnerDb = !isEmptyDb && currentDb.isOwner;

  useEffect(() => {
    if (!isEmptyUser && !isEmptyDb) {
      socketAction.refreshWebSocket();
      let find = currentUser.listBudgets.find((dd) => {
        return dd.dbName === currentDb.dbName;
      });
      if (find) {
        ApiStorage.setCanEdit(
          find.isOwner ||
            find.permission === BudgetShareActions.PERMISSION.WRITE
        );
      }
    }
  }, [isEmptyUser, isEmptyDb, currentDb, currentUser]);

  if (isEmptyUser) {
    return (
      <Navigate to="/login" state={{ from: location, force: true }} replace />
    );
  } else if (!currentUser.emailVerified) {
    return <Navigate to="/activate" state={{ from: location }} replace />;
  } else if (currentUser.is2FAenabled && !currentUser.verify2fasuccess) {
    return (
      <Navigate
        to="/two_step_verification"
        state={{ from: location }}
        replace
      />
    );
  } else if (
    currentUser.hasOwnProperty('listBudgets') &&
    !currentUser.listBudgets.length
  ) {
    return (
      <Navigate
        to="/dbbudget/create/first"
        state={{ from: location }}
        replace
      />
    );
  } else if (isEmptyDb) {
    return (
      <Navigate to="/dbbudget/list/first" state={{ from: location }} replace />
    );
  } else if (!isOwnerDb && location.pathname === '/dbbudget/share') {
    return <Navigate to="/page_not_found" state={{ from: location }} replace />;
  }
  return children;
};

export default AppRoutePrivate;
