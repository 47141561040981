import React, { useState } from 'react';
import useIsMounted from '@utilityjs/use-is-mounted';
import { useTranslation } from 'react-i18next';
import UtilNotify from '../../utils/UtilNotify';
import UtilFirebase from '../../utils/UtilFirebase';
import AppleSVG from '../../icons/AppleSVG ';
import MyButtonMain from '../myButton/MyButtonMain';
import './SocialLoginAppleButton.scss';

const SocialLoginAppleButton = (props) => {
  const { onSuccess, className, disabled } = props;

  const isMounted = useIsMounted();
  const { t } = useTranslation();

  const [saving, setsaving] = useState(false);

  const handleLoginApple = async () => {
    setsaving(true);
    const result = await UtilFirebase.loginApple();
    if (isMounted()) {
      if (result.success) {
        onSuccess && onSuccess(result.data);
      } else {
        UtilNotify.notifywarning(result.errMsg);
      }
      setsaving(false);
    }
  };

  return (
    <MyButtonMain
      className={`${className} social-button`}
      loading={saving}
      onClick={handleLoginApple}
      icon={<AppleSVG />}
      label={t('WORD_CONTINUE_APPLE')}
      type="default"
      disabled={disabled}
    />
  );
};

export default SocialLoginAppleButton;
