import React, { useEffect, useState } from 'react';
import { Upload, message, Avatar, Form } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import { getBaseURL, getBaseURLShowImage } from '../../../utils/UrlHelper';
import ApiStorage from '../../../redux/ApiStorage';
import LoadingIconApp from '../../loadingIconApp/LoadingIconApp';
import EditSVG from '../../../icons/EditSVG';
// import { primaryColor } from '../../../theme/themeLightConfig';

const URL_UPLOAD_IMAGE = 'file/upload/image';

function beforeUpload(file) {
  const isJPG = true;
  // const isJPG = file.type === 'image/*';
  if (!isJPG) {
    message.error('You can only upload JPG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image file size must be less than 2MB!');
  }
  return isJPG && isLt2M;
}

const errorMessage = 'Error Image';

const IconAvatarForm = (props) => {
  const {
    onChange,
    label,
    name,
    required,
    placeholder,
    icon,
    image,
    hidden,
    style,
    onClear,
  } = props;

  const [imageUrl, setImageUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [showDeleteIcon, setShowDeleteIcon] = useState(false);

  useEffect(() => {
    setImageUrl(icon || image);
  }, [icon, image]);

  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      if (info.file.response) {
        setImageUrl(info.file.response.data.path);
        onChange && onChange(info.file.response.data.path);
      }
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
    setLoading(false);
  };

  const handleClear = () => {
    setImageUrl('');
    onClear && onClear();
  };

  return (
    <Form.Item
      label={label}
      name={name}
      // fieldKey={fieldKey}
      hidden={hidden}
      rules={[
        {
          required: required && !hidden,
          message: errorMessage
            ? errorMessage
            : `Please input ${placeholder ? placeholder : label}!`,
        },
      ]}
      style={{ width: '20%' }}
      onMouseEnter={() => setShowDeleteIcon(true)}
      onMouseLeave={() => setShowDeleteIcon(false)}
      extra="2 MB / Images"
    >
      <div>
        <ImgCrop rotationSlider>
          <Upload
            className="avatar-uploader"
            name="image"
            multiple={false}
            showUploadList={false}
            action={getBaseURL() + URL_UPLOAD_IMAGE}
            headers={{
              authorization: `Bearer ${ApiStorage.getAccessToken()}`,
            }}
            beforeUpload={beforeUpload}
            onChange={handleChange}
            maxCount={1}
            // listType="picture-card"
          >
            <Avatar
              src={getBaseURLShowImage(imageUrl)}
              // size="large"
              size={70}
              style={style}
              shape={icon ? 'circle' : 'square'}
              icon={loading ? <LoadingIconApp /> : <PlusOutlined />}
            />
          </Upload>
        </ImgCrop>
        {showDeleteIcon && imageUrl && (
          <div
            onClick={handleClear}
            style={{
              position: 'absolute',
              top: 3,
              left: 43,
              // color: primaryColor,
              // backgroundColor: primaryColor,
              // borderRadius: 50,
              // padding: 5,
              cursor: 'pointer',
            }}
          >
            <EditSVG color={'#fff'} />
          </div>
        )}
      </div>
    </Form.Item>
  );
};

export default IconAvatarForm;
