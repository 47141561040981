import { fetchFromUrlPOSTAsync } from '../utils/UrlHelper';

const URL_CREATE_TOKEN = '/plaid/create_link_token';
const URL_GET_TOKEN = '/plaid/get_access_token';
const URL_GET_TREANSACTIONS = '/plaid/get_transactions';

const fs = {
  createToken: async () => {
    let result = await fetchFromUrlPOSTAsync(URL_CREATE_TOKEN, []);
    return result;
  },
  getToken: async (publicToken) => {
    let result = await fetchFromUrlPOSTAsync(
      URL_GET_TOKEN + '/' + publicToken,
      []
    );
    return result;
  },
  getTransactions: async (accessToken) => {
    let result = await fetchFromUrlPOSTAsync(
      URL_GET_TREANSACTIONS + '/' + accessToken,
      []
    );
    return result;
  },
};

const loc = {};

const PlaidActions = Object.assign(fs, loc);

export default PlaidActions;
