import React, { useEffect, useState } from 'react';
import ReactCodeInput from 'react-verification-code-input';
import { useTranslation } from 'react-i18next';
import MyTextSecondary from '../myText/MyTextSecondary';
import { Button } from 'antd';
import MyText from '../myText/MyText';
import MySpace from '../mySpace/MySpace';
import './MyReactCodeInput.scss';

// const intervalSeconds = 60000;
const intervalSeconds = 10000;

const MyReactCodeInput = (props) => {
  const { onComplete, saving, onClick } = props;

  const { t } = useTranslation();

  const [canSend, setcanSend] = useState(false);
  const [countDown, setcountDown] = useState(intervalSeconds);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!canSend) {
        if (countDown <= 1000) {
          setcanSend(true);
          clearInterval(interval);
          setcountDown(intervalSeconds);
        } else {
          setcountDown((ov) => (ov -= 1000));
        }
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [canSend, countDown]);

  const handleOnClick = () => {
    setcanSend(false);
    onClick && onClick();
  };

  return (
    <MySpace className="react-code-input" direction="vertical">
      <ReactCodeInput
        fields={4}
        type="number"
        onComplete={onComplete}
        autoFocus={true}
        loading={saving}
        className={'code-input'}
      />
      <MySpace direction="vertical">
        <MySpace>
          <MyText>{t('WORD_DONT_GET_A_CODE')}</MyText>
          {canSend ? (
            <Button
              style={{ padding: 0, fontWeight: 600 }}
              type="link"
              onClick={handleOnClick}
            >
              {t('WORD_RESEND')}
            </Button>
          ) : (
            <MyTextSecondary bold>
              {t('WORD_YOU_CAN_RESEND_AFTER')} {countDown / 1000}
            </MyTextSecondary>
          )}
        </MySpace>
        <MyTextSecondary className="resend-message">
          {t('WORD_MINUTE_RECEIVED')}
        </MyTextSecondary>
      </MySpace>
    </MySpace>
  );
};

export default MyReactCodeInput;
