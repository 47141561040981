import React, { memo } from 'react';
import MyIcon from './MyIcon';

const SVGIcon = (props) => {
  const { color, size } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size || 18}
      height={size || 18}
      viewBox="0 0 24 24"
      fill={color}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 7.45379C3.49999 4.44175 5.91292 2 8.88941 2H17.1106C20.0871 2 22.5 4.44174 22.5 7.45378V23.1681C22.5 23.4777 22.33 23.7618 22.0588 23.9053C21.7877 24.0488 21.4601 24.028 21.2088 23.8513L18.7853 22.1475L16.3617 23.8513C16.0797 24.0496 15.7056 24.0496 15.4236 23.8513L13 22.1475L10.5765 23.8513C10.2944 24.0496 9.92035 24.0496 9.63833 23.8513L7.21477 22.1475L4.79121 23.8513C4.53989 24.028 4.21238 24.0488 3.9412 23.9053C3.67001 23.7618 3.50003 23.4777 3.50003 23.1681L3.5 7.45379ZM8.88941 3.66387C6.821 3.66387 5.14422 5.36067 5.14423 7.45379L5.14426 21.5771L6.74571 20.4512C7.02773 20.253 7.40182 20.253 7.68384 20.4512L10.1074 22.1551L12.531 20.4512C12.813 20.253 13.1871 20.253 13.4691 20.4512L15.8926 22.1551L18.3162 20.4512C18.5982 20.253 18.9723 20.253 19.2543 20.4512L20.8558 21.5771V7.45378C20.8558 5.36067 19.179 3.66387 17.1106 3.66387H8.88941ZM7.50521 8.2549C7.50521 7.79544 7.87328 7.42297 8.32732 7.42297H17.6727C18.1268 7.42297 18.4948 7.79544 18.4948 8.2549C18.4948 8.71437 18.1268 9.08683 17.6727 9.08683H8.32732C7.87328 9.08683 7.50521 8.71437 7.50521 8.2549ZM7.50521 13.6779C7.50521 13.2184 7.87328 12.8459 8.32732 12.8459H17.6727C18.1268 12.8459 18.4948 13.2184 18.4948 13.6779C18.4948 14.1373 18.1268 14.5098 17.6727 14.5098H8.32732C7.87328 14.5098 7.50521 14.1373 7.50521 13.6779Z"
      />
    </svg>
  );
};

const TransactionsOutlineSVG = (props) => {
  return <MyIcon icon={<SVGIcon />} {...props} />;
};

export default memo(TransactionsOutlineSVG);
