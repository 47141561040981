import React, { useMemo } from 'react';
import MyCard from '../../components/myCard/MyCard';
import MyLink from '../../components/myLink/MyLink';
import MyTextSecondary from '../../components/myText/MyTextSecondary';
import { useTranslation } from 'react-i18next';
import UtilNumber from '../../utils/UtilNumber';
import GreenUpSquareArrowSVG from '../../icons/GreenUpSquareArrowSVG';
import RedUpSquareArrowSVG from '../../icons/RedUpSquareArrowSVG';
import MySpace from '../../components/mySpace/MySpace';
import { errorColor, successColor } from '../../theme/themeLightConfig';
import UtilDate from '../../utils/UtilDate';
import MyText from '../../components/myText/MyText';
import RedDownSquareArrowSVG from '../../icons/RedDownSquareArrowSVG';
import GreenDownSquareArrowSVG from '../../icons/GreenDownSquareArrowSVG';
import MyStatistic from '../../components/myStatistic/MyStatistic';
import CategoriesActions from '../../actions/CategoriesActions';

const ActivityAmountBlock = (props) => {
  const { item, month, year, index } = props;
  const { t } = useTranslation();

  const DateSection = useMemo(() => {
    const result =
      UtilDate.getMonth() === '' + month
        ? UtilDate.getMonthFullNameFormatWithoutYear(
            UtilDate.getLastMonthWithSameDay()
          )
        : UtilDate.getMonthName(
            UtilDate.getLastMonthWithSameDay(
              UtilDate.getEndOfMonthDate(month, year)
            )
          );
    return result;
  }, [month, year]);

  const IconForPercentage = ({ item }) => {
    const isPoistivePer = UtilNumber.getIsPercentagePositive(item.percentage);
    if (isPoistivePer === 0) {
      return null;
    }
    if (isPoistivePer) {
      if (
        item.typeIncomeExpense === CategoriesActions.TYPEINCOMEEXPENSE.INCOME
      ) {
        return <GreenUpSquareArrowSVG />;
      } else {
        return <RedUpSquareArrowSVG />;
      }
    } else {
      if (
        item.typeIncomeExpense === CategoriesActions.TYPEINCOMEEXPENSE.INCOME
      ) {
        return <RedDownSquareArrowSVG />;
      } else {
        return <GreenDownSquareArrowSVG />;
      }
    }
  };

  const color = () => {
    const colorNegative = `${errorColor}`;
    const colorPositive = `${successColor}`;
    const isPoistivePer = UtilNumber.getIsPercentagePositive(item?.percentage);
    if (isPoistivePer === 0) {
      if (
        item?.typeIncomeExpense === CategoriesActions.TYPEINCOMEEXPENSE.INCOME
      ) {
        return colorPositive;
      } else {
        return colorNegative;
      }
    }
    if (isPoistivePer) {
      if (
        item?.typeIncomeExpense === CategoriesActions.TYPEINCOMEEXPENSE.INCOME
      ) {
        return colorPositive;
      } else {
        return colorNegative;
      }
    } else {
      if (
        item?.typeIncomeExpense === CategoriesActions.TYPEINCOMEEXPENSE.INCOME
      ) {
        return colorNegative;
      } else {
        return colorPositive;
      }
    }
  };

  return (
    <MyLink key={index} path="/transaction/list" state={item?.state}>
      <MyCard
        style={{ width: 270, height: 150 }}
        className="activity-amount-card"
      >
        <MySpace fullWidth direction="vertical">
          <MySpace fullWidth spaceBetween>
            <MyText bold size={20}>
              {t(item?.label)}
            </MyText>
            <IconForPercentage item={item} />
          </MySpace>
          <MySpace>
            <MyStatistic
              style={{ fontWeight: 'bold' }}
              value={UtilNumber.formatDouble(item?.amount)}
            />
          </MySpace>
          <MySpace>
            <MyStatistic
              value={UtilNumber.formatAddPlusAtBegining(item?.percentage)}
              style={{
                color: color(),
                fontSize: 15,
                fontWeight: 500,
              }}
            />
            <MyTextSecondary>
              {t('WORD_FROM')} {DateSection}
            </MyTextSecondary>
          </MySpace>
        </MySpace>
      </MyCard>
    </MyLink>
  );
};

export default ActivityAmountBlock;
