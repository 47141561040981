import React from 'react';
import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import './MyInputForm.scss';

const InputPasswordConfirmFormItem = ({
  classNameInput = '',
  className = '',
  label,
  name,
  placeholder,
  required = true,
  prefix,
  hidden = false,
  readOnly,
  allowClear = true,
  hasFeedback = false,
}) => {
  const { t } = useTranslation();

  return (
    <Form.Item
      className={className}
      name={name}
      label={label}
      hidden={hidden}
      dependencies={['password']}
      hasFeedback={hasFeedback}
      rules={[
        {
          required: required && !hidden,
          message: `${t('WORD_PLEASE_CONFIRM_YOUR_PASSWORD')}`,
        },
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value || getFieldValue('password') === value) {
              return Promise.resolve();
            }
            return Promise.reject(
              new Error(
                `${t('WORD_THE_TWOPASSWORDS_THAT_YOU_ENTERED_DO_NOT_MATCH')}`
              )
            );
          },
        }),
      ]}
    >
      <Input.Password
        className={`my-input input-password ${classNameInput}`}
        placeholder={placeholder || label || ''}
        readOnly={readOnly}
        prefix={prefix}
        autoComplete={'nope'}
        allowClear={allowClear}
      />
    </Form.Item>
  );
};

export default InputPasswordConfirmFormItem;
