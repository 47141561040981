import React, { memo } from 'react';
import MaterialCustomSvg from './MaterialCustomSvg';

const MSvgIcon = (props) => {
  const { color } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 24 24"
      fill={color}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 6.56955C23 2.94633 20.0023 0 16.317 0H6.68414C2.99884 0 0 2.94633 0 6.56955V14.4304C0 18.0526 2.99884 21 6.68414 21H16.317C20.0023 21 23 18.0526 23 14.4304V6.56955ZM6.68414 1.64294H16.317C19.0807 1.64294 21.3284 3.85323 21.3284 6.56955V6.58598L21.325 6.58598V6.59998H17.55C15.4789 6.59998 13.8 8.27891 13.8 10.35C13.8 12.421 15.4789 14.1 17.55 14.1V14.1H21.325V14.1238L21.3284 14.1238V14.4304C21.3284 17.1468 19.0807 19.3571 16.317 19.3571H6.68414C3.92044 19.3571 1.67159 17.1468 1.67159 14.4304V6.56955C1.67159 3.85323 3.92044 1.64294 6.68414 1.64294ZM21.325 12.4V8.29998H17.55C16.4178 8.29999 15.5 9.21781 15.5 10.35C15.5 11.4822 16.4178 12.4 17.55 12.4V12.4H21.325ZM12.7431 5.792C12.7431 5.33855 12.3687 4.97054 11.9073 4.97054H5.89069C5.42933 4.97054 5.0549 5.33855 5.0549 5.792C5.0549 6.24545 5.42933 6.61347 5.89069 6.61347H11.9073C12.3687 6.61347 12.7431 6.24545 12.7431 5.792ZM18.4 10.35C18.4 10.8194 18.0195 11.2 17.55 11.2C17.0806 11.2 16.7 10.8194 16.7 10.35C16.7 9.88056 17.0806 9.5 17.55 9.5C18.0195 9.5 18.4 9.88056 18.4 10.35Z"
      />
    </svg>
  );
};

const MAccountsOutline = () => {
  return <MaterialCustomSvg children={<MSvgIcon />} />;
};

export default memo(MAccountsOutline);
