import React, { useEffect, useState } from 'react';
import { Select, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import AccountActions from '../../actions/AccountActions';

// const { Option, OptGroup } = Select;

const SelectAccountType = (props) => {
  const { onChange, label, name, required, hidden, placeholder, className } =
    props;

  const [list, setlist] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    // let li = [];
    // for (const obj of AccountActions.defaultAccountTypes) {
    //   for (const dat of obj.data) {
    //     li.push({ ...dat, label: dat.accTyName, value: dat.idType });
    //   }
    // }
    // setlist(li);
    // let r = AccountActions.defaultAccountTypes.map((oo) => {
    //   return (
    //     <OptGroup key={oo?.idType} label={oo?.accTyName}>
    //       {oo.data.map((ch) => {
    //         return (
    //           <Option
    //             {...ch}
    //             key={ch?.idType}
    //             value={ch?.idType}
    //             label={ch?.accTyName}
    //           />
    //           //   <MySpace>
    //           //     <GetCategoryIcons icon={ch?.icon} />
    //           //     <MyText>{ch?.categoryName}</MyText>
    //           //     <div className="option-item-value">{ch?.currentAvilable}</div>
    //           //   </MySpace>
    //           // </Option>
    //         );
    //       })}
    //     </OptGroup>
    //   );
    // });
    let r = AccountActions.defaultAccountTypes.map((oo) => {
      return {
        label: oo?.accTyName,
        key: oo?.idType,
        ...oo,
        options: oo.data.map((ch) => {
          return {
            ...ch,
            label: ch?.accTyName,
            value: ch?.idType,
          };
        }),
      };
    });
    setlist(r);
  }, []);

  const errorMessage = props.errorMessage
    ? props.errorMessage
    : `${t('WORD_ERROR_PLEASE_SELECT')} ${placeholder ? placeholder : label}!`;

  return (
    <Form.Item
      name={name}
      label={label}
      hasFeedback={false}
      hidden={hidden}
      className={`select-group-app select-account-type ${className}`}
      rules={[
        { required: required && !hidden, message: errorMessage },
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value) {
              return Promise.resolve();
            }
            if (getFieldValue(name) !== '') {
              return Promise.resolve();
            }
            return Promise.reject(errorMessage);
          },
        }),
      ]}
    >
      <Select
        placeholder={placeholder || label || ''}
        onChange={onChange}
        className="select-accoun"
        options={list}
      />
    </Form.Item>
  );
};

export default SelectAccountType;
