import { Segmented, Form } from 'antd';
import React from 'react';
import './MySegmentedForm.scss';

const MySegmentedForm = (props) => {
  const {
    block,
    options,
    onChange,
    className,
    label,
    name,
    hidden,
    required,
    disabled,
  } = props;

  return (
    <Form.Item
      label={label}
      name={name}
      hidden={hidden}
      className={`app-input ${className || ''}`}
      rules={[
        {
          required: required && !hidden,
        },
      ]}
    >
      <Segmented
        block={block}
        options={options}
        onChange={onChange}
        disabled={disabled}
        className="my-segment"
      />
    </Form.Item>
  );
};

export default MySegmentedForm;
