import React, { memo } from 'react';
import MyIcon from './MyIcon';

const SVGIcon = (props) => {
  const { size } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size || 35}
      height={size || 35}
      viewBox="0 0 50 50"
      fill="none"
    >
      <path
        d="M21.3342 8.27788L35.1328 10.7825C39.0247 11.4879 41.8162 11.9933 43.893 12.7255C43.7193 7.23099 43.0931 4.00348 40.8332 1.99495C40.5146 1.71203 40.1771 1.45116 39.823 1.21416C36.3474 -1.11073 31.3998 0.17875 21.5102 2.75935L13.405 4.87278C10.4343 5.64701 8.17774 6.23548 6.42383 6.88459C9.5978 6.14696 14.1006 6.96654 21.3342 8.27788Z"
        fill="url(#paint0_linear_4116_12470)"
      />
      <path
        d="M48.0645 15.5722C47.9159 15.3788 47.7596 15.1916 47.5957 15.0106C46.6696 13.9861 45.4937 13.2894 43.8923 12.7256C43.9584 14.8324 43.9584 17.2721 43.9584 20.1018C43.9584 26.4886 43.9584 29.6817 42.287 32.0814C42.1114 32.3335 41.9238 32.5762 41.7242 32.8097C39.8239 35.0324 36.734 35.8378 30.5538 37.4491L22.4487 39.562C14.7211 41.5766 10.0129 42.8043 6.66403 42.1355L6.45312 42.0678C8.4917 42.7365 11.1881 43.2261 14.8675 43.8943L28.666 46.399C38.247 48.1381 43.0361 49.0075 46.2947 46.5509C46.5395 46.3662 46.775 46.1695 47.0012 45.9608C49.9998 43.195 49.9998 38.3261 49.9998 28.5889C49.9993 21.5869 49.9993 18.0845 48.0645 15.5722Z"
        fill="url(#paint1_linear_4116_12470)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.8925 12.7253C41.8163 11.9948 39.0226 11.4878 35.1317 10.7813L21.3332 8.27773C14.099 6.9664 9.59569 6.14681 6.42171 6.8828C4.75523 7.49967 3.54279 8.17173 2.60354 9.11043C0.18304 11.7397 0.0120206 16.1222 0 24.1869C0.0131133 33.1067 0.207081 37.733 3.12644 40.3273C3.28125 40.465 3.44043 40.5974 3.60398 40.7245C4.43504 41.3037 5.43275 41.7506 6.66376 42.1391C10.0126 42.8079 14.7208 41.5801 22.4484 39.5656L30.5546 37.4516C36.7348 35.8403 39.8247 35.0349 41.725 32.8122C41.9243 32.5791 42.1119 32.3364 42.2878 32.0839C43.9592 29.6842 43.9592 26.4911 43.9592 20.1043C43.9587 17.2719 43.9586 14.8344 43.8925 12.7253ZM35.8776 20.2715C35.3752 20.2715 34.8841 20.1226 34.4664 19.8435C34.0487 19.5644 33.7231 19.1678 33.5309 18.7037C33.3386 18.2396 33.2882 17.7289 33.3861 17.2362C33.484 16.7435 33.7259 16.2909 34.081 15.9356C34.4361 15.5803 34.8886 15.3383 35.3813 15.2401C35.874 15.142 36.3847 15.1921 36.8489 15.3842C37.313 15.5763 37.7098 15.9017 37.9891 16.3193C38.2683 16.7369 38.4175 17.2279 38.4177 17.7303C38.4179 18.0639 38.3523 18.3944 38.2247 18.7027C38.0971 19.011 37.91 19.2911 37.6741 19.5271C37.4382 19.7631 37.1581 19.9503 36.8499 20.0781C36.5416 20.2058 36.2112 20.2715 35.8776 20.2715Z"
        fill="url(#paint2_linear_4116_12470)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4116_12470"
          x1="17.2396"
          y1="17.3206"
          x2="42.2642"
          y2="-11.3375"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EA6A63" />
          <stop offset="0.84" stopColor="#F6C35D" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4116_12470"
          x1="50.6966"
          y1="14.0574"
          x2="20.0567"
          y2="57.6358"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#77F7FF" />
          <stop offset="1" stopColor="#99B9F5" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_4116_12470"
          x1="-0.975837"
          y1="-3.18452"
          x2="46.4238"
          y2="49.134"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8F3FC0" />
          <stop offset="1" stopColor="#5786FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const LogoSVG = (props) => {
  return <MyIcon icon={<SVGIcon />} {...props} />;
};

export default memo(LogoSVG);
