import { fetchFromUrlPOSTAsync } from '../utils/UrlHelper';

const URL_SHARE = '/budgets_db_share/share';
const URL_UNSHARE = '/budgets_db_share/unshare';
const URL_ACCEPT = '/budgets_db_share/accept';
const URL_CHANGE_PERMISSION = '/budgets_db_share/change_permission';
const URL_GET_ALL_BY_USER = '/budgets_db_share/get_all/user';

const fs = {
  share: async (idKey, values) => {
    let result = await fetchFromUrlPOSTAsync(URL_SHARE + '/' + idKey, {
      saveData: values,
    });
    return result;
  },
  unShare: async (idKey) => {
    let result = await fetchFromUrlPOSTAsync(URL_UNSHARE + '/' + idKey, []);
    return result;
  },
  accept: async (idKey) => {
    let result = await fetchFromUrlPOSTAsync(URL_ACCEPT + '/' + idKey, []);
    return result;
  },
  changePermission: async (idKey, values) => {
    let result = await fetchFromUrlPOSTAsync(
      URL_CHANGE_PERMISSION + '/' + idKey,
      {
        saveData: values,
      }
    );
    return result;
  },
  getAll: async (idKey) => {
    const result = await fetchFromUrlPOSTAsync(
      URL_GET_ALL_BY_USER + '/' + idKey,
      []
    );
    return result;
  },
};

const loc = {
  PERMISSION: { READ: 1, WRITE: 2 },
  STATUS: { PENDING: 1, ACTIVE: 2 },
};

const BudgetShareActions = Object.assign(fs, loc);

export default BudgetShareActions;
