import {
  Button,
  Card,
  FormControlLabel,
  Link,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import useMyTheme from '../theme/useMyTheme';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import ApiStorage from '../redux/ApiStorage';

const MaterialHome = () => {
  const { isDark } = useMyTheme();
  const [check, setChecked] = useState(isDark);

  return (
    <Card sx={{ padding: 2 }}>
      <Typography>TEST</Typography>
      <FormControlLabel
        labelPlacement="top"
        label="Theme"
        control={
          <Switch
            checked={check}
            onChange={(e) => {
              setChecked(!check);
              ApiStorage.setSelectedTheme(e.target.checked ? 'dark' : 'light');
            }}
          />
        }
      />
      <Link href="#">Link</Link>
      <Button>
        <DeleteIcon />
      </Button>
      <Stack spacing={2} direction="column">
        <Stack spacing={1} direction="row">
          <Button variant="text">Text</Button>
          <Button variant="contained">Contained</Button>
          <Button variant="outlined">Outlined</Button>
        </Stack>

        <Stack direction="row" spacing={1}>
          <Button variant="outlined" startIcon={<DeleteIcon />}>
            Delete
          </Button>
          <Button variant="contained" endIcon={<SendIcon />}>
            Send
          </Button>
        </Stack>
      </Stack>
    </Card>
  );
};

export default MaterialHome;
