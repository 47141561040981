import { Popconfirm, Space } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import MyText from '../../components/myText/MyText';
import { primaryColor, successColor } from '../../theme/themeLightConfig';

const CollapseItemLine = ({
  label,
  value,
  style,
  colorRed,
  title,
  disabled,
  okText,
  cancelText,
  handleCancel,
  onConfirm,
}) => {
  const { t } = useTranslation();

  return (
    <Popconfirm
      title={title || t('WORD_ARE_YOU_SURE')}
      onConfirm={onConfirm}
      onCancel={handleCancel}
      okText={okText || t('WORD_YES')}
      cancelText={cancelText || t('WORD_NO')}
      disabled={disabled}
    >
      <Space className="auto-assign-item">
        <MyText style={{ color: primaryColor }}>{label}</MyText>
        <MyText
          strong
          style={{
            fontWeight: 500,
            color:
              parseFloat(value) >= 0 ? successColor : colorRed ? 'red' : ' ',
            ...style,
          }}
        >
          {value}
        </MyText>
      </Space>
    </Popconfirm>
  );
};

export default CollapseItemLine;
