import React, { useCallback, useEffect, useRef, useState } from 'react';
import FormApp from '../../components/formApp/FormApp';
import InfiniteScrollTable from '../../components/infiniteScrollTable/InfiniteScrollTable';
import MyText from '../../components/myText/MyText';
import InputTextFormItem from '../../components/myInputForm/InputTextFormItem';
import InputNumberFormItem from '../../components/myInputForm/InputNumberFormItem';
import { Form, Divider, Dropdown, Button } from 'antd';
import UtilDate from '../../utils/UtilDate';
import UtilNumber from '../../utils/UtilNumber';
import MyButtonMain from '../../components/myButton/MyButtonMain';
import InputFormDatePicker from '../../components/inputFormDatePicker/InputFormDatePicker';
import TransactionActions from '../../actions/TransactionActions';
import useIsMounted from '@utilityjs/use-is-mounted/useIsMounted';
import UtilNotify from '../../utils/UtilNotify';
import MyPageHeader from '../../components/myPageHeader/MyPageHeader';
import { useTranslation } from 'react-i18next';
import MySpace from '../../components/mySpace/MySpace';
import InputText from '../../components/myInputForm/InputText';
import TransactionFilterDrawer from './TransactionFilterDrawer';
import {
  errorColor,
  successColor,
  primaryColor,
} from '../../theme/themeLightConfig';
import TotalsRender from './TotalsRender';
import GetTransactionIcons from '../../components/defaultBudgetCategories/GetTransactionIcons';
import HourglassSVG from '../../icons/HourglassSVG';
import PlusBtnSVG from '../../icons/PlusBtnSVG';
import SelectCategory from '../../components/selects/SelectCategory';
import SelectAccount from '../../components/selects/SelectAccount';
import { useSelector } from 'react-redux';
import MySwitchFormItem from '../../components/mySwitchFormItem/MySwitchFormItem';
import SelectPayee from '../../components/selects/SelectPayee';
import TransactionEditModal from './transactionEdit/TransactionEditModal';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import MyButtonSecondary from '../../components/myButton/MyButtonSecondary';
import { DownOutlined } from '@ant-design/icons';
import SetCategoryModal from './SetCategoryModal';
import CategoriesActions from '../../actions/CategoriesActions';
import './TransactionList.scss';
// import { socketAction } from '../../actions/socketAction';

const formName = 'transaction-list-form';

const TransactionList = () => {
  const canEdit = useSelector((state) => state.canEdit);

  const [form] = Form.useForm();
  const isMounted = useIsMounted();
  const { t } = useTranslation();
  const location = useLocation();

  const tableRef = useRef(null);
  const inputSearchRef = useRef(null);

  const { accountObject, categoryObject } = location?.state || {};

  const defaultFilter = {
    page: 0,
    perPage: 50,
    search: '',
    sort: '',
    ...location?.state,
    idAccount: accountObject?.idAccount || '',
    idCategory: categoryObject?.idCategory || [],
  };

  const [listData, setlistData] = useState([]);
  const [totalRows, settotalRows] = useState(0);
  const [totals, settotals] = useState({});
  const [editingRow, seteditingRow] = useState(null);
  const [selectedRow, setselectedRow] = useState('');
  const [filter, setfilter] = useState(defaultFilter);
  const [loading, setloading] = useState(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  // const [pagination, setpagination] = useState({ page: 0, perPage: 50 });
  // const [sort, setsort] = useState('');
  // const [search, setSearch] = useState('');
  // const [account, setaccount] = useState({});

  const refModal = useRef();
  const refModalSecond = useRef();

  const budgetName = useSelector((state) => {
    return state.currentDbSettings.displayName || '';
  });

  // useEffect(() => {
  //   socketAction.setSocketCallBack_Transactions((tr) => {
  //     if (tr._action === 'INSERT') {
  //       const newArray = [...listData];
  //       newArray.splice(
  //         selectedRow,
  //         // 1,
  //         0,
  //         ...tr.data.map((oo) => {
  //           return {
  //             ...oo,
  //             transactionDate: UtilDate.getDate(oo.transactionDate),
  //           };
  //         })
  //       );
  //       setlistData(newArray);
  //     } else if (tr._action === 'UPDATE') {
  //       const newArray = [
  //         ...listData.filter(
  //           (oo) => !tr.data.map((ooo) => ooo.idKey).includes(oo.idKey)
  //         ),
  //       ];
  //       console.log('newArray = ', newArray);
  //       setlistData(newArray);

  //       //   setlistData((ov) => {
  //       //     return ov.filter(
  //       //       (oo) => !tr.data.map((ooo) => ooo.idKey).includes(oo.idKey)
  //       //     );
  //       //     // .map((oo) => {
  //       //     //   let found = tr.data.find((el) => {
  //       //     //     return el.idKey === oo.idKey;
  //       //     //   });
  //       //     //   if (found) {
  //       //     //     oo = Object.assign({}, oo, found);
  //       //     //   }
  //       //     //   return oo;
  //       //     // });
  //       //   });
  //       // }
  //     }
  //   });
  //   return () => {
  //     socketAction.setSocketCallBack_Transactions(null);
  //   };
  // }, [listData, budgetName]);

  const fillFS = useCallback(async () => {
    setloading(true);
    let result = await TransactionActions.fillPaginationList({
      search: filter?.search,
      pagination: {
        page: filter?.page,
        perPage: filter?.perPage,
      },
      filter: {
        dateFrom: filter?.dateFrom,
        dateTo: filter?.dateTo,
        idAccount: filter?.idAccount,
        idCategory: filter?.idCategory,
        idPayee: filter?.idPayee,
        approved: filter?.approved,
        type: filter?.type,
      },
      sort: filter?.sort,
    });
    if (isMounted()) {
      if (result.success) {
        const data = result.data.map((oo) => {
          return {
            ...oo,
            transactionDate: UtilDate.getDate(oo.transactionDate),
          };
        });
        if (filter?.page === 0) {
          setlistData(data);
        } else {
          setlistData((ov) => [...ov, ...(data ?? [])]);
        }
        settotalRows(result.totalRows);
        settotals(result.totals);
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
      setloading(false);
    }
  }, [isMounted, filter]);

  useEffect(() => {
    fillFS();
  }, [fillFS]);

  const columns = [
    {
      title: ' ',
      dataIndex: 'Icon',
      // width: '60px',
      render: (_, trr) => {
        return (
          <div onClick={() => refModal.current.open(trr?.idKey)}>
            <GetTransactionIcons item={trr} />
          </div>
        );
      },
    },
    {
      title: t('WORD_DATE'),
      dataIndex: 'transactionDate',
      key: 'transactionDate',
      sorter: true,
      width: 130,
      render: (value, record) => {
        if (editingRow === record?.idKey) {
          return (
            <InputFormDatePicker name="transactionDate" allowClear={false} />
          );
          // return (
          //   <MyText>
          //     {UtilDate.formatDate(value)}
          //   </MyText>
          // );
        } else {
          return <MyText>{UtilDate.formatDate(value)}</MyText>;
        }
      },
    },
    {
      title: t('WORD_ACCOUNT'),
      dataIndex: 'accountName',
      key: 'accountName',
      sorter: true,
      render: (value, record) => {
        if (editingRow === record?.idKey) {
          return (
            <SelectAccount
              name="idAccount"
              style={{ width: 130 }}
              required
              disabled={!canEdit}
              onChangeItem={(ii) => {
                if (ii) {
                  form.setFieldsValue({ accountName: ii.accountName });
                }
              }}
            />
          );
        } else {
          return <MyText>{value}</MyText>;
        }
      },
    },
    {
      title: 'Category',
      dataIndex: 'categoryName',
      key: 'categoryName',
      sorter: true,
      render: (value, { idKey, categoryName, accountNameTo }) => {
        if (editingRow === idKey) {
          return (
            <SelectCategory
              style={{ width: 220 }}
              name="idCategory"
              placeholder="Category"
              onChangeItem={(ii) => {
                if (ii) {
                  form.setFieldsValue({
                    categoryName: ii.categoryName,
                    icon: ii.icon,
                  });
                }
              }}
            />
          );
        } else {
          return (
            <MyText>
              {accountNameTo ? `Transfer to ${accountNameTo}` : categoryName}
            </MyText>
          );
        }
      },
    },
    {
      title: 'Payee',
      dataIndex: 'payeeName',
      key: 'payeeName',
      sorter: true,
      width: 130,
      render: (value, record) => {
        if (editingRow === record?.idKey) {
          return <SelectPayee name="idPayee" />;
        } else {
          return <MyText>{value}</MyText>;
        }
      },
    },
    {
      title: 'Memo',
      dataIndex: 'transactionNote',
      key: 'transactionNote',
      sorter: true,
      render: (value, record) => {
        if (editingRow === record?.idKey) {
          return (
            <InputTextFormItem name="transactionNote" placeholder="Memo" />
          );
        } else {
          return (
            // <Tooltip title={value}>
            <MyText>{value}</MyText>
            // </Tooltip>
          );
        }
      },
    },
    {
      title: 'Inflow',
      dataIndex: 'valueIncome',
      key: 'valueIncome',
      sorter: true,
      width: 130,
      render: (value, record) => {
        if (editingRow === record?.idKey) {
          return (
            <>
              <InputTextFormItem name="idKey" hidden={true} />
              <InputTextFormItem name="categoryName" hidden={true} />
              <InputTextFormItem name="accountName" hidden={true} />
              <InputNumberFormItem name="value" hidden={true} />
              <InputNumberFormItem name="idTransaction" hidden={true} />
              <InputNumberFormItem name="valueIncome" placeholder="Inflow" />
            </>
          );
        } else {
          return <MyText>{value ? UtilNumber.formatDouble(value) : ''}</MyText>;
        }
      },
    },
    {
      title: 'Outflow',
      dataIndex: 'valueExpense',
      key: 'valueExpense',
      sorter: true,
      // width: 200,
      render: (value, record) => {
        if (editingRow === record?.idKey) {
          return (
            <InputNumberFormItem
              name="valueExpense"
              placeholder="Outflow"
              style={
                {
                  // padding: 0,
                  // margin: 0,
                  //   width: 300,
                }
              }
              onChange={(value) => {
                form.setFieldsValue({ valueExpense: value });
              }}
            />
          );
          //   return <InputTextFormItem name="valueExpense" />;
        } else {
          return <MyText>{value ? UtilNumber.formatDouble(value) : ''}</MyText>;
        }
      },
    },
    {
      title: 'Cleared',
      dataIndex: 'approved',
      key: 'approved',
      sorter: true,
      width: 15,
      render: (value, record) => {
        if (editingRow === record?.idKey) {
          return <MySwitchFormItem name="approved" />;
        } else {
          return Boolean(value) ? null : <HourglassSVG />;
        }
      },
    },

    // {
    //   title: 'Action',
    //   key: 'action',
    //   render: (_, record) => {
    //     if (editingRow === record?.idKey) {
    //       return (
    //         <Space size="small">
    //           <MyButton
    //             label="Cancel"
    //             onClick={() => {
    //               handleSetEditable(null);
    //             }}
    //           />
    //           <MyButtonMain label="Save" />
    //         </Space>
    //       );
    //     }
    //     return null;
    //   },
    // },
  ];

  const onChangeTable = (_, __, sorter) => {
    let so = '';
    if (sorter.order) {
      so = `${sorter.columnKey} ${sorter.order === 'ascend' ? 'ASC' : 'DESC'}`;
    }
    setfilter((ov) => Object.assign({}, ov, { sort: so }));
  };

  const handleSetEditable = (record, index) => {
    if (record) {
      form.setFieldsValue(record);
      seteditingRow(record?.idKey);
      setselectedRow(index);
    } else {
      //   form.resetFields();
      seteditingRow(null);
    }
  };

  const onFinish = async (values) => {
    console.log('values = ', values);
    values.idPayee = values.idPayee || '';
    // let idKey = editingRow;
    if (values.valueIncome > 0) {
      values.type = TransactionActions.TYPE.INCOME;
      values.value = values.valueIncome;
    } else {
      values.type = TransactionActions.TYPE.EXPENSE;
      values.value = -values.valueExpense;
    }
    let result = await TransactionActions.saveTransactionForm(
      values,
      values?.idTransaction
    );
    if (result.success) {
      UtilNotify.notifySuccess('WORD_EDITED_SUCCESSFULY');
      // setlistData(
      //   listData.map((oo) => {
      //     if (oo.idKey === idKey) {
      //       oo = Object.assign({}, oo, values);
      //     }
      //     return oo;
      //   })
      // );
      seteditingRow(null);
      // setselectedRow('');
      fillFS();
    } else {
      UtilNotify.notifyErrorServer(result.errMsg);
    }
  };

  const TotalsArray = [
    {
      label: t('WORD_VALUE_INFLOW'),
      value: totals.valueIncome,
      color: successColor,
      key: 1,
      divider: true,
    },
    {
      label: t('WORD_VALUE_OUTFLOW'),
      value: -totals.valueExpense,
      color: errorColor,
      key: 2,
      divider: true,
    },
    {
      label: t('WORD_BALANCE'),
      value: totals.valueBalance,
      color: primaryColor,
      key: 3,
      divider: false,
    },
  ];

  const onSelectChange = (newSelectedRowKeys) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const items = [
    {
      label: t('WORD_CLEARED'),
      key: 'cleared',
    },
    {
      label: t('WORD_DELETE'),
      key: 'delete',
      danger: true,
    },
    {
      label: t('SET_CATEGORY'),
      key: 'category',
      onClick: () => refModalSecond.current.open(),
    },
  ];

  const menuProps = {
    items,
    onClick: items.onClick,
  };

  const hasSelected = selectedRowKeys.length > 0;

  return (
    <>
      <Helmet>
        <title>
          {t('WORD_TRANSACTIONS')} | {budgetName} | {t('WORD_MONEYSCOPE')}{' '}
        </title>
        <meta name="description" content="Some description." />
        <meta
          property="og:title"
          content={
            `${t('WORD_TRANSACTIONS')}` |
            `${budgetName}` |
            `${t('WORD_MONEYSCOPE')}`
          }
        />
        <meta property="og:image" content="public/favicon/mstile-150x150.png" />
      </Helmet>
      <MySpace direction="vertical" size={'middle'} fullWidth>
        <MyPageHeader
          extraLeft={
            <InputText
              ref={inputSearchRef}
              // value={filter?.search}
              // onChange={(v) => {
              //   setSearch(v);
              //   setpagination((ov) => Object.assign(ov, { page: 0 }));
              // }}
              defaultValue={filter?.search}
              placeholder="Amount, Category, Payee, Note"
              onPressEnter={(value) => {
                setfilter((ov) =>
                  Object.assign({}, ov, { search: value, page: 0 })
                );
              }}
              onClear={() => {
                setfilter((ov) =>
                  Object.assign({}, ov, { search: '', page: 0 })
                );
              }}
            />
          }
          extraRight={
            <MySpace size={'large'}>
              {selectedRowKeys.length > 0 && (
                <Dropdown menu={menuProps}>
                  <Button>
                    <MySpace>
                      {hasSelected
                        ? `Selected ${selectedRowKeys.length} items`
                        : ''}
                      <DownOutlined />
                    </MySpace>
                  </Button>
                </Dropdown>
              )}
              {TotalsArray.map((item) => (
                <MySpace key={item.key}>
                  <TotalsRender
                    label={item.label}
                    value={item.value}
                    color={item.color}
                  />
                  {item.divider ? (
                    <Divider
                      style={{ margin: 0 }}
                      type="vertical"
                      className="transaction-divider"
                    />
                  ) : null}
                </MySpace>
              ))}
              <TransactionFilterDrawer
                loading={loading}
                filter={filter}
                onChange={(value) =>
                  setfilter((ov) => Object.assign({}, ov, value, { page: 0 }))
                }
                onReset={() => {
                  tableRef?.current?.scrollTop();
                  inputSearchRef?.current?.clearInput();
                  setfilter(defaultFilter);
                }}
              />
              <MyButtonMain
                label={t('WORD_ADD_TRANSACTION')}
                icon={<PlusBtnSVG isMainButtonIcon />}
                onClick={() => {
                  const idKey = new Date().getMilliseconds();
                  const obj = {
                    idKey: idKey,
                    idCurrency: 2,
                    type: TransactionActions.TYPE.EXPENSE,
                    status: TransactionActions.STATUS.ACTIVE,
                    ordering: 1000,
                    value: '',
                    valueIncome: '',
                    valueExpense: '',
                    transactionNote: null,
                    accountName: null,
                    accountNameTo: null,
                    payeeName: null,
                    categoryName: '',
                    idCategory: '',
                    idAccount: '',
                    idAccountTo: '',
                    idPayee: '',
                    transactionDate: UtilDate.getDate(),
                    isNew: true,
                  };
                  setlistData((ov) => {
                    ov.unshift(obj);
                    return [...ov];
                  });
                  seteditingRow(idKey);
                  setselectedRow(0);
                  form.setFieldsValue(obj);
                }}
              />
            </MySpace>
          }
          title={
            accountObject?.accountName ||
            categoryObject?.categoryName ||
            t('WORD_ALL_TRANSACTION')
          }
        />
        <FormApp name={formName} form={form} onFinish={onFinish}>
          <InfiniteScrollTable
            ref={tableRef}
            height={'86vh'}
            dataSource={listData}
            columns={columns}
            expandable={{
              expandIcon: () => null,
              columnWidth: 0,
              expandedRowKeys: [editingRow],
              expandedRowRender: (record, index) => (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                  }}
                >
                  <div
                    style={{ display: 'flex', flexDirection: 'row', gap: 10 }}
                  >
                    <MyButtonSecondary
                      label="Cancel"
                      onClick={() => {
                        handleSetEditable(null);
                        setselectedRow(index);
                        form.resetFields();
                        if (record?.isNew) {
                          setlistData((ov) => {
                            ov.splice(0, 1);
                            return [...ov];
                          });
                        }
                      }}
                    />
                    <MyButtonMain label="Save" form={formName} />
                  </div>
                </div>
              ),
              rowExpandable: (record) => record.name !== 'Not Expandable',
            }}
            rowKey="idKey"
            selectedRow={selectedRow}
            dataLength={totalRows}
            loading={loading}
            onChange={onChangeTable}
            onRowDoubleClick={(index, record) => {
              handleSetEditable(record);
              setselectedRow(index);
            }}
            onRowClick={(index, record) => {
              handleSetEditable(null);
              setselectedRow(index);
            }}
            onLoadMore={() => {
              setfilter((ov) => Object.assign({}, ov, { page: ov.page + 1 }));
            }}
            rowSelection={rowSelection}
          />
        </FormApp>
        <TransactionEditModal
          trigger={null}
          // onSave={fill}
          ref={refModal}
        />
        <SetCategoryModal
          ref={refModalSecond}
          transactionType={CategoriesActions.TYPEINCOMEEXPENSE.EXPENSE}
          onSelect={(cat) => {
            console.log('cat = ', cat);
          }}
        />
      </MySpace>
    </>
  );
};

export default TransactionList;
