import React from 'react';
import MainLayoutSideMenu from './MainLayoutSideMenu';
import { Layout } from 'antd';
import './MainLayout.scss';

const { Content } = Layout;

const MainLayout = (props) => {
  const { children, fullPage } = props;

  return (
    <Layout hasSider style={{ height: '100vh', overflow: 'hidden' }}>
      <MainLayoutSideMenu />
      <Layout
        style={{
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        <Content style={{ width: fullPage ? '100%' : 600, padding: 10 }}>
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
