import React from 'react';
import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import './MyInputForm.scss';

const { TextArea } = Input;

const InputTextAreaFormItem = ({
  classNameInput = '',
  className = '',
  label,
  name,
  placeholder,
  required = false,
  hidden = false,
  readOnly,
  allowClear = true,
  rows = 4,
  onBlur,
  autoSize = true,
  bordered = true,
  style,
}) => {
  const { t } = useTranslation();

  return (
    <Form.Item
      name={name}
      label={label}
      hidden={hidden}
      className={`app-input-textaria ${className}`}
      rules={[
        {
          required: required && !hidden,
          message: `${t('WORD_PLEASE_INPUT_YOUR_USERNAME')}`,
        },
      ]}
    >
      <TextArea
        className={`my-input input-text-area ${classNameInput}`}
        placeholder={placeholder || label || ''}
        autoComplete={'nope'}
        allowClear={allowClear}
        rows={rows}
        readOnly={readOnly}
        variant={!bordered ? 'borderless' : 'outlined'}
        onBlur={onBlur}
        autoSize={autoSize}
        style={style}
      />
    </Form.Item>
  );
};

export default InputTextAreaFormItem;
