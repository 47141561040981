import React, { useCallback, useMemo, useState } from 'react';
import MyCard from '../../../components/myCard/MyCard';
import { Divider } from 'antd';
import MyTextTitle from '../../../components/myText/MyTextTitle';
import MyTextSecondary from '../../../components/myText/MyTextSecondary';
import SocialLoginAppleButton from '../../../components/socialLoginAppleButton/SocialLoginAppleButton';
import SocialLoginGoogleButton from '../../../components/socialLoginGoogleButton/SocialLoginGoogleButton';
import FormApp from '../../../components/formApp/FormApp';
import InputEmailFormItem from '../../../components/myInputForm/InputEmailFormItem';
import InputPasswordFormItem from '../../../components/myInputForm/InputPasswordFormItem';
import MyButtonMain from '../../../components/myButton/MyButtonMain';
import { useForm } from 'antd/es/form/Form';
import MyCaptcha from '../../../components/myGoogleCaptcha/MyCaptcha';
import ReturnToLogIn from '../../../components/returnToLogIn/ReturnToLogIn';
import MySpace from '../../../components/mySpace/MySpace';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useIsMounted from '@utilityjs/use-is-mounted/useIsMounted';
import UsersActions from '../../../actions/UsersActions';
import UtilNotify from '../../../utils/UtilNotify';
import md5 from 'md5';
import UtilFirebase from '../../../utils/UtilFirebase';

const formName = 'register_form';

const Register = () => {
  const [form] = useForm();
  const { t } = useTranslation();

  const navigate = useNavigate();
  const isMounted = useIsMounted();

  const [saving, setSaving] = useState(false);
  const [CaptchaCheck, setCaptchaCheck] = useState(false);

  const handleAfterSuccessRegister = useCallback(
    async (obj) => {
      let result = await UsersActions.register(obj);
      if (isMounted()) {
        if (result.success) {
          navigate('/home');
        } else {
          UtilNotify.notifyErrorServer(result.errMsg);
          setSaving(false);
        }
      }
    },
    [isMounted, navigate]
  );

  const OurRegister = useMemo(() => {
    const onFinish = async (values) => {
      setSaving(true);
      await handleAfterSuccessRegister({
        email: values.email,
        password: md5(values.password),
        registerType: UtilFirebase.REGISTERTYPE.OURSITE,
      });
    };

    return (
      <FormApp
        className={'form-wrapper'}
        form={form}
        name={formName}
        onFinish={onFinish}
        initialValues={{ rememberMe: true }}
      >
        <InputEmailFormItem name="email" placeholder="Email" />
        <InputPasswordFormItem name="password" placeholder="Password" />
        <MyButtonMain
          fullWidth
          label="Register"
          form={formName}
          disabled={!CaptchaCheck}
          loading={saving}
          htmlType="submit"
          // onClick={() => navigate('/activate')}
        />
      </FormApp>
    );
  }, [handleAfterSuccessRegister, saving, CaptchaCheck, form]);

  return (
    <MyCard className="login-card">
      <MySpace direction="vertical" style={{ marginBottom: 50 }}>
        <MyTextTitle size={30}>{t('WORD_REGISTER')}</MyTextTitle>
        <MyTextSecondary>
          {t('WORD_GAIN_TOTAL_VONTROL_OF_YOUR_MONEY')}
        </MyTextSecondary>
      </MySpace>
      <MySpace direction="vertical" size={'large'} fullWidth>
        <SocialLoginAppleButton
          onSuccess={handleAfterSuccessRegister}
          disabled={!CaptchaCheck}
        />
        <SocialLoginGoogleButton
          onSuccess={handleAfterSuccessRegister}
          disabled={!CaptchaCheck}
        />
      </MySpace>
      <Divider>
        <MyTextSecondary>or</MyTextSecondary>
      </Divider>
      {OurRegister}
      <ReturnToLogIn withMarginTop withMarginBottom />
      <MyCaptcha
        onChange={() => {
          if (isMounted()) {
            setCaptchaCheck(true);
          }
        }}
      />
    </MyCard>
  );
};

export default Register;
