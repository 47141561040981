import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useIsMounted from '@utilityjs/use-is-mounted';
import CurrenciesActionsMain from '../../actions/CurrenciesActionsMain';
import MySelectFormItem from '../mySelectFormItem/MySelectFormItem';

const SelectCurrencyMain = (props) => {
  const { name, label, withAll, required } = props;

  const isMounted = useIsMounted();
  const { t } = useTranslation();

  const [list, setlist] = useState([]);
  const [loading, setloading] = useState(false);
  const [search, setSearch] = useState('');

  useEffect(() => {
    const fill = async () => {
      if (t) {
        setloading(true);
        let result = await CurrenciesActionsMain.fillPaginationList({
          search: search,
          pagination: {
            page: 0,
            perPage: 3000,
          },
          filter: { useProject: true },
        });
        if (isMounted()) {
          if (result.success) {
            let data = result.data.map((oo) => {
              return {
                label: `${oo.currencyName} ${oo.currencySymbol}`,
                value: oo.idCurrency,
              };
            });
            if (withAll) data.unshift({ label: t('WORD_ALL'), value: '' });
            setlist(data);
          }
          setloading(false);
        }
      }
    };
    fill();
  }, [isMounted, t, withAll, search]);

  return (
    <MySelectFormItem
      name={name}
      label={label}
      options={list}
      onSearch={setSearch}
      showSearch
      loading={loading}
      required={required}
    />
  );
};

export default SelectCurrencyMain;
