import React from 'react';
import { Typography } from 'antd';

const { Text } = Typography;

const MyTextSecondary = ({ children, size = 14, bold, style }) => {
  return (
    <Text
      style={{ fontSize: size, color: '#bec2ce', ...style }}
      strong={bold || false}
    >
      {children}
    </Text>
  );
};

export default MyTextSecondary;
