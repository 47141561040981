import { Space } from 'antd';
import React from 'react';

const MySpaceColumn = ({
  children,
  size = 'small',
  style,
  align,
  className,
  fullWidth,
}) => {
  return (
    <Space
      style={{ width: fullWidth ? '100%' : '', ...style }}
      direction="vertical"
      size={size}
      align={align}
      className={className || ''}
    >
      {children}
    </Space>
  );
};

export default MySpaceColumn;
