import React from 'react';
import { toast } from 'react-toastify';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  WarningOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import i18n from '../i18n';

const config = {
  closeButton: false,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: true,
  pauseOnFocusLoss: false,
  position: 'top-center',
  hideProgressBar: true,
  autoClose: 3000,
  newestOnTop: true,
  icon: ({ _, type }) => {
    switch (type) {
      case 'error':
        return <CloseCircleOutlined />;
      case 'success':
        return <CheckCircleOutlined />;
      case 'warning':
        return <WarningOutlined />;
      case 'toast':
        return <InfoCircleOutlined />;
      default:
        break;
    }
  },
};

const UtilNotify = {
  notifySuccess: (text) => {
    text = i18n.t(text);
    toast.success(text, config);
  },
  notifyError: (text) => {
    text = i18n.t(text);
    toast.error(text, config);
  },
  notifyErrorServer: (text) => {
    if (text) {
      if (text.startsWith('Duplicate entry')) {
        // text = "WARNNING_DUBLICATE_ACCCOUNT_REGISTER"
      } else if (text.startsWith('There is no Account with this email')) {
        text = 'WARNNING_THERE_IS_NO_ACCOUNT';
      } else if (
        text.startsWith(
          'Invalid value, Invalid value, saveData.email field is required and cannot be empty & Must be valid email'
        )
      ) {
        text = 'WARNNING_EMAIL_REQUIRED_VALID';
      } else if (text.startsWith('check your password and try again')) {
        text = 'WARNNING_CHECK_YOUR_PASSWORD';
      }
      text = i18n.t(text);
    }
    toast.error(text, config);
  },
  notifywarning: (text) => {
    if (text.startsWith('auth/user-cancelled')) {
      text = 'WARNNING_USER_CANCELLED';
    } else if (text.startsWith('auth/popup-closed-by-user')) {
      text = 'WARNNING_POPUP_CLOSED_BY_USER';
    } else if (text.startsWith('auth/cancelled-popup-request')) {
      text = 'WARNING_AUTH_ CANCELLED_POPUP _REQUEST';
    }
    text = i18n.t(text);
    toast.warning(text, config);
  },
  notifyText: (text) => {
    text = i18n.t(text);
    toast(text, config);
  },
};

export default UtilNotify;
