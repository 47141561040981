import React, { useEffect, useState } from 'react';
import { Layout, Menu, Space } from 'antd';
import MenuSVG from '../../icons/MenuSVG';
import ActivityOutlineSVG from '../../icons/ActivityOutlineSVG';
import { useSelector } from 'react-redux';
import ApiStorage from '../../redux/ApiStorage';
import BudgetsOutlineSVG from '../../icons/BudgetsOutlineSVG';
import TransactionsOutlineSVG from '../../icons/TransactionsOutlineSVG';
import AccountsOutlineSVG from '../../icons/AccountsOutlineSVG';
import HeaderMenuLeft from './HeaderMenuLeft';
import useMyTheme from '../../theme/useMyTheme';
import MyLink from '../../components/myLink/MyLink';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import './MainLayoutSideMenu.scss';

const { Sider } = Layout;

const MainLayoutSideMenu = () => {
  const { isDark } = useMyTheme();
  const location = useLocation();

  const { t } = useTranslation();

  const [selectedMenuKey, setselectedMenuKey] = useState('');

  const sideMenuColapsed = useSelector((state) => {
    return state.sideMenuColapsed;
  });

  useEffect(() => {
    if (location?.pathname.startsWith('/activity')) {
      setselectedMenuKey('activity');
    } else if (location?.pathname.startsWith('/category')) {
      setselectedMenuKey('category');
    } else if (location?.pathname.startsWith('/transaction')) {
      setselectedMenuKey('transaction');
    } else if (location?.pathname.startsWith('/account')) {
      setselectedMenuKey('account');
    }
  }, [location]);

  const items = [
    {
      key: 'activity',
      label: (
        <MyLink style={{ fontSize: 16 }} path="/activity" state={{}}>
          {t('WORD_ACTIVITY')}
        </MyLink>
      ),
      icon: <ActivityOutlineSVG />,
    },
    {
      key: 'category',
      label: (
        <MyLink style={{ fontSize: 16 }} path="/category/list" state={{}}>
          {t('WORD_BUDGET')}
        </MyLink>
      ),
      icon: <BudgetsOutlineSVG />,
    },
    {
      key: 'transaction',
      label: (
        <MyLink style={{ fontSize: 16 }} path="/transaction/list" state={{}}>
          {t('WORD_ALL_TRANSACTION')}
        </MyLink>
      ),
      icon: <TransactionsOutlineSVG />,
    },
    {
      key: 'account',
      label: (
        <MyLink style={{ fontSize: 16 }} path="/accounts/list" state={{}}>
          {t('WORD_ACCOUNTS')}
        </MyLink>
      ),
      icon: <AccountsOutlineSVG />,
    },
  ];

  return (
    <Sider
      theme="light"
      collapsible
      collapsed={sideMenuColapsed}
      onCollapse={ApiStorage.setSideMenuColapsed}
      width={300}
      trigger={<MenuSVG />}
    >
      <Space.Compact direction="vertical" block="true">
        <div className="side-menu-header">
          <HeaderMenuLeft />
        </div>
        <Menu
          theme={isDark ? 'dark' : 'light'}
          className="main-side-menu"
          defaultSelectedKeys={['1']}
          mode="inline"
          items={items}
          selectedKeys={[selectedMenuKey]}
          onSelect={(menu) => {
            setselectedMenuKey(menu?.key);
          }}
        />
      </Space.Compact>
    </Sider>
  );
};

export default MainLayoutSideMenu;
