import React, { memo } from 'react';
import MyIcon from './MyIcon';

const SVGIcon = (props) => {
  const { color, rotate, size } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size || 5}
      height={size || 9}
      viewBox="0 0 5 9"
      transform={rotate ? `rotate(${rotate})` : 'rotate(180)'}
      // style={{ marginTop: 8 }}
    >
      <path
        fill={color}
        d="M0.663796 8.74995C0.749667 8.75099 0.83483 8.73433 0.913973 8.70099C0.993116 8.66766 1.06454 8.61837 1.12379 8.5562L4.75873 4.91998C4.83327 4.85009 4.89303 4.76596 4.93447 4.67256C4.9759 4.57916 4.99818 4.47839 4.99998 4.37623C5.00082 4.27364 4.97974 4.17206 4.93813 4.07828C4.89653 3.9845 4.83536 3.9007 4.75873 3.83249L1.12379 0.19376C1.06422 0.13201 0.99274 0.0829972 0.91367 0.0496932C0.834601 0.0163891 0.749592 -0.000512829 0.663796 1.18513e-05C0.575774 0.000334547 0.488688 0.0180963 0.40757 0.0522717C0.326453 0.086447 0.252911 0.136358 0.191195 0.199121C0.129478 0.261884 0.0808107 0.336255 0.0480038 0.417936C0.0151968 0.499618 -0.00109909 0.586991 5.75229e-05 0.675007C0.000294541 0.767977 0.0188526 0.859988 0.0546707 0.945781C0.0904889 1.03157 0.142865 1.10946 0.208804 1.175L3.43125 4.37748L0.208804 7.57996C0.142865 7.6455 0.0904889 7.72339 0.0546707 7.80918C0.0188526 7.89498 0.000294541 7.98699 5.75229e-05 8.07996C-0.000436137 8.16755 0.0163495 8.25439 0.0494538 8.33549C0.082558 8.41659 0.131331 8.49037 0.192981 8.5526C0.254632 8.61484 0.327949 8.6643 0.408738 8.69816C0.489526 8.73203 0.576199 8.74962 0.663796 8.74995Z"
      />
    </svg>
  );
};

const ArrowSVG = (props) => {
  return <MyIcon icon={<SVGIcon />} {...props} />;
};

export default memo(ArrowSVG);
