import React, { useState } from 'react';
import MyCard from '../../../components/myCard/MyCard';
import MyPageHeader from '../../../components/myPageHeader/MyPageHeader';
import { useTranslation } from 'react-i18next';
import useIsMounted from '@utilityjs/use-is-mounted/useIsMounted';
import { useForm } from 'antd/es/form/Form';
import { useNavigate } from 'react-router-dom';
import FormApp from '../../../components/formApp/FormApp';
import MySpaceColumn from '../../../components/mySpace/MySpaceColumn';
import InputPasswordFormItem from '../../../components/myInputForm/InputPasswordFormItem';
import InputPasswordConfirmFormItem from '../../../components/myInputForm/InputPasswordConfirmFormItem';
import MyButtonMain from '../../../components/myButton/MyButtonMain';
import md5 from 'md5';
import UsersActions from '../../../actions/UsersActions';
import UtilNotify from '../../../utils/UtilNotify';

const formName = 'user_change_password_form';

const UserChangePassword = () => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const [form] = useForm();
  const navigate = useNavigate();

  const [saving, setsaving] = useState(false);

  const onFinish = async (values) => {
    setsaving(true);
    values.password = md5(values.password);
    // values.currentPassword = md5(values.currentPassword)
    let result = await UsersActions.changePassword(values);
    if (isMounted()) {
      if (result.success) {
        UtilNotify.notifySuccess('WORD_SAVED_SUCCESSFULY');
        navigate('/users/account_settings');
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
      setsaving(false);
    }
  };
  return (
    <MyCard fullHeight>
      <MyPageHeader
        title={t('WORD_CHANGE_PASSWORD')}
        onBack={() => {
          navigate(-1);
        }}
      />
      <FormApp
        style={{ marginTop: 10 }}
        name={formName}
        onFinish={onFinish}
        form={form}
      >
        <MySpaceColumn fullWidth>
          <InputPasswordFormItem
            name="password"
            label={t('WORD_NEW_PASSWORD')}
            required
          />
          <InputPasswordConfirmFormItem
            name="passwordConfirm"
            label={t('WORD_CONFIRM_PASSWORD')}
            required
          />
        </MySpaceColumn>
      </FormApp>
      <MyButtonMain
        fullWidth
        loading={saving}
        form={formName}
        label={t('WORD_SAVE')}
      />
    </MyCard>
  );
};

export default UserChangePassword;
