import React from 'react';
// import colors from '../Color.scss';
// import useMyTheme from '../utils/useMyTheme';
const AppleSVG = (props) => {
  const { size } = props;
  return (
    <svg
      width={size || 24}
      height={size || 24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M17.6859 12.9187C17.6597 10.2805 19.8373 9.01518 19.9347 8.95126C18.7108 7.16183 16.8041 6.9163 16.1255 6.88836C14.5042 6.72368 12.9603 7.8434 12.1377 7.8434C11.3169 7.8434 10.0461 6.91249 8.70155 6.9362C6.93287 6.96244 5.30261 7.9649 4.39245 9.54816C2.55561 12.7346 3.92255 17.4569 5.7124 20.043C6.58743 21.3071 7.63094 22.7295 9 22.6774C10.3195 22.6253 10.8174 21.824 12.4125 21.824C14.0076 21.824 14.4555 22.6774 15.8512 22.6507C17.2702 22.6249 18.1698 21.3625 19.0376 20.0934C20.0422 18.6248 20.4558 17.2029 20.4804 17.1309C20.4495 17.1165 17.7143 16.0688 17.6859 12.9187Z" />
      <path d="M15.0636 5.17684C15.7896 4.29546 16.2815 3.07076 16.1477 1.85156C15.1 1.8939 13.8317 2.54794 13.0794 3.42848C12.4055 4.20995 11.8162 5.45412 11.9741 6.65131C13.1429 6.7419 14.335 6.05653 15.0636 5.17684Z" />
    </svg>
  );
};

export default AppleSVG;
