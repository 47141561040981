import React from 'react';
import MySpace from '../../components/mySpace/MySpace';
import ActivityChartSection from './ActivityChartSection';
import ActivityBudgetOverviewHeader from './ActivityBudgetOverviewHeader';
import { useTranslation } from 'react-i18next';
import './Activity.js';

const ActivityBudgetOverview = (props) => {
  const { month, year, totals } = props;

  const { t } = useTranslation();

  return (
    <div
      style={{
        width: 320,
        borderRadius: 20,
        // height: '100vh',
      }}
    >
      <MySpace direction="vertical" fullWidth>
        <ActivityBudgetOverviewHeader totals={totals} />
        <ActivityChartSection
          month={month}
          year={year}
          title={t('WORD_SPENDING_TRENDS')}
          isIncome={false}
        />
        <ActivityChartSection
          month={month}
          year={year}
          title={t('WORD_INCOME_TRENDS')}
          isIncome={true}
        />
      </MySpace>
    </div>
  );
};

export default ActivityBudgetOverview;
