import React from 'react';
import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import './MyInputForm.scss';

const InputEmailFormItem = ({
  classNameInput = '',
  className = '',
  label,
  name,
  placeholder,
  required = true,
  prefix,
  hidden = false,
  readOnly,
  allowClear = true,
  bordered = true,
  style,
}) => {
  const { t } = useTranslation();

  return (
    <Form.Item
      label={label}
      name={name}
      hidden={hidden}
      style={style}
      className={`app-input ${className}`}
      rules={[
        {
          required: required && !hidden,
          message: `${t('WORD_PLEASE_INPUT_YOUR')} ${label || placeholder}`,
        },
        {
          type: 'email',
          message: `${placeholder || label} ${t('WORD_NOT_VALID_EMAIL')}`,
        },
      ]}
    >
      <Input
        className={`my-input input-email ${classNameInput}`}
        placeholder={placeholder || label || ''}
        prefix={prefix}
        readOnly={readOnly}
        autoComplete={'nope'}
        allowClear={allowClear}
        variant={!bordered ? 'borderless' : 'outlined'}
      />
    </Form.Item>
  );
};

export default InputEmailFormItem;
