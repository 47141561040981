import React, { useRef, useState } from 'react';
import {
  Line,
  // getElementAtEvent,
  // getDatasetAtEvent,
  // getElementsAtEvent,
} from 'react-chartjs-2';
import useWindowSize from '../../utils/useWindowSize';
import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import MyText from '../myText/MyText';
import MySpace from '../mySpace/MySpace';
import './MyLineChart.scss';

Chart.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const MyLineChart = (props) => {
  const { data, onSelect } = props;

  const chartRef = useRef();

  const { isMobile } = useWindowSize();

  const [selectedIndex, setselectedIndex] = useState(6);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        beginAtZero: false,
        grid: {
          // display: false,
          // borderDash: [5, 7],
          lineWidth: 20,
          borderRadius: 12,
          color: 'rgba(136,112,230, 0.08)',
        },
        ticks: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          // borderDash: [5, 7],
          // drawBorder: true,
          // color: 'rgba(118, 118, 118, 0.3)',
          // display: false,
        },
        ticks: {
          display: false,

          callback(value, index) {
            if (index % 2 === 0) return '';
            return value;
          },
        },
      },
    },
  };

  //   const options = {
  //     responsive: true,
  //     plugins: {
  //       legend: {
  //         position: 'top',
  //       },
  //       title: {
  //         display: true,
  //         text: 'Chart.js Line Chart',
  //       },
  //     },
  //   };

  return (
    <div className="my-line-chart-container">
      <Line
        ref={chartRef}
        options={options}
        data={data}
        // title="Chart"
        height={isMobile ? 50 : 80}
        // onClick={(e, element) => {
        //   console.log('e = ', e, 'element ', element);

        //   if (element?.length > 0) {
        //     console.log(element, element[0]._datasetInde);
        //     // you can also get dataset of your selected element
        //     console.log(data.datasets[element[0]._datasetIndex]);
        //   }

        //   // if (!e.length) return;

        //   // const { index } = e[0];

        //   // console.log(data.labels[index]);
        //   console.log('chartRef.current = ', chartRef.current);
        //   const it = getElementAtEvent(chartRef.current, e);
        //   console.log('it = ', it);
        //   // const se = getDatasetAtEvent(chartRef.current, e);
        //   // console.log('se = ', se);
        //   //     console.log('it = ', it, 'se', se, 'see', see);
        //   //     if (it && it[0]) {
        //   //       const index = it[0].index;
        //   //       onSelect && onSelect(data[index]);
        //   //     }
        // }}
      />
      <MySpace fullWidth spaceBetween>
        {data.labels.map((lab, index) => {
          return (
            <MyText
              key={index}
              className={`${
                selectedIndex === index
                  ? 'selected-index'
                  : 'my-line-chart-labels'
              }`}
              onClick={() => {
                setselectedIndex(index);
                onSelect(index, lab);
              }}
            >
              {lab}
            </MyText>
          );
        })}
      </MySpace>
    </div>
  );
};

export default MyLineChart;
