import React from 'react';

const MyDefaultIconImage = ({ url }) => {
  return (
    <div style={{ width: 35, height: 35 }}>
      <img style={{ width: 35, height: 35 }} src={url} alt="categoryIcon" />
    </div>
  );
};

export default MyDefaultIconImage;
