import React from 'react';
import { CopyOutlined } from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { message } from 'antd';
import useMyTheme from '../../utils/useMyTheme';
import colors from '../../Color.scss';
import '../buttonCopy/ButtonCopy.scss';

const ButtonCopy = (props) => {
  const { isDark } = useMyTheme();
  const { style, className, value } = props;
  return props.disabled ? (
    <CopyOutlined
      className={className}
      style={style || isDark ? colors.textColorDark : colors.primaryColor}
      disabled={true}
    />
  ) : (
    <CopyToClipboard
      text={value}
      onCopy={() => {
        message.info(`Copied ${value}`);
      }}
    >
      <CopyOutlined
        className={className}
        style={{ color: isDark ? colors.textColorDark : colors.primaryColor }}
      />
    </CopyToClipboard>
  );
};

export default ButtonCopy;
