import MyLink from '../myLink/MyLink';
import { useTranslation } from 'react-i18next';
import { memo } from 'react';
// import { useLocation } from 'react-router-dom';
import MyTextSecondary from '../myText/MyTextSecondary';
import MySpace from '../mySpace/MySpace';
import './ReturnToLogIn.scss';

const ReturnToLogIn = (props) => {
  const { withMarginTop, withMarginBottom, style } = props;
  const { t } = useTranslation();
  // const location = useLocation();

  return (
    <MySpace
      style={{
        marginTop: withMarginTop ? 40 : 0,
        marginBottom: withMarginBottom ? 15 : 0,
        ...style,
      }}
    >
      <MyTextSecondary>{t('WORD_ALREADY_HAVE_AN_ACCOUNT')}</MyTextSecondary>
      <MyLink style={{ fontWeight: 600 }} path="/login">
        {t('WORD_LOGIN')}
      </MyLink>
    </MySpace>
  );
};

export default memo(ReturnToLogIn);
