import React, { useCallback, useEffect, useState } from 'react';
import MyCard from '../../components/myCard/MyCard';
import MySpace from '../../components/mySpace/MySpace';
import MyTextTitle from '../../components/myText/MyTextTitle';
import MyCollapse from '../../components/myCollapse/MyCollapse';
import { ThunderboltOutlined } from '@ant-design/icons';
import CollapseItemLine from './CollapseItemLine';
import { useTranslation } from 'react-i18next';
import useIsMounted from '@utilityjs/use-is-mounted/useIsMounted';
import CategoriesActions from '../../actions/CategoriesActions';
import UtilNotify from '../../utils/UtilNotify';
import UtilNumber from '../../utils/UtilNumber';
import MyDivider from '../../components/myDivider/MyDivider';
import './Categories.scss';

const CategoryAutoAssignSection = (props) => {
  const { item, month, year } = props;
  const { t } = useTranslation();
  const isMounted = useIsMounted();

  const [loading, setloading] = useState(false);
  const [data, setdata] = useState({});

  const fillFS = useCallback(async () => {
    setloading(true);
    let objToSend = { month, year };
    if (item?.idCategory) {
      objToSend.idCategory = item?.idCategory;
    }
    let result = await CategoriesActions.fillAutoAssignTotal(objToSend);
    if (isMounted()) {
      if (result?.success) {
        setdata(result.data);
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
      setloading(false);
    }
  }, [item, month, year, isMounted]);

  useEffect(() => {
    fillFS();
  }, [fillFS]);

  const handleAssign = async (type) => {
    let objToSend = { month, year };
    if (item?.idCategory) {
      objToSend.idCategory = item?.idCategory;
    }
    let result = await CategoriesActions.setAssignBudgeted(objToSend, type);
    if (isMounted()) {
      if (result.success) {
        UtilNotify.notifySuccess(t('WORD_CATEGORY_BUDGET_SET_SUCCESSFULLY'));
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
    }
  };

  const getItem = () => {
    return {
      key: '1',
      label: (
        <MySpace fullWidth spaceBetween>
          <MySpace>
            <ThunderboltOutlined />
            <MyTextTitle size={14}>{t('WORD_AUTO_ASSIGN')}</MyTextTitle>
          </MySpace>
        </MySpace>
      ),
      children: (
        <>
          <MyDivider />
          <MySpace direction="vertical" fullWidth style={{ marginBottom: 8 }}>
            <CollapseItemLine
              label={t('WORD_ASSIGN_LAST_MONTH')}
              value={UtilNumber.formatDouble(data?.assignLastMonth || 0)}
              onConfirm={() => handleAssign(1)}
            />
            <CollapseItemLine
              label={t('WORD_SPENT_LAST_MONTH')}
              value={UtilNumber.formatDouble(data?.spentLastMonth || 0)}
              onConfirm={() => handleAssign(2)}
            />
            <CollapseItemLine
              label={t('WORD_AVERAGE_ASSIGNED')}
              value={UtilNumber.formatDouble(data?.assignAverage || 0)}
              onConfirm={() => handleAssign(3)}
            />
            <CollapseItemLine
              label={t('WORD_AVERAGE_SPENT')}
              value={UtilNumber.formatDouble(data?.spentAverage || 0)}
              onConfirm={() => handleAssign(4)}
            />
            <CollapseItemLine
              label={t('WORD_RESET_AMOUNT_TO')}
              value={0}
              onConfirm={() => handleAssign(5)}
            />
          </MySpace>
        </>
      ),
    };
  };

  return (
    <MyCard className="auto-assign-card">
      <MyCollapse
        key={'1'}
        loading={loading}
        expandIconPosition="end"
        items={[getItem()]}
        defaultActiveKey={'1'}
        collapsible="header"
      />
    </MyCard>
  );
};

export default CategoryAutoAssignSection;
