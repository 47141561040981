import React, { useEffect } from 'react';
import MyText from '../../../components/myText/MyText';
import MySpace from '../../../components/mySpace/MySpace';
import InputNumberFormItem from '../../../components/myInputForm/InputNumberFormItem';
import MySwitchFormItem from '../../../components/mySwitchFormItem/MySwitchFormItem';
import MyTextSecondary from '../../../components/myText/MyTextSecondary';
import { InfoCircleOutlined } from '@ant-design/icons';
import InputFormDatePicker from '../../../components/inputFormDatePicker/InputFormDatePicker';
import { useTranslation } from 'react-i18next';
import { Form } from 'antd';
import UtilNumber from '../../../utils/UtilNumber';
import PercentInput from '../../../components/percentInput/PercentInput';

const AccountLoanSection = (props) => {
  const { form } = props;

  const { t } = useTranslation();

  const loanAmount = Form.useWatch('loanAmount', form);
  const loanPercentage = Form.useWatch('loanPercentage', form);
  const loanMonthsCount = Form.useWatch('loanMonthsCount', form);

  useEffect(() => {
    let values = UtilNumber.calcLoan(
      loanAmount,
      loanPercentage,
      loanMonthsCount
    );

    form.setFieldsValue({
      loanMonthlyPayment: values.payment,
      loanTotalAmount: values.total,
      loanTotalInterestAmount: values.totalInterest,
    });
  }, [loanAmount, loanPercentage, loanMonthsCount, form]);

  return (
    <>
      <MySpace className="loan-vertical-gap" direction="vertical" fullWidth>
        <MyText strong>{t('WORD_LOAN_DETAILS')}</MyText>
        <InputNumberFormItem
          name="loanAmount"
          label={t('WORD_LOAN_AMOUNT')}
          required
        />
        <PercentInput
          name="loanPercentage"
          label={t('WORD_ANNUAL_INTEREST_RATE')}
          required
        />
        <InputNumberFormItem
          name="loanMonthsCount"
          label={t('WORD_LOAN_TERM_IN_MONTHS')}
          required
        />
        <InputFormDatePicker
          name="loanStartDate"
          label={t('WORD_PAYMENT_DATE')}
          required
        />
        <MySwitchFormItem
          className="switch-row"
          name="loanShowScheduledTransactions"
          label={t('WORD_SCHEDULE_MONTHLY_PAYMENTS')}
        />
        <MyText strong>{t('WORD_PAYMENT_INFORMATION')}</MyText>
        <InputNumberFormItem
          name="loanMonthlyPayment"
          label={t('WORD_MONTH_PAYMENT')}
          // required
          readOnly
        />
        <InputNumberFormItem
          name="loanTotalAmount"
          label={t('WORD_TOTAL_PAYMENT')}
          // required
          readOnly
        />
        <InputNumberFormItem
          name="loanTotalInterestAmount"
          label={t('WORD_TOTAL_INTERESTS_PAYMENT')}
          // required
          readOnly
        />
      </MySpace>
      <MyTextSecondary>
        <MySpace>
          <InfoCircleOutlined />
          {t('WORD_LOAN_ESTIMATE_DIFFERENCE')}
        </MySpace>
      </MyTextSecondary>
    </>
  );
};

export default AccountLoanSection;
