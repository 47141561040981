import React from 'react';
import { Select } from 'antd';
import './MySelect.scss';

const MySelect = (props) => {
  const {
    options,
    onChange,
    className,
    value,
    allowClear,
    style,
    fullWidth,
    width,
  } = props;

  return (
    <Select
      onChange={onChange}
      allowClear={allowClear}
      className={`app-select ${className}`}
      options={options}
      value={value}
      style={{ ...style, width: fullWidth ? '100%' : width }}
    ></Select>
  );
};

export default MySelect;
