import { Popover } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CategoryCreateNewContent from './CategoryCreateNewContent';
import PlusBtnSVG from '../../icons/PlusBtnSVG';
import EditSVG from '../../icons/EditSVG';

const CategoryCreateNewButton = (props) => {
  const { item, isNew, onChange, onDelete, isGroup, trigger } = props;

  const { t } = useTranslation();

  const [visible, setvisible] = useState(false);

  const handleOpenChange = (newOpen) => {
    setvisible(newOpen);
  };
  return (
    <Popover
      placement="bottomLeft"
      className="edit-category-container"
      content={
        <CategoryCreateNewContent
          isNew={isNew}
          item={item}
          onChange={onChange}
          onDelete={onDelete}
          isGroup={isGroup}
          visible={visible}
          setvisible={setvisible}
        />
      }
      title={
        isNew
          ? isGroup
            ? t('WORD_NEW_CATEGORY_GROUP')
            : t('WORD_CREATE_CATEGORY')
          : t('WORD_EDIT_CATEGORY')
      }
      trigger="click"
      destroyTooltipOnHide
      open={visible}
      onOpenChange={handleOpenChange}
    >
      {
        <div style={{ cursor: 'pointer' }}>
          {trigger ? (
            trigger
          ) : isNew ? (
            <PlusBtnSVG className="create-cayegory" />
          ) : (
            <EditSVG className="category-edit" />
          )}
        </div>
      }
    </Popover>
  );
};

export default CategoryCreateNewButton;
