import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useIsMounted from '@utilityjs/use-is-mounted';
import { Select, Form, Button } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import UtilNotify from '../../utils/UtilNotify';
import PayeeActions from '../../actions/PayeeActions';

const { Option, OptGroup } = Select;

const SelectPayee = (props) => {
  const {
    onChange,
    label,
    name,
    required,
    hidden,
    placeholder,
    className,
    onChangeItem,
    disabled,
    onlySelect,
    allowClear,
  } = props;

  const isMounted = useIsMounted();
  const { t } = useTranslation();

  const [list, setlist] = useState([]);
  const [loading, setloading] = useState(false);
  const [search, setSearch] = useState('');
  const [open, setopen] = useState(false);

  const errorMessage = props.errorMessage
    ? props.errorMessage
    : `${t('WORD_ERROR_PLEASE_SELECT')} ${placeholder ? placeholder : label}!`;

  useEffect(() => {
    const fillPayee = async () => {
      let data = [];
      let resultPayee = await PayeeActions.fillPaginationList({
        search: search,
        pagination: {
          page: 0,
          perPage: 500,
        },
        filter: {},
      });
      if (resultPayee.success) {
        if (resultPayee.totalRows) {
          let listAccountPayees = resultPayee.data.filter(
            (py) => py.type === PayeeActions.TYPE.ACCOUNT
          );
          let listPayees = resultPayee.data.filter(
            (py) => py.type === PayeeActions.TYPE.DEFAULT
          );

          if (listAccountPayees.length) {
            data.push(
              <OptGroup key={'accounts'} label={t('WORD_TRANSAFER')}>
                {listAccountPayees.map((catc) => {
                  return (
                    <Option
                      {...catc}
                      key={`Payee_${catc.idPayee}`}
                      value={catc.idPayee}
                      label={catc.payeeName}
                    >
                      {catc.payeeName}
                    </Option>
                  );
                })}
              </OptGroup>
            );
          }
          if (listPayees.length) {
            data.push(
              <OptGroup key={'Payees'} label={t('WORD_SAVED_PAYEES')}>
                {listPayees.map((catc) => {
                  return (
                    <Option
                      {...catc}
                      key={`Payee_${catc.idPayee}`}
                      value={catc.idPayee}
                      label={catc.payeeName}
                    >
                      {catc.payeeName}
                    </Option>
                  );
                })}
              </OptGroup>
            );
          }
        }
      }
      return data;
    };
    const fill = async () => {
      setloading(true);
      let listPayee = await fillPayee();
      if (isMounted()) {
        setlist(listPayee);
        setloading(false);
      }
    };
    fill();
  }, [isMounted, t, search]);

  const handleOnChange = (e, ee) => {
    setSearch('');
    onChange && onChange(e);
    onChangeItem && onChangeItem(ee);
  };

  const handleSearch = (e) => {
    setSearch(e);
  };

  const handleCreatePayee = async () => {
    setloading(true);
    let result = await PayeeActions.insert({ payeeName: search });
    if (isMounted()) {
      if (result.success) {
        setTimeout(() => {
          setopen(false);
          handleOnChange(result.data.idPayee, result.data);
          setloading(false);
        }, 1000);
      } else {
        setloading(false);
        UtilNotify.notifyErrorServer(result.errMsg);
      }
    }
  };

  return (
    <Form.Item
      name={name}
      label={label}
      hidden={hidden}
      className={`select-group-app select-payee ${className}`}
      rules={[
        { required: required && !hidden, message: errorMessage },
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value) {
              return Promise.resolve();
            }
            if (getFieldValue(name) !== '') {
              return Promise.resolve();
            }
            return Promise.reject(errorMessage);
          },
        }),
      ]}
    >
      <Select
        style={{ width: '100%' }}
        placeholder={placeholder || label || ''}
        onChange={handleOnChange}
        onSearch={handleSearch}
        showSearch
        allowClear={allowClear}
        loading={loading}
        optionFilterProp="label"
        open={open}
        disabled={disabled}
        onDropdownVisibleChange={setopen}
        dropdownRender={(menu) => {
          return (
            <>
              {list.length ? (
                menu
              ) : !onlySelect && search ? (
                <Button onClick={handleCreatePayee} style={{ padding: 5 }}>
                  <PlusCircleOutlined />
                  {`  Create "${search}" Payee`}
                </Button>
              ) : null}
            </>
          );
        }}
      >
        {list.map((oo) => {
          return oo;
        })}
      </Select>
    </Form.Item>
  );
};

export default SelectPayee;
