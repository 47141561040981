import React, { useState } from 'react';
import MyCard from '../../../components/myCard/MyCard';
import LogoSVG from '../../../icons/LogoSVG';
import FormApp from '../../../components/formApp/FormApp';
import InputPasswordFormItem from '../../../components/myInputForm/InputPasswordFormItem';
import { useTranslation } from 'react-i18next';
import MyTextTitle from '../../../components/myText/MyTextTitle';
import MyButtonMain from '../../../components/myButton/MyButtonMain';
import MyTextSecondary from '../../../components/myText/MyTextSecondary';
import InputPasswordConfirmFormItem from '../../../components/myInputForm/InputPasswordConfirmFormItem';
import ReturnToLogIn from '../../../components/returnToLogIn/ReturnToLogIn';
import { useNavigate } from 'react-router-dom';
import md5 from 'md5';
import UtilNotify from '../../../utils/UtilNotify';
import UsersActions from '../../../actions/UsersActions';
import MySpace from '../../../components/mySpace/MySpace';

const formName = 'forgot_password_form';

const ForgotPasswordChange = (props) => {
  const { data } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [saving, setSaving] = useState(false);

  const onFinish = async (values) => {
    setSaving(true);
    values.password = md5(values.password);
    let result = await UsersActions.forgotPasswordChange(values, data);
    if (result.success) {
      navigate('/login');
    } else {
      UtilNotify.notifyErrorServer(result.errMsg);
      setSaving(false);
    }
  };

  return (
    <MyCard className="login-card">
      <MySpace
        direction="vertical"
        size={'large'}
        style={{ marginTop: 10 }}
        fullWidth
      >
        <LogoSVG size={50} />
        <MySpace
          direction="vertical"
          size={'middle'}
          style={{ marginBottom: 20, marginTop: 20 }}
        >
          <MyTextTitle size={30} className="title">
            {t('WORD_NEW_PASSWORD')}
          </MyTextTitle>
          <MyTextSecondary className="description">
            {t('WORD_ENTER_YOUR_NEW_PASSWORD')}
          </MyTextSecondary>
        </MySpace>
        <FormApp name={formName} onFinish={onFinish}>
          <InputPasswordFormItem
            name="password"
            required
            placeholder={t('WORD_PASSWORD')}
          />
          <InputPasswordConfirmFormItem
            name="confirmPassword"
            required
            placeholder={t('WORD_CONFIRM_PASSWORD')}
          />
        </FormApp>
        <MyButtonMain
          form={formName}
          loading={saving}
          htmlType="submit"
          label={t('WORD_CONFIRM')}
          fullWidth
        />
        <ReturnToLogIn />
      </MySpace>
    </MyCard>
  );
};

export default ForgotPasswordChange;
