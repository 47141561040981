import io from 'socket.io-client';
import ApiStorage from '../redux/ApiStorage';
import UtilObject from '../utils/UtilObject';

// export let socket;

// let socketCallBack = null;
// let socketCallBackCategory = null;
let socketCallBack_Transactions = null;

const socket = io(process.env.REACT_APP_SOCKET_URL, {
  autoConnect: true,
  forceNew: false,
  reconnection: true,
  //     // transports: ['polling']
  //     // transports: ["websocket", "polling"]
  // transports: ["transports"]
  // transports: ["websocket", "polling"]
  // transports: ["websocket"]
  transports: ['polling'],
});

export const socketAction = {
  openWebsocket: async () => {
    socket.on('connect', () => {
      console.log(`socket_____ Connection id is ${socket.id}`);
    });
    socket.on('reconnect', (attempt) => {
      console.log('socket_____ Reconnect', attempt);
    });
    socket.on('disconnect', () => {
      console.log('socket_____ disconnect ');
    });
    socket.on('connect_error', (err) => {
      console.log('err = ', err);
      // revert to classic upgrade
      // socket.io.opts.transports = ["polling", "websocket"];
    });

    let currentDb = ApiStorage.getCurrentDb();
    if (!UtilObject.isEmpty(currentDb) && currentDb.dbName) {
      console.log('Db = ', currentDb.dbName);
      // socket.on(`${currentDb.dbName}`, (data) => {
      //   console.log('Socket e = ', data);
      // });
      socket.on(`${currentDb.dbName}_web`, (res) => {
        console.log('Socket web e = ', res);
        console.log(
          'socketCallBack_Transactions = ',
          socketCallBack_Transactions
        );
        if (res) {
          switch (res._type) {
            case 'tbl_transactions':
              socketCallBack_Transactions && socketCallBack_Transactions(res);
              break;
            default:
              break;
          }
        }
      });
    }
  },

  refreshWebSocket: async () => {
    socketAction.closeWebSocket();
    socketAction.openWebsocket();
  },

  closeWebSocket: async () => {
    socket.removeAllListeners();
    // try {
    //     socket.close();
    // } catch (error) {

    // }
    // socket = null;
  },
  // setCallBack: (cb) => {
  //     socketCallBack = cb;
  // },
  // setCallBackCategory: (cb) => {
  //     socketCallBackCategory = cb;
  // },
  setSocketCallBack_Transactions: (cb) => {
    socketCallBack_Transactions = cb;
  },

  // sendTableChanges: async tablesMap => {
  //     return new Promise(async resolve => {
  //         if (socket) {
  //             // console.log('SEND SOCKET');
  //             let accessToken = ApiStorage.getAccessToken();
  //             let { dbName } = ApiStorage.getCurrentDb();

  //             socket.emit(
  //                 'dataChanges',
  //                 { data: tablesMap },
  //                 {
  //                     socketId: socket.id,
  //                     accessToken,
  //                     dbName: dbName,
  //                     offset: utilDate.getTimeZone(),
  //                 },
  //                 async result => {
  //                     console.log('result', result);
  //                     resolve(result);
  //                 },
  //             );
  //         }
  //     });
  // },
  // updateFromInbox: async e => {
  //     console.log('e :>> ', e);
  // },
};
