import React, { memo } from 'react';
import MyIcon from './MyIcon';

export const SVGIcon = (props) => {
  const { color, size } = props;
  return (
    <svg
      width={size || 16}
      height={size || 16}
      viewBox="0 0 16 15"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="2.5" cy="2.5" r="2.5" fill={color} />
      <circle cx="13.5" cy="2.5" r="2.5" fill={color} />
      <circle cx="2.5" cy="12.5" r="2.5" fill={color} />
      <circle cx="13.5" cy="12.5" r="2.5" fill={color} />
    </svg>
  );
};

const MenuSVG = (props) => {
  return <MyIcon icon={<SVGIcon />} {...props} />;
};

export default memo(MenuSVG);
