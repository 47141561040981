import { Modal } from 'antd';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import MyText from '../myText/MyText';
import MySpace from '../mySpace/MySpace';
import MyButtonSecondary from '../myButton/MyButtonSecondary';
import MyButtonMain from '../myButton/MyButtonMain';
import LoadingIconApp from '../loadingIconApp/LoadingIconApp';
import './MyModal.scss';

const MyModal = forwardRef(
  (
    {
      trigerText,
      children,
      width = 450,
      saving,
      onOk,
      destroyOnClose = true,
      title,
      onClose,
      forceRender = false,
      getContainer = false,
      disableOk,
      onVisibleChange,
      footerExtra,
      labelOk,
      loading,
    },
    ref
  ) => {
    const { t } = useTranslation();

    const [open, setOpen] = useState(false);

    const showModal = () => {
      setOpen(true);
      onVisibleChange && onVisibleChange(true);
    };

    const handleCancel = () => {
      setOpen(false);
      onVisibleChange && onVisibleChange(false);
    };

    useImperativeHandle(ref, () => ({
      open: () => {
        showModal();
      },
      close: () => {
        handleCancel();
      },
    }));

    useEffect(() => {
      if (!open) {
        onClose && onClose();
      }
    }, [open, onClose]);

    return (
      <>
        <div className="app-modal-trigger-button" onClick={showModal}>
          {trigerText}
        </div>
        <Modal
          className="my-modal"
          title={<MyText bold>{title}</MyText>}
          open={open}
          style={{
            padding: 0,
            body: { maxHeight: 600, overflowY: 'auto', padding: 10 },
          }}
          // style={{ backgroundColor: 'red' }}
          // onOk={onOk}
          // style={style}
          confirmLoading={saving}
          onCancel={handleCancel}
          width={width}
          destroyOnClose={destroyOnClose}
          centered
          getContainer={getContainer}
          forceRender={forceRender}
          footer={
            <MySpace fullWidth spaceBetween>
              <MySpace align="start">
                {footerExtra || (
                  <>
                    <div />
                    <div />
                  </>
                )}
              </MySpace>
              <MySpace align="end">
                <MyButtonSecondary
                  key="cancel"
                  onClick={handleCancel}
                  label={t('WORD_CANCEL')}
                />
                <MyButtonMain
                  key="ok "
                  onClick={onOk}
                  label={labelOk || t('WORD_OK')}
                  disabled={disableOk}
                />
              </MySpace>
            </MySpace>
          }
        >
          {loading ? <LoadingIconApp /> : children}
        </Modal>
      </>
    );
  }
);

export default MyModal;
