import React, { useCallback, useEffect, useState } from 'react';
import MyCard from '../../../components/myCard/MyCard';
import MyPageHeader from '../../../components/myPageHeader/MyPageHeader';
import FormApp from '../../../components/formApp/FormApp';
import { useForm } from 'antd/es/form/Form';
import InputEmailFormItem from '../../../components/myInputForm/InputEmailFormItem';
import MyButtonMain from '../../../components/myButton/MyButtonMain';
import MyList from '../../../components/myList/MyList';
import { useTranslation } from 'react-i18next';
import useIsMounted from '@utilityjs/use-is-mounted/useIsMounted';
import { useNavigate } from 'react-router-dom';
import BudgetShareActions from '../../../actions/BudgetShareActions';
import UtilNotify from '../../../utils/UtilNotify';
import SelectInvitePermission from '../../../components/selects/SelectInvitePermissionForm';
import { useSelector } from 'react-redux';
import MySpace from '../../../components/mySpace/MySpace';
import IconRound from '../../../components/iconRound/IconRound';
import MyText from '../../../components/myText/MyText';
import MySpaceColumn from '../../../components/mySpace/MySpaceColumn';
import HourglassSVG from '../../../icons/HourglassSVG';
import { LinkOutlined } from '@ant-design/icons';
import SelectInvitePermissionValue from '../../../components/selects/SelectInvitePermissionValue';
import MyButtonDelete from '../../../components/myButton/MyButtonDelete';
import './BudgetShare.scss';

const formName = 'share-budget-form';

const BudgetShare = () => {
  const currentUserEmail = useSelector((state) => {
    return state.currentUser?.email || '';
  });

  const currentDbidKey = useSelector((state) => {
    return state.currentDb?.idKey;
  });

  const [form] = useForm();
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const navigate = useNavigate();

  const [saving, setsaving] = useState(false);
  const [sharedList, setSharedList] = useState([]);

  const fill = useCallback(async () => {
    if (currentDbidKey) {
      let result = await BudgetShareActions.getAll(currentDbidKey);
      if (result.success) {
        setSharedList(result.data);
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
    }
  }, [currentDbidKey]);

  useEffect(() => {
    fill();
  }, [fill]);

  const onFinish = async (values) => {
    if (values.email.trim() === currentUserEmail.trim()) {
      UtilNotify.notifyError(t('ERROR_YOU_CANT_SHARE_TO_YOURSELF'));
      return;
    }
    setsaving(true);
    let result = await BudgetShareActions.share(currentDbidKey, values);
    if (isMounted()) {
      if (result.success) {
        form.resetFields();
        fill();
        UtilNotify.notifySuccess('WORD_SHARED_SUCCESSFULY');
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
      setsaving(false);
    }
  };

  const handlePermissionChange = async (idKey, permission) => {
    let result = await BudgetShareActions.changePermission(idKey, {
      permission,
    });
    if (isMounted()) {
      if (result.success) {
        fill();
        UtilNotify.notifySuccess('WORD_PERMISSION_CHANGED_SUCCESSFULY');
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
    }
  };

  const handleUnShare = async (idKey) => {
    let result = await BudgetShareActions.unShare(idKey);
    if (isMounted()) {
      if (result.success) {
        fill();
        UtilNotify.notifySuccess('WORD_UNSHARED_SUCCESSFULY');
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
    }
  };

  return (
    <>
      <MyCard fullHeight className="budget-share-card">
        <MyPageHeader
          title={t('WORD_BUDGET_SHARE')}
          onBack={() => {
            navigate(-1);
          }}
          style={{ marginBottom: 10 }}
        />
        <FormApp
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: 20,
            width: '100%',
          }}
          name={formName}
          form={form}
          initialValues={{ permission: BudgetShareActions.PERMISSION.READ }}
          onFinish={onFinish}
        >
          <InputEmailFormItem
            style={{ width: '50%' }}
            placeholder={t('WORD_EMAIL')}
            name="email"
            required
          />
          <SelectInvitePermission
            style={{ width: 130 }}
            name="permission"
            required
          />
          <MyButtonMain
            loading={saving}
            style={{ width: 130 }}
            form={formName}
            htmlType="submit"
            label={t('WORD_SHARE')}
          />
        </FormApp>
        <MyList
          data={sharedList}
          renderItem={(item) => {
            return (
              <MySpace fullWidth spaceBetween>
                <MySpace size="middle">
                  <IconRound
                    name={item.displayName}
                    src={item.icon}
                    width={30}
                  />
                  <MySpace size="large">
                    <MySpaceColumn style={{ width: '100%' }}>
                      <MyText bold>{item.displayName}</MyText>
                      <MyText>{item.email}</MyText>
                    </MySpaceColumn>
                    {item.status === BudgetShareActions.STATUS.PENDING ? (
                      <HourglassSVG />
                    ) : (
                      <LinkOutlined />
                    )}
                  </MySpace>
                </MySpace>
                <MySpace size="middle">
                  <SelectInvitePermissionValue
                    value={item.permission}
                    // style={{ width: 100 }}
                    // fullWidth
                    onChange={(value) =>
                      handlePermissionChange(item.idKey, value)
                    }
                  />
                  <MyButtonDelete
                    icon={null}
                    label="Delete"
                    onClick={() => handleUnShare(item.idKey)}
                  />
                </MySpace>
              </MySpace>
            );
          }}
        />
      </MyCard>
    </>
  );
};

export default BudgetShare;
