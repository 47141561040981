import React from 'react';
import { Form, Switch } from 'antd';

const MySwitchFormItem = (props) => {
  const { label, name, onChange, className, disabled } = props;

  return (
    <Form.Item
      label={label}
      name={name}
      valuePropName="checked"
      className={`"switch-row" ${className || ''}`}
    >
      <Switch onChange={onChange} className="form-switch" disabled={disabled} />
    </Form.Item>
  );
};

export default MySwitchFormItem;
