import React from 'react';
import {
  PoweroffOutlined,
  UserOutlined,
  DatabaseOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import ApiStorage from '../../redux/ApiStorage';
import SettingSVG from '../../icons/SettingSVG';
import MyLink from '../../components/myLink/MyLink';
import LogoSVG from '../../icons/LogoSVG';
import MyDropDown from '../../components/myDropDown/MyDropDown';
import { useSelector } from 'react-redux';
import MyText from '../../components/myText/MyText';
import { useNavigate } from 'react-router-dom';
import MySpace from '../../components/mySpace/MySpace';
import UsersActions from '../../actions/UsersActions';

const HeaderMenuLeft = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const sideMenuColapsed = useSelector((state) => {
    return state.sideMenuColapsed;
  });

  const currentDbSettingsdisplayName = useSelector((state) => {
    return state.currentDbSettings?.displayName || '';
  });

  const currentUserName = useSelector((state) => {
    return state.currentUser?.displayName || '';
  });

  const items = [
    {
      icon: <PlusCircleOutlined />,
      label: <MyLink path="/dbbudget/create">{t('WORD_NEW_BUDGET')}</MyLink>,
      key: 'NewBudget',
    },
    {
      icon: <DatabaseOutlined />,
      label: <MyLink path="/dbbudget/list">{t('WORD_SWITCH_BUDGET')}</MyLink>,
      key: 'budgetList',
    },
    {
      type: 'divider',
    },
    {
      key: 'CurrentBudget',
      type: 'group',
      label: t('WORD_CURRENT_BUDGET'),
      children: [
        {
          icon: <SettingSVG />,
          label: (
            <MyLink path="/dbbudget/settings" style={{ marginLeft: 4 }}>
              {t('WORD_BUDGET_SETTINGS')}
            </MyLink>
          ),
          key: 'budgetSettings',
        },
      ],
    },
    {
      type: 'divider',
    },
    {
      key: 'Account',
      type: 'group',
      label: t('WORD_ACCOUNT'),
      children: [
        {
          icon: <UserOutlined />,
          label: (
            <MyLink path="/users/account_settings">{t('WORD-PROFILE')}</MyLink>
          ),
          key: 'AccountSettings',
        },
        {
          icon: <PoweroffOutlined />,
          label: t('WORD_LOG_OUT'),
          key: `logOut`,
          onClick: async () => {
            await UsersActions.logOut();
            ApiStorage.clearUser();
            navigate(`/login`);
          },
        },
      ],
    },
  ];

  return (
    <MyDropDown items={items} arrow={!sideMenuColapsed}>
      {sideMenuColapsed ? (
        <LogoSVG />
      ) : (
        <MySpace>
          {/* style={{ backgroundColor: 'red', width: 100, flexGrow: 1 }} */}
          <LogoSVG />
          <MySpace
            direction="vertical"
            // align="center"
            // size={10}
            size={0}
            style={{
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
            }}
          >
            <MyText size={16} bold>
              {currentDbSettingsdisplayName}
            </MyText>
            <MyText size={12}>{currentUserName}</MyText>
          </MySpace>
        </MySpace>
      )}
    </MyDropDown>
  );
};

export default HeaderMenuLeft;
