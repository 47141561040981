import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useIsMounted from '@utilityjs/use-is-mounted';
import MySelectFormItem from '../mySelectFormItem/MySelectFormItem';

const SelectTransactionComplete = (props) => {
  const { withAll, onChange, onChangeItem } = props;

  const isMounted = useIsMounted();
  const { t } = useTranslation();

  const [list, setlist] = useState([]);

  useEffect(() => {
    if (isMounted()) {
      let data = [
        { label: t('WORD_YES'), value: 'true' },
        { label: t('WORD_NO'), value: 'false' },
      ];
      // if (withAll) data.unshift({ label: t("WORD_ALL"), value: '' });
      setlist(data);
    }
  }, [isMounted, t, withAll]);

  const handleOnChange = (e, ee) => {
    onChange && onChange(e);
    onChangeItem && onChangeItem(ee);
  };

  return (
    <MySelectFormItem
      {...props}
      showSearch={false}
      options={list}
      onChange={handleOnChange}
    />
  );
};

export default SelectTransactionComplete;
