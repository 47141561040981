import React from 'react';
import { Avatar, Image } from 'antd';
import { getBaseURLShowImage } from '../../utils/UrlHelper';
import { FileImageOutlined } from '@ant-design/icons';
import UtilString from '../../utils/UtilString';
import './IconRound.scss';

const IconRound = ({ src, width, name, onClick, className }) => {
  const iconSrc = getBaseURLShowImage(src);

  const isText = Boolean(!iconSrc) && name;

  const size = width || 32;

  return (
    <div className="icon-round">
      <Avatar
        size={size}
        onClick={onClick}
        style={{
          backgroundColor: isText ? UtilString.getStringToColor(name) : '',
        }}
        src={
          !isText && iconSrc ? (
            <Image preview={false} src={iconSrc} style={{ width: size }} />
          ) : null
        }
        icon={!isText && !iconSrc ? <FileImageOutlined /> : null}
        className={className}
      >
        {isText ? UtilString.getAvatarName(name) : null}
      </Avatar>
    </div>
  );
};

export default IconRound;
