import React, { useEffect, useState } from 'react';
import MyCard from '../../../components/myCard/MyCard';
import MyPageHeader from '../../../components/myPageHeader/MyPageHeader';
import MySpace from '../../../components/mySpace/MySpace';
import MyText from '../../../components/myText/MyText';
import { useTranslation } from 'react-i18next';
import { Divider } from 'antd';
import { primaryColor } from '../../../theme/themeLightConfig';
import FormApp from '../../../components/formApp/FormApp';
import InputTextFormItem from '../../../components/myInputForm/InputTextFormItem';
import MyButtonMain from '../../../components/myButton/MyButtonMain';
import QRCode from 'qrcode.react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import UsersActions from '../../../actions/UsersActions';
import UtilNotify from '../../../utils/UtilNotify';
import ApiStorage from '../../../redux/ApiStorage';
import LoadingIconApp from '../../../components/loadingIconApp/LoadingIconApp';
import MySpaceColumn from '../../../components/mySpace/MySpaceColumn';
import ButtonCopy from '../../../components/buttonCopy/ButtonCopy';
import './SetUpNewTwoStepVerification.scss';

const formName = `set-up-2fa`;

const SetUpNewTwoStepVerification = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const is2FAenabled = useSelector((state) => {
    return state.currentUser?.is2FAenabled;
  });

  const [data, setdata] = useState({});
  // const [step, setstep] = useState(1);
  const [saving, setsaving] = useState(false);
  const [loading, setloading] = useState(true);

  useEffect(() => {
    if (is2FAenabled) {
      navigate('/users/account_settings');
    }
  }, [navigate, is2FAenabled]);

  useEffect(() => {
    const get = async () => {
      setloading(true);
      let result = await UsersActions.signUp2Fa();
      if (result.success) {
        setdata(result.data);
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
        navigate('/users/account_settings');
      }
      setTimeout(() => {
        setloading(false);
      }, 500);
    };
    get();
  }, [navigate]);

  const onFinish = async (values) => {
    setsaving(true);
    values.secret2Fa = data.secret2Fa;
    let result = await UsersActions.saveUp2Fa(values);
    if (result.success) {
      // setstep(2);
      // let currentUser = ApiStorage.getCurrentUser();
      // ApiStorage.setCurrentUser(Object.assign(currentUser, { is2FAenabled: true }));

      result.data.verify2fasuccess = true;
      ApiStorage.setCurrentUser(result.data);
      navigate('/users/account_settings');
    } else {
      UtilNotify.notifyErrorServer(result.errMsg);
    }
    setsaving(false);
  };
  return (
    <MyCard>
      <MyPageHeader
        title="Setup two-step verification"
        onBack={() => {
          navigate(-1);
        }}
      />
      {loading ? (
        <LoadingIconApp />
      ) : (
        <MySpace
          fullWidth
          style={{ marginTop: 40 }}
          size={'middle'}
          direction="vertical"
        >
          <MyText>
            {t('WORD_TWO_STEP_VERIFICATION_SUBTITLE')}
            <a
              style={{ margin: 4 }}
              target="_blanck"
              href="https://moneyscope.ai/"
            >
              {t('WORD_SET_IT_UP_TOGETHER')}
            </a>
            . {t('WORD_GRAB_YOUT_MOBILE_PHONE_AND_LETS_BEGIN')}
          </MyText>
          <Divider />
          <MySpace direction="vertical">
            <MySpace>
              <MyText className="number-bg" style={{ color: primaryColor }}>
                1
              </MyText>
              <MyText style={{ color: primaryColor }} bold>
                {t('WORD_INSTALL_A_VERIFICATION_APP')}
              </MyText>
            </MySpace>
            <MySpace>
              <MyText>
                {t('WORD_FA_STEP_SUBTITLE_1')}
                <a
                  style={{ margin: 4 }}
                  href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en"
                >
                  {t('WORD_GOOGLE_AUTHENTICATOR')}
                </a>
                , {t('WORD_FA_STEP_SUBTITLE_2')}
              </MyText>
            </MySpace>
          </MySpace>
          <Divider />
          <MySpace direction="vertical">
            <MySpace>
              <MyText className="number-bg" style={{ color: primaryColor }}>
                2
              </MyText>
              <MyText style={{ color: primaryColor }} bold>
                {t('WORD_SCAN_THE_MONEYSCOPE_QR_CODE')}
              </MyText>
            </MySpace>
            <MySpace>
              <MyText>{t('WORD_FA_STEP_SUBTITLE_3')}</MyText>
            </MySpace>
            <MySpaceColumn fullWidth align="center">
              <MyText>{t('WORD_SCAN_WITH_YOUR_VERIFICATION_APP')}</MyText>
              <QRCode value={data?.aut} renderAs="canvas" />
              <MyText>{t('WORD_CANT_SCAN_IT?_ENTER_THE_KEY_MANUALLY')}</MyText>
              <MySpace className="copy-row-wrapper">
                <MyText style={{ color: primaryColor }} strong>
                  {data?.secret2Fa}
                </MyText>
                <ButtonCopy className="btn-copy" value={data?.secret2Fa} />
              </MySpace>
            </MySpaceColumn>
          </MySpace>
          <Divider />
          <MySpace direction="vertical" fullWidth>
            <MySpace fullWidth>
              <MyText className="number-bg" style={{ color: primaryColor }}>
                3
              </MyText>
              <MyText style={{ color: primaryColor }} bold>
                {t('WORD_ENTER_THE_VERIFICATION_CODE')}
              </MyText>
            </MySpace>
            <MySpace>
              <MyText>{t('WORD_FA_STEP_SUBTITLE_4')}</MyText>
            </MySpace>
            <FormApp onFinish={onFinish} name={formName}>
              <MySpace direction="vertical" fullWidth>
                <InputTextFormItem
                  name="digitCode"
                  label={t('WORD_DIGIT_CODE')}
                  placeholder=" "
                  style={{ width: '100%' }}
                  required
                />
                <Divider />
                <MyButtonMain
                  form={formName}
                  label={t('WORD_SAVE')}
                  fullWidth
                  loading={saving}
                />
              </MySpace>
            </FormApp>
          </MySpace>
        </MySpace>
      )}
    </MyCard>
  );
};

export default SetUpNewTwoStepVerification;
