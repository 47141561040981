import React, { useEffect, useState } from 'react';
import { Popover, Space, Tabs } from 'antd';
import DATA from './categoriesData';
import { useCallback } from 'react';

const CategoryIconSelector = (props) => {
  const { children, onChange } = props;
  const [tabs, settabs] = useState([]);
  const [visible, setvisible] = useState(false);

  const handleSetIcon = useCallback(
    (label) => {
      setvisible(false);
      onChange && onChange(label);
    },
    [onChange]
  );

  useEffect(() => {
    const getList = (title) => {
      let list = [];
      for (const it of Object.entries(DATA)) {
        if (it[1].parent.includes(title)) {
          list.push(it[1]);
        }
      }
      return list;
    };

    const RenderIcon = ({ iconKey }) => {
      return (
        <Space wrap>
          {getList(iconKey).map((ic, index) => {
            return (
              <div key={index} onClick={() => handleSetIcon(ic.title)}>
                {ic.icon}
              </div>
            );
          })}
        </Space>
      );
    };

    const tabsTitle = [
      {
        label: 'Housing',
        key: '1',
        children: <RenderIcon iconKey="Housing" />,
      },
      {
        label: 'Transportation',
        key: '2',
        children: <RenderIcon iconKey="Transportation" />,
      },
      {
        label: 'Food',
        key: '3',
        children: <RenderIcon iconKey="Food" />,
      },
      {
        label: 'Utilities',
        key: '4',
        children: <RenderIcon iconKey="Utilities" />,
      },
      {
        label: 'Medical/Healthcare',
        key: '5',
        children: <RenderIcon iconKey="Medical/Healthcare" />,
      },
      {
        label: 'Personal',
        key: '6',
        children: <RenderIcon iconKey="Personal" />,
      },
      {
        label: 'Clothing',
        key: '7',
        children: <RenderIcon iconKey="Clothing" />,
      },
      {
        label: 'Insurance',
        key: '8',
        children: <RenderIcon iconKey="Insurance" />,
      },
      {
        label: 'Debt',
        key: '9',
        children: <RenderIcon iconKey="Debt" />,
      },
      {
        label: 'Retirement',
        key: '10',
        children: <RenderIcon iconKey="Retirement" />,
      },
    ];
    settabs(tabsTitle);
  }, [handleSetIcon]);

  const content = (
    <div>
      <Tabs
        style={{ width: 400, fontSize: 5 }}
        items={tabs}
        size="small"
        tabBarGutter={10}
      />
    </div>
  );

  return (
    <Popover
      content={content}
      title="Title"
      trigger="click"
      placement="topLeft"
      style={{ width: 500, height: 200 }}
      onOpenChange={setvisible}
      open={visible}
    >
      <div>{children}</div>
    </Popover>
  );
};

export default CategoryIconSelector;
