import React, { memo } from 'react';
import MaterialCustomSvg from './MaterialCustomSvg';

const MSvgIcon = () => {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="2.5" cy="2.5" r="2.5" />
      <circle cx="13.5" cy="2.5" r="2.5" />
      <circle cx="2.5" cy="12.5" r="2.5" />
      <circle cx="13.5" cy="12.5" r="2.5" />
    </svg>
  );
};

const MMenuSvg = () => {
  return <MaterialCustomSvg children={<MSvgIcon />} />;
};

export default memo(MMenuSvg);
