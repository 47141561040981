import React from 'react';

const HourglassSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12Z"
        fill="#FF9900"
      />
      <path
        d="M4.34043 9H7.73034C7.91835 9 8.07076 8.8465 8.07076 8.65714V7.74125C8.07076 7.66806 8.04751 7.59679 8.0044 7.53786L7.02269 6.19603C6.93681 6.07864 6.93404 5.91938 7.0158 5.79904L7.92619 4.45897C7.96493 4.40194 7.98566 4.33446 7.98566 4.26536V3.34286C7.98566 3.1535 7.83324 3 7.64523 3H4.34043C4.15241 3 4 3.1535 4 3.34286V4.25875C4 4.33195 4.02326 4.40321 4.06636 4.46214L5.04269 5.79662C5.13118 5.91756 5.13118 6.08244 5.04269 6.20338L4.06636 7.53786C4.02326 7.59679 4 7.66806 4 7.74125V8.65714C4 8.8465 4.15241 9 4.34043 9Z"
        stroke="white"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.5 7.5H6.5"
        stroke="white"
        strokeWidth="0.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default HourglassSVG;
