import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useIsMounted from '@utilityjs/use-is-mounted';
import TransactionActions from '../../actions/TransactionActions';
import MySelectFormItem from '../mySelectFormItem/MySelectFormItem';

const SelectTransactionType = (props) => {
  const { withAll, onChange, onChangeItem } = props;

  const isMounted = useIsMounted();
  const { t } = useTranslation();

  const [list, setlist] = useState([]);

  useEffect(() => {
    if (isMounted()) {
      let data = [
        { label: t('WORD_OUTFLOW'), value: TransactionActions.TYPE.EXPENSE },
        { label: t('WORD_INFLOW'), value: TransactionActions.TYPE.INCOME },
        { label: t('WORD_TRANSFER'), value: TransactionActions.TYPE.TRANSFER },
      ];
      // if (withAll) data.unshift({ label: t("WORD_ALL"), value: '' });
      setlist(data);
    }
  }, [isMounted, t, withAll]);

  const handleOnChange = (e, ee) => {
    onChange && onChange(e);
    onChangeItem && onChangeItem(ee);
  };

  return (
    <MySelectFormItem
      {...props}
      showSearch={false}
      options={list}
      onChange={handleOnChange}
    />
  );
};

export default SelectTransactionType;
