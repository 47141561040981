import { fetchFromUrlPOSTAsync } from '../utils/UrlHelper';

const URL_GET_SCREEN_DASHBOARD_GETTOTAL =
  '/cdb/statistics/get_screen_dashboard_getTotal';
const URL_GET_SCREEN_BUDGET_GETTOTAL =
  '/cdb/statistics/get_screen_budget_getTotal';
const URLGET_SCREEN_DASHBOARD_TOTAL_PARENT_CATEGORIES =
  '/cdb/statistics/get_screen_dashboard_total_parent_categories';
const URLGET_SCREEN_DASHBOARD_TOTAL_PARENT_NATURE =
  '/cdb/statistics/get_screen_dashboard_total_parent_nature';
const URLGET_SCREEN_DASHBOARD_SEVEN_DAYS_CHART_DATA =
  '/cdb/statistics/get_screen_dashboard_seven_days_chart_data';

const fs = {
  getScreenDashboardGetTotal: async (params) => {
    let result = await fetchFromUrlPOSTAsync(
      URL_GET_SCREEN_DASHBOARD_GETTOTAL,
      params
    );
    return result;
  },
  getScreenBudgetGetTotal: async (params) => {
    let result = await fetchFromUrlPOSTAsync(
      URL_GET_SCREEN_BUDGET_GETTOTAL,
      params
    );
    return result;
  },
  getScreenDashboardTotalParentCategories: async (params) => {
    let result = await fetchFromUrlPOSTAsync(
      URLGET_SCREEN_DASHBOARD_TOTAL_PARENT_CATEGORIES,
      params
    );
    return result;
  },
  getScreenDashboardTotalParentNature: async (params) => {
    let result = await fetchFromUrlPOSTAsync(
      URLGET_SCREEN_DASHBOARD_TOTAL_PARENT_NATURE,
      params
    );
    return result;
  },
  getScreenDashboardSevenDaysChartData: async (params) => {
    let result = await fetchFromUrlPOSTAsync(
      URLGET_SCREEN_DASHBOARD_SEVEN_DAYS_CHART_DATA,
      params
    );
    return result;
  },
};

const loc = {};

const StatisticsActions = Object.assign(fs, loc);

export default StatisticsActions;
