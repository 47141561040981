import React from 'react';

const GoogleSVG = (props) => {
  const { size } = props;
  return (
    <svg
      width={size || 18}
      height={size || 18}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.64 9.20468C17.64 8.56649 17.5827 7.95286 17.4764 7.36377H9V10.8451H13.8436C13.635 11.9701 13.0009 12.9233 12.0477 13.5615V15.8197H14.9564C16.6582 14.2529 17.64 11.9456 17.64 9.20468Z"
        fill="#4285F4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.99975 18.0002C11.4297 18.0002 13.467 17.1943 14.9561 15.8197L12.0475 13.5616C11.2416 14.1016 10.2107 14.4207 8.99975 14.4207C6.65566 14.4207 4.67157 12.8375 3.96385 10.7102H0.957031V13.042C2.43794 15.9834 5.48157 18.0002 8.99975 18.0002Z"
        fill="#34A853"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.96409 10.7103C3.78409 10.1703 3.68181 9.59349 3.68181 9.00031C3.68181 8.40713 3.78409 7.83031 3.96409 7.29031V4.9585H0.957272C0.347727 6.17349 0 7.54804 0 9.00031C0 10.4526 0.347727 11.8271 0.957272 13.0421L3.96409 10.7103Z"
        fill="#FBBC05"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.99975 3.57954C10.3211 3.57954 11.5075 4.03363 12.4402 4.92545L15.0216 2.34409C13.4629 0.891817 11.4257 0 8.99975 0C5.48157 0 2.43794 2.01682 0.957031 4.95818L3.96385 7.28999C4.67157 5.16272 6.65566 3.57954 8.99975 3.57954Z"
        fill="#EA4335"
      />
    </svg>
  );
};

export default GoogleSVG;
