import { memo } from 'react';
import DATA from './categoriesData';

const GetCategoryIcons = ({ icon }) => {
  if (icon) {
    if (DATA[icon]) {
      return DATA[icon].icon;
    } else {
      return DATA.Default.icon;
    }
  } else {
    return DATA.Default.icon;
  }
};

export default memo(GetCategoryIcons);
