import { fetchFromUrlPOSTAsync } from '../utils/UrlHelper';
import UtilDate from '../utils/UtilDate';

const URL_SAVE_UPDATE = '/cdb/transactions/update';
const URL_SAVE_CREATE = '/cdb/transactions/create';
const URL_GET_ALL = '/cdb/transactions/get_all';
const URL_GET_ONE = '/cdb/transactions/get_one';
const URL_GET_ONE_EDIT = '/cdb/transactions/get_one_edit';
const URL_DELETE = '/cdb/transactions/delete';

const fs = {
  insert: async (values) => {
    let result = await fetchFromUrlPOSTAsync(URL_SAVE_CREATE, {
      saveData: values,
    });
    return result;
  },
  update: async (values, idKey) => {
    let result = await fetchFromUrlPOSTAsync(URL_SAVE_UPDATE + '/' + idKey, {
      saveData: values,
    });
    return result;
  },
  delete: async (idKey) => {
    let result = await fetchFromUrlPOSTAsync(URL_DELETE + '/' + idKey, []);
    return result;
  },
  fillPaginationList: async (params) => {
    let result = await fetchFromUrlPOSTAsync(URL_GET_ALL, params);
    return result;
  },
  getOneByidKey: async (idKey) => {
    const urlParams = [];
    let result = await fetchFromUrlPOSTAsync(
      URL_GET_ONE + '/' + idKey,
      urlParams
    );
    return result;
  },
  getOneByidKeyForEdit: async (idKey) => {
    const urlParams = [];
    let result = await fetchFromUrlPOSTAsync(
      URL_GET_ONE_EDIT + '/' + idKey,
      urlParams
    );
    return result;
  },
};

const loc = {
  TYPE: { INCOME: 1, EXPENSE: 2, TRANSFER: 3 },
  STATUS: { ACTIVE: 1, HIDDEN: 2, DELETED: 3, SCHEDULED: 4 },
  saveTransactionForm: async (values, idTransaction) => {
    console.log('values = ', values);
    console.log('idTransaction = ', idTransaction);
    values.idCurrency = 2;

    if (values.type === loc.TYPE.INCOME) {
      values.valueExpense = 0;
      values.valueIncome = Math.abs(values.value);
    } else {
      values.valueExpense = Math.abs(values.value);
      values.valueIncome = 0;
    }

    if (values.type === loc.TYPE.TRANSFER) {
      values.idCategory = 0;
      values.idPayee = 0;
    } else {
      values.idAccountTo = 0;
    }

    if (
      UtilDate.getDate(values.transactionDate).isAfter(UtilDate.getDateToday())
    ) {
      values.status = loc.STATUS.SCHEDULED;
    } else {
      values.status = loc.STATUS.ACTIVE;
    }
    let result;
    if (idTransaction) {
      result = await fs.update(values, values.idKey);
    } else {
      values.idKey = '';
      result = await fs.insert(values);
    }
    return result;
  },
};

const TransactionActions = Object.assign(fs, loc);

export default TransactionActions;
