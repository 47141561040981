import React from 'react';
import { Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';
import MyButton from './MyButton';
// import MyButtonMain from './MyButtonMain';
// import MyButtonSecondary from './MyButtonSecondary';
const ButtonConfirm = ({
  onCancel,
  onConfirm,
  disabled,
  title,
  okText,
  cancelText,
  icon,
  label,
  className,
  loading,
  danger,
}) => {
  const { t } = useTranslation();

  const handleCancel = () => {
    onCancel && onCancel();
  };

  return (
    <Popconfirm
      title={title || t('WORD_ARE_YOU_SURE')}
      onConfirm={onConfirm}
      onCancel={handleCancel}
      okText={okText || t('WORD_YES')}
      cancelText={cancelText || t('WORD_NO')}
      disabled={disabled}
    >
      <>
        <MyButton
          className={className}
          loading={loading}
          label={label}
          icon={icon}
          danger={danger}
          style={{ paddingTop: 4, paddingLeft: 8 }}
        />
      </>
    </Popconfirm>
  );
};

export default ButtonConfirm;
