import { Button } from 'antd';
import React from 'react';

const MyButtonRound = (props) => {
  const { className, loading, onClick, style, icon, disabled } = props;

  return (
    <div>
      <Button
        className={`my-button-round ${className}`}
        loading={loading}
        onClick={onClick}
        style={style}
        disabled={disabled}
      >
        {Boolean(icon) && <div className="my-button-round-icon">{icon}</div>}
      </Button>
    </div>
  );
};

export default MyButtonRound;
