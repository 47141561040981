import { fetchFromUrlPOSTAsync } from '../utils/UrlHelper';

const URL_GET_ALL = '/currencies/get_all';

const fs = {
  fillPaginationList: async (params) => {
    let result = await fetchFromUrlPOSTAsync(URL_GET_ALL, params);
    return result;
  },
};

const loc = {};

const CurrenciesActionsMain = Object.assign(fs, loc);

export default CurrenciesActionsMain;
