import { useEffect, useState } from 'react';

export default function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
    isDesktop: window.innerWidth > 1000,
    isTablet: window.innerWidth > 500 && window.innerWidth <= 1000,
    isMobile: window.innerWidth <= 500,
    isTV: window.innerWidth >= 1000,
    isWatch: window.innerWidth <= 300,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        isDesktop: window.innerWidth > 1000,
        isTablet: window.innerWidth > 500 && window.innerWidth <= 1000,
        isMobile: window.innerWidth <= 500,
        isTV: window.innerWidth >= 1000,
        isWatch: window.innerWidth <= 300,
      });
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowSize;
}
