import React from 'react';
import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import './MyInputForm.scss';

const InputTextFormItem = ({
  classNameInput = '',
  className = '',
  label,
  name,
  placeholder,
  required = false,
  hidden = false,
  readOnly = false,
  allowClear = true,
  onPressEnter,
  hasFeedback = false,
  onChange,
  autoFocus = false,
  style,
}) => {
  const { t } = useTranslation();

  return (
    <Form.Item
      name={name}
      label={label}
      hidden={hidden}
      hasFeedback={hasFeedback}
      className={`app-input ${className}`}
      rules={[
        {
          required: required && !hidden,
          message: `${label || placeholder} ${t('WORD_IS_REQUAIRED')}`,
        },
      ]}
    >
      <Input
        className={`my-input ${classNameInput}`}
        style={style}
        readOnly={readOnly}
        placeholder={placeholder || label || ''}
        autoComplete={'nope'}
        autoFocus={autoFocus}
        allowClear={allowClear}
        onChange={(e) => {
          onChange && onChange(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            onPressEnter && onPressEnter(e.target.value);
          }
        }}
      />
    </Form.Item>
  );
};

export default InputTextFormItem;
