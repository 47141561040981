import React from 'react';
import MyCard from '../../../components/myCard/MyCard';
import BudgetsSVG from '../../../icons/BudgetsSVG';
import MyText from '../../../components/myText/MyText';
import MySpace from '../../../components/mySpace/MySpace';
import BudgetShareActions from '../../../actions/BudgetShareActions';
import ApiStorage from '../../../redux/ApiStorage';
import BudgetActions from '../../../actions/BudgetActions';
import { useNavigate } from 'react-router-dom';
import { primaryColor } from '../../../theme/themeLightConfig';
import { Avatar } from 'antd';
import { useSelector } from 'react-redux';
import IconRound from '../../../components/iconRound/IconRound';
import './BudgetList.scss';

const BudgetListItem = (props) => {
  const {
    item,
    // onChange
  } = props;

  const currentUserId = useSelector((state) => {
    return state.currentUser?.idUser;
  });

  const navigate = useNavigate();

  return (
    <MyCard
      className="budget-list-item-card"
      onClick={async () => {
        if (item.status === BudgetShareActions.STATUS.ACTIVE) {
          ApiStorage.setCurrentDb(item);
          let result = await BudgetActions.getSettings();
          if (result.success) {
            navigate('/home');
          }
        }
      }}
    >
      <MySpace direction="vertical" align="center">
        <div className="budget-icon-wrapper">
          <BudgetsSVG size={25} />
        </div>
        <MyText style={{ color: item.isSelected ? primaryColor : '' }} bold>
          {item.displayName}
        </MyText>
        <Avatar.Group>
          {item.accessUsers
            .filter((it) => currentUserId && it.idUser !== currentUserId)
            .map((us, index) => {
              return (
                // <Tooltip key={index} title={us.displayName} placement="top">
                <MyText>
                  <IconRound name={us.displayName} src={us.icon} width={25} />
                </MyText>
                // </Tooltip>
              );
            })}
        </Avatar.Group>
      </MySpace>
    </MyCard>
  );
};

export default BudgetListItem;
