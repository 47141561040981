import React, { useState } from 'react';
import MyCard from '../../../components/myCard/MyCard';
import { Space } from 'antd';
import LogoSVG from '../../../icons/LogoSVG';
import MyTextTitle from '../../../components/myText/MyTextTitle';
import MyTextSecondary from '../../../components/myText/MyTextSecondary';
import MyReactCodeInput from '../../../components/myReactCodeInput/MyReactCodeInput';
import { useTranslation } from 'react-i18next';
import md5 from 'md5';
import UsersActions from '../../../actions/UsersActions';
import UtilNotify from '../../../utils/UtilNotify';

const ForgotPasswordInput4Digit = (props) => {
  const { onComplete, data } = props;

  const { t } = useTranslation();

  const email = data.email;

  const [saving, setSaving] = useState(false);

  const handleOnComplete = async (value) => {
    setSaving(true);
    const code = md5(value);
    let result = await UsersActions.forgotPasswordCheck(code, email);
    if (result.success) {
      onComplete && onComplete(result.data);
    } else {
      // UtilNotify.notifyErrorServer(result.errMsg);
      UtilNotify.notifyError(t('WORD_INCORRECT_4_DIGIT_CODE'));
    }
    setSaving(false);
  };

  const handleResendCode = async () => {
    if (email) {
      let result = await UsersActions.forgotPasswordRequest({ email });
      if (result.success) {
        UtilNotify.notifySuccess('WORD_CODE_RESEND_SUCCESSFULY');
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
    }
  };

  return (
    <MyCard className="login-card">
      <Space direction="vertical" size={'large'} style={{ marginTop: 10 }}>
        <LogoSVG size={50} />
        <Space
          direction="vertical"
          size={'middle'}
          style={{ marginBottom: 20, marginTop: 20 }}
        >
          <MyTextTitle size={30}>
            {t('WORD_PLEASE_CHECK_YOUR_EMAIL')}
          </MyTextTitle>
          <Space.Compact direction="vertical">
            <MyTextSecondary>{t('WORD_CHECK_EMAIL')}</MyTextSecondary>
            <MyTextSecondary>{email}</MyTextSecondary>
          </Space.Compact>
        </Space>
        <MyReactCodeInput
          onComplete={handleOnComplete}
          loading={saving}
          onClick={handleResendCode}
        />
      </Space>
    </MyCard>
  );
};

export default ForgotPasswordInput4Digit;
