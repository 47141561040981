import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useIsMounted from '@utilityjs/use-is-mounted';
import CategoriesActions from '../../actions/CategoriesActions';
import MySelectFormItem from '../mySelectFormItem/MySelectFormItem';

const SelectCategoryNature = (props) => {
  const { name, label, withAll, required } = props;

  const isMounted = useIsMounted();
  const { t } = useTranslation();

  const [list, setlist] = useState([]);

  useEffect(() => {
    if (isMounted()) {
      let data = [
        { label: t('WORD_NONE'), value: CategoriesActions.NATURE.NONE },
        { label: t('WORD_MUST'), value: CategoriesActions.NATURE.MUST },
        { label: t('WORD_NEED'), value: CategoriesActions.NATURE.NEED },
        { label: t('WORD_WANT'), value: CategoriesActions.NATURE.WANT },
      ];
      if (withAll) data.unshift({ label: t('WORD_ALL'), value: '' });
      setlist(data);
    }
  }, [isMounted, t, withAll]);

  return (
    <MySelectFormItem
      name={name}
      label={label}
      options={list}
      required={required}
    />
  );
};

export default SelectCategoryNature;
