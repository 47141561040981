import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import MyModal from '../../components/myModal/MyModal';
import CategoriesActions from '../../actions/CategoriesActions';
import useIsMounted from '@utilityjs/use-is-mounted';
import { useTranslation } from 'react-i18next';
import MySpace from '../../components/mySpace/MySpace';
import GetCategoryIcons from '../../components/defaultBudgetCategories/GetCategoryIcons';
import MyText from '../../components/myText/MyText';
import UtilNotify from '../../utils/UtilNotify';
import MySpaceColumn from '../../components/mySpace/MySpaceColumn';
import LoadingIconApp from '../../components/loadingIconApp/LoadingIconApp';

const SetCategoryModal = forwardRef((props, ref) => {
  const { transactionType, onSelect } = props;

  const isMounted = useIsMounted();
  const { t } = useTranslation();

  const [list, setList] = useState([]);
  const [loading, setloading] = useState(true);

  const fillFS = useCallback(async () => {
    if (t) {
      setloading(true);
      let result = await CategoriesActions.fillPaginationList({
        search: '',
        filter: {
          type: CategoriesActions.TYPE.DEFAULT,
          typeIncomeExpense: transactionType,
        },
      });
      if (isMounted()) {
        if (result.success) {
          let listData = result.data.filter((cat) => {
            return (
              cat.typeMainChild === CategoriesActions.TYPEMAINCHILD.MAIN ||
              (cat.typeMainChild === CategoriesActions.TYPEMAINCHILD.CHILD &&
                !cat.idParent)
            );
          });
          for (const catm of listData) {
            catm.children = result.data.filter((cat) => {
              return cat.idParent === catm.idCategory;
            });
          }
          setList(listData);
        } else {
          UtilNotify.notifyErrorServer(result.errMsg);
        }
        setloading(false);
      }
    }
  }, [t, isMounted, transactionType]);

  useEffect(() => {
    fillFS();
  }, [fillFS]);

  return (
    <MyModal ref={ref}>
      {loading ? (
        <LoadingIconApp />
      ) : (
        <MySpaceColumn fullWidth size="large">
          {list.map((categ, index) => {
            return (
              <MySpaceColumn key={index} fullWidth>
                <MySpace>
                  <GetCategoryIcons icon={categ.icon} />
                  <MyText>{categ.categoryName}</MyText>
                </MySpace>
                {categ.children.map((catc, index) => {
                  return (
                    <MySpace
                      style={{
                        marginLeft: 20,
                        width: '95%',
                        cursor: 'pointer',
                      }}
                      spaceBetween
                      onClick={() => {
                        onSelect && onSelect(catc);
                      }}
                      key={index}
                    >
                      <MySpace>
                        <GetCategoryIcons icon={catc.icon} />
                        <MyText>{catc.categoryName}</MyText>
                      </MySpace>
                      <div className="option-item-value">
                        {catc.currentAvilableFinal}
                      </div>
                    </MySpace>
                  );
                })}
              </MySpaceColumn>
            );
          })}
        </MySpaceColumn>
      )}
    </MyModal>
  );
});

export default SetCategoryModal;
