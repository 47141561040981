import React from 'react';

const RedUpSquareArrowSVG = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
    >
      <rect opacity="0.15" width="34" height="34" rx="8" fill="#E2647B" />
      <mask
        id="mask0_3748_11580"
        maskUnits="userSpaceOnUse"
        x="7"
        y="8"
        width="19"
        height="19"
      >
        <rect
          x="7.52637"
          y="8.14551"
          width="18.3281"
          height="18.3281"
          fill="#2CB37A"
        />
      </mask>
      <g mask="url(#mask0_3748_11580)">
        <path
          d="M9.96192 24.6612L8.39061 23.754L13.1739 15.4043L17.6634 17.9962L21.0448 12.2042L18.7434 12.8209L18.269 11.0505L23.5801 9.62743L25.0032 14.9385L23.2328 15.4129L22.6161 13.1114L18.3394 20.519L13.8499 17.927L9.96192 24.6612Z"
          fill="#E2647B"
        />
      </g>
    </svg>
  );
};

export default RedUpSquareArrowSVG;
