import { fetchFromUrlPOSTAsync } from '../utils/UrlHelper';

const URL_SAVE_UPDATE = '/cdb/accounts/update';
const URL_SAVE_CREATE = '/cdb/accounts/create';
const URL_GET_ALL = '/cdb/accounts/get_all';
const URL_DELETE = '/cdb/accounts/delete';
const URL_GET_ONE = '/cdb/accounts/get_one';
const URL_HIDE = '/cdb/accounts/set_hidden';
const URL_SHOW = '/cdb/accounts/set_active';

const fs = {
  insert: async (values) => {
    let result = await fetchFromUrlPOSTAsync(URL_SAVE_CREATE, {
      saveData: values,
    });
    return result;
  },
  update: async (values, idKey) => {
    let result = await fetchFromUrlPOSTAsync(URL_SAVE_UPDATE + '/' + idKey, {
      saveData: values,
    });
    return result;
  },
  delete: async (idKey) => {
    let result = await fetchFromUrlPOSTAsync(URL_DELETE + '/' + idKey, []);
    return result;
  },
  fillPaginationList: async (params) => {
    let result = await fetchFromUrlPOSTAsync(URL_GET_ALL, params);
    return result;
  },
  getOneByidKey: async (idKey) => {
    const urlParams = [];
    let result = await fetchFromUrlPOSTAsync(
      URL_GET_ONE + '/' + idKey,
      urlParams
    );
    return result;
  },
  hide: async (idKey) => {
    let result = await fetchFromUrlPOSTAsync(URL_HIDE + '/' + idKey, []);
    return result;
  },
  show: async (idKey) => {
    let result = await fetchFromUrlPOSTAsync(URL_SHOW + '/' + idKey, []);
    return result;
  },
};

const loc = {
  STATUS: { ACTIVE: 1, CLOSED: 2, DELETED: 3 },
  GROUP: {
    GENERAL: 1,
    LOAN: 2,
    SAVING: 3,
    INVESTMENT: 4,
  },
};

loc.defaultAccountTypes = [
  {
    accTyName: 'General',
    idGroup: loc.GROUP.GENERAL,
    idType: 1,
    data: [
      {
        accTyName: 'General',
        idType: 101,
        idGroup: loc.GROUP.GENERAL,
      },
      {
        accTyName: 'Cash',
        idType: 103,
        idGroup: loc.GROUP.GENERAL,
      },
      {
        accTyName: 'Current account',
        idType: 105,
        idGroup: loc.GROUP.GENERAL,
      },
      {
        accTyName: 'Credit Card',
        idType: 104,
        idGroup: loc.GROUP.GENERAL,
      },
    ],
  },
  {
    accTyName: 'Loan',
    idGroup: loc.GROUP.LOAN,
    idType: 2,
    data: [
      {
        accTyName: 'Loan',
        idType: 201,
        idGroup: loc.GROUP.LOAN,
      },
      {
        accTyName: 'Mortgage',
        idType: 202,
        idGroup: loc.GROUP.LOAN,
      },
      {
        accTyName: 'Account with overdraft',
        idType: 203,
        idGroup: loc.GROUP.LOAN,
      },
    ],
  },
  {
    accTyName: 'Saving',
    idGroup: loc.GROUP.SAVING,
    idType: 3,
    data: [
      {
        accTyName: 'Saving account',
        idType: 301,
        idGroup: loc.GROUP.SAVING,
      },
      {
        accTyName: 'Debit',
        idType: 302,
        idGroup: loc.GROUP.SAVING,
      },
    ],
  },
  {
    accTyName: 'Investment',
    idGroup: loc.GROUP.INVESTMENT,
    idType: 4,
    data: [
      {
        accTyName: 'Investment',
        idType: 401,
        idGroup: loc.GROUP.INVESTMENT,
      },
    ],
  },
];

const AccountActions = Object.assign(fs, loc);

export default AccountActions;
