import React from 'react';
import MyCard from '../../../components/myCard/MyCard';
import BudgetsSVG from '../../../icons/BudgetsSVG';
import MyText from '../../../components/myText/MyText';
import MySpace from '../../../components/mySpace/MySpace';
import BudgetShareActions from '../../../actions/BudgetShareActions';
import ApiStorage from '../../../redux/ApiStorage';
import BudgetActions from '../../../actions/BudgetActions';
import { useNavigate } from 'react-router-dom';
import MyButtonDelete from '../../../components/myButton/MyButtonDelete';
import { useTranslation } from 'react-i18next';
import MyButtonMain from '../../../components/myButton/MyButtonMain';
import UtilNotify from '../../../utils/UtilNotify';
import { Avatar } from 'antd';
import { useSelector } from 'react-redux';
import IconRound from '../../../components/iconRound/IconRound';
import { primaryColor } from '../../../theme/themeLightConfig';
import './BudgetList.scss';

const BudgetListItemShare = (props) => {
  const { item, onChange } = props;

  const { t } = useTranslation();

  const navigate = useNavigate();

  const currentUserId = useSelector((state) => {
    return state.currentUser?.idUser;
  });

  const handleReject = async (msg) => {
    let result = await BudgetShareActions.unShare(item.idKey);
    if (result.success) {
      UtilNotify.notifySuccess(msg);
      onChange && onChange();
    } else {
      UtilNotify.notifyErrorServer(result.errMsg);
    }
  };

  const handleAccept = async () => {
    let result = await BudgetShareActions.accept(item.idKey);
    if (result.success) {
      UtilNotify.notifySuccess('Accept success');
      onChange && onChange();
    } else {
      UtilNotify.notifyErrorServer(result.errMsg);
    }
  };

  return (
    <MyCard
      className="budget-list-item-share-card"
      onClick={async () => {
        if (item.status === BudgetShareActions.STATUS.ACTIVE) {
          ApiStorage.setCurrentDb(item);
          let result = await BudgetActions.getSettings();
          if (result.success) {
            navigate('/home');
          }
        }
      }}
    >
      {!item.isOwner && (
        <>
          <MySpace direction="vertical" align="center">
            <div className="budget-icon-wrapper">
              <BudgetsSVG size={25} />
            </div>
            <MySpace direction="vertical" align="center">
              <MyText
                style={{ color: item.isSelected ? primaryColor : '' }}
                className="budget-name"
                bold
              >
                {item.displayName}
              </MyText>
              {!item.isOwner && (
                <MyText
                  className={
                    item.displayName ? 'owner-name' : 'owner-name-hover'
                  }
                >
                  {item.ownerName}
                </MyText>
              )}
            </MySpace>
            <Avatar.Group>
              {item.accessUsers
                .filter((it) => currentUserId && it.idUser !== currentUserId)
                .map((us, index) => {
                  return (
                    // <Tooltip key={index} title={us.displayName} placement="top">
                    <MyText key={index}>
                      <IconRound
                        name={us.displayName}
                        src={us.icon}
                        width={30}
                      />
                    </MyText>
                    // </Tooltip>
                  );
                })}
            </Avatar.Group>
            {item.status === BudgetShareActions.STATUS.PENDING ? (
              <MySpace style={{ marginTop: 10 }}>
                <MyButtonDelete
                  label={t('WORD_REJECT')}
                  icon={' '}
                  onClick={() => handleReject('Deny success')}
                />
                <MyButtonMain label={t('WORD_ACCEPT')} onClick={handleAccept} />
              </MySpace>
            ) : (
              <MySpace style={{ marginTop: 10 }}>
                <MyButtonDelete
                  icon={' '}
                  label={t('WORD_LEAVE')}
                  onClick={() => handleReject('Leave success')}
                />
              </MySpace>
            )}
          </MySpace>
        </>
      )}
    </MyCard>
  );
};

export default BudgetListItemShare;
