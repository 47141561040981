import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import MyText from '../../components/myText/MyText';
import CategoriesActions from '../../actions/CategoriesActions';
import CategoryCreateNewButton from './CategoryCreateNewButton';
import UtilNumber from '../../utils/UtilNumber';
import MyTextSecondary from '../../components/myText/MyTextSecondary';
import MySpace from '../../components/mySpace/MySpace';
import MySpaceColumn from '../../components/mySpace/MySpaceColumn';
import MyTextTitle from '../../components/myText/MyTextTitle';

const CategoryListItemMain = (props) => {
  const { item, canEdit, onDelete, onEdit, onCreate } = props;

  const { t } = useTranslation();

  const isHidden = item?.idCategory === CategoriesActions.OPERATION_IDS.Hidden;
  return (
    <div
      style={{
        // width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'start',
      }}
    >
      <MySpace fullWidth>
        <MyText bold>{item?.categoryName}</MyText>
        {!isHidden && canEdit ? (
          <>
            <CategoryCreateNewButton
              item={item}
              onChange={onEdit}
              onDelete={onDelete}
              isNew={false}
              isGroup={true}
            />
            <CategoryCreateNewButton
              item={item}
              isNew={true}
              onChange={onCreate}
              isGroup={false}
            />
          </>
        ) : null}
      </MySpace>
      <div
        style={{
          width: '60%',
          display: 'flex',
          justifyContent: 'space-between',
          textAlign: 'center',
        }}
      >
        <MySpaceColumn style={{ minWidth: '15%' }}>
          <MyTextSecondary>{t('WORD_BUDGETED')}</MyTextSecondary>
          <MyTextTitle size={14}>
            {UtilNumber.formatDouble(item?.budgetValue || 0)}
          </MyTextTitle>
        </MySpaceColumn>
        <MySpaceColumn style={{ minWidth: '15%' }}>
          <MyTextSecondary>{t('WORD_ACTIVITY')}</MyTextSecondary>
          <MyTextTitle size={14}>
            {UtilNumber.formatDouble(item?.currentBalance || 0)}
          </MyTextTitle>
        </MySpaceColumn>
        <MySpaceColumn style={{ minWidth: '15%' }}>
          <MyTextSecondary>{t('WORD_LEFT_CAP')}</MyTextSecondary>
          <MyTextTitle size={14}>
            {UtilNumber.formatDouble(item?.currentAvilableFinal || 0)}
          </MyTextTitle>
        </MySpaceColumn>
      </div>
    </div>
  );
};

export default memo(CategoryListItemMain);
