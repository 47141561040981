import React from 'react';

const GreenUpSquareArrowSVG = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
    >
      <rect opacity="0.15" width="34" height="34" rx="8" fill="#2CB37A" />
      <mask
        id="mask0_3748_11554"
        maskUnits="userSpaceOnUse"
        x="7"
        y="8"
        width="19"
        height="19"
      >
        <rect
          x="7.52612"
          y="8.146"
          width="18.3281"
          height="18.3281"
          fill="#2CB37A"
        />
      </mask>
      <g mask="url(#mask0_3748_11554)">
        <path
          d="M9.96167 24.6617L8.39036 23.7545L13.1737 15.4047L17.6631 17.9967L21.0446 12.2047L18.7431 12.8214L18.2687 11.051L23.5798 9.62791L25.0029 14.939L23.2326 15.4134L22.6159 13.1119L18.3391 20.5195L13.8497 17.9275L9.96167 24.6617Z"
          fill="#2CB37A"
        />
      </g>
    </svg>
  );
};

export default GreenUpSquareArrowSVG;
