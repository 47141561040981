import React, { useEffect, useState } from 'react';
import MyCard from '../../components/myCard/MyCard';
import MySpace from '../../components/mySpace/MySpace';
import MyTextTitle from '../../components/myText/MyTextTitle';
import MyTextSecondary from '../../components/myText/MyTextSecondary';
import MyText from '../../components/myText/MyText';
import {
  primaryColor,
  successColor,
  errorColor,
} from '../../theme/themeLightConfig';
import UtilNumber from '../../utils/UtilNumber';
import { useTranslation } from 'react-i18next';
import MyCollapse from '../../components/myCollapse/MyCollapse';
import UtilDate from '../../utils/UtilDate';
import useIsMounted from '@utilityjs/use-is-mounted/useIsMounted';
import StatisticsActions from '../../actions/StatisticsActions';
import MyProgressBar from '../../components/myProgressBar/MyProgressBar';
import UtilObject from '../../utils/UtilObject';
import { useSelector } from 'react-redux';
import CategoryCreateNewButton from './CategoryCreateNewButton';
import MyDivider from '../../components/myDivider/MyDivider';

const CategoryTotalsSection = (props) => {
  const { month, year, item, onChange, onDelete } = props;
  const [totals, setTotals] = useState({});
  const [loading, setloading] = useState(true);

  const canEdit = useSelector((state) => state.canEdit);

  const { t } = useTranslation();
  const isMounted = useIsMounted();

  const thisMonthName = UtilDate.getMonthLongName(month);

  const isNullItem = UtilObject.isEmpty(item);

  let daysLeft = 0;

  useEffect(() => {
    const getTotal = async () => {
      setloading(true);

      let result = await StatisticsActions.getScreenBudgetGetTotal({
        filter: { month, year },
      });

      if (isMounted()) {
        if (result.success) {
          setTotals(result.data);
        }
        setloading(false);
      }
    };
    getTotal();
  }, [month, year, isMounted]);

  const getItem = () => {
    return {
      key: '1',
      label: (
        <MySpace fullWidth spaceBetween>
          <MySpace>
            <MyText bold>{UtilNumber.formatDouble(totals?.valuePerDay)}</MyText>
            <MyTextSecondary> {t('WORD_PER_DAY')}</MyTextSecondary>
          </MySpace>
          <MySpace>
            <MyText bold>
              {UtilNumber.formatDouble(totals?.availableThis)}
            </MyText>
            <MyTextSecondary>{t('WORD_LEFT')}</MyTextSecondary>
          </MySpace>
        </MySpace>
      ),
      children: (
        <>
          <MyDivider />
          <MySpace direction="vertical" fullWidth size={2}>
            <MySpace fullWidth spaceBetween>
              <MyText>{t('WORD_AVILABLE_FROM_LAST_MONTH')}</MyText>
              <MyText style={{ color: successColor, fontWeight: 500 }}>
                {UtilNumber.formatDouble(totals?.availableUntil)}
              </MyText>
            </MySpace>
            <MySpace fullWidth spaceBetween>
              <MyText>{t('WORD_LEFT-OVER_FROM_LAST_MONTH')}</MyText>
              <MyText style={{ color: successColor, fontWeight: 500 }}>
                {UtilNumber.formatDouble(totals?.thisMonthStartingBalance)}
              </MyText>
            </MySpace>
            <MySpace fullWidth spaceBetween>
              <MyText>{`${t('WORD_INCOME_IN')} ${thisMonthName}`}</MyText>
              <MyText style={{ color: successColor, fontWeight: 500 }}>
                {UtilNumber.formatDouble(totals?.thisMonthIncome)}
              </MyText>
            </MySpace>
            <MySpace fullWidth spaceBetween>
              <MyText>{`${t('WORD_BUDGETED_IN')} ${thisMonthName}`}</MyText>
              <MyText style={{ color: successColor, fontWeight: 500 }}>
                {UtilNumber.formatDouble(totals?.thisMonthBudgeted)}
              </MyText>
            </MySpace>
            <MySpace fullWidth spaceBetween>
              <MyText>{`${t('WORD_OVER_SPENT_IN')} ${thisMonthName}`}</MyText>
              <MyText style={{ color: errorColor, fontWeight: 500 }}>
                {UtilNumber.formatDouble(totals?.overspentThisMonth)}
              </MyText>
            </MySpace>
          </MySpace>
        </>
      ),
    };
  };

  const getItemCategory = () => {
    return {
      key: '1',
      label: (
        <MySpace fullWidth spaceBetween>
          <MySpace>
            <MyText bold>
              {UtilNumber.formatDouble(
                item?.currentAvilable > 0 ? item?.currentAvilable / daysLeft : 0
              )}
            </MyText>
            <MyTextSecondary> {t('WORD_PER_DAY')}</MyTextSecondary>
          </MySpace>
          <MySpace>
            <MyText bold>
              {UtilNumber.formatDouble(item?.currentAvilable) || 0}
            </MyText>
            <MyTextSecondary>{t('WORD_LEFT')}</MyTextSecondary>
          </MySpace>
        </MySpace>
      ),
      children: (
        <>
          <MyDivider />
          <MySpace direction="vertical" fullWidth>
            <MySpace fullWidth spaceBetween>
              <MyText>{t('WORD_TRANSACTINS_THIS_MONTH')}</MyText>
              <MyText style={{ fontWeight: 500 }}>
                {item?.transactionCount || 0}
              </MyText>
            </MySpace>
            <MySpace fullWidth spaceBetween>
              <MyText>{t('WORD_AVILABLE_FROM_LAST_MONTH')}</MyText>
              <MyText style={{ fontWeight: 500 }}>
                {UtilNumber.formatDouble(item?.currentAvilableUntil) || 0}
              </MyText>
            </MySpace>
            <MySpace fullWidth spaceBetween>
              <MyText>{t('WORD_SPENT_THIS_MONTH')}</MyText>
              <MyText style={{ color: successColor, fontWeight: 500 }}>
                {UtilNumber.formatDouble(item?.currentBalance)}
              </MyText>
            </MySpace>
            {Boolean(item.overspentThisMonth < 0) && (
              <MySpace fullWidth spaceBetween>
                <MyText>{`${t('WORD_OVER_SPENT_IN')} ${thisMonthName}`}</MyText>
                <MyText style={{ color: errorColor, fontWeight: 500 }}>
                  {UtilNumber.formatDouble(item?.overSpentThisMonth)}
                </MyText>
              </MySpace>
            )}
            <MySpace fullWidth spaceBetween>
              <MyText>{t('WORD_SPENT_LAST_MONTH')}</MyText>
              <MyText style={{ fontWeight: 500 }}>
                {UtilNumber.formatDouble(item?.lastExpense) || 0}
              </MyText>
            </MySpace>
          </MySpace>
        </>
      ),
    };
  };

  return (
    <MyCard
      style={{
        width: 350,
      }}
      className="category-totals-card"
    >
      {!isNullItem && item?.idCategory ? (
        <>
          <MySpace fullWidth spaceBetween>
            <MyTextTitle size={14}>{item?.categoryName}</MyTextTitle>
            <MySpace>
              <MyTextSecondary>{t('WORD_SPENT')}</MyTextSecondary>
              <MyText bold>
                {UtilNumber.formatDouble(Math.abs(item?.currentBalance))}
              </MyText>
              <MyTextSecondary>{t('WORD_OF')}</MyTextSecondary>
              <MyText bold>
                {UtilNumber.formatDouble(item?.budgetValue)}
                {/* 000000 */}
              </MyText>
              {canEdit && (
                <CategoryCreateNewButton
                  item={item}
                  isNew={false}
                  onChange={onChange}
                  onDelete={onDelete}
                  isGroup={false}
                />
              )}
            </MySpace>
          </MySpace>
          <MyProgressBar
            percent={Math.abs((item?.currentBalance / item?.budgetValue) * 100)}
          />
          <MyCollapse
            key={'1'}
            expandIconPosition="end"
            items={[getItemCategory()]}
            defaultActiveKey={'1'}
            loading={loading}
          />
        </>
      ) : (
        <>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <MyTextTitle
              style={{ width: '27%' }}
              size={14}
            >{`${thisMonthName} ${t('WORD_OVERVIEW')}`}</MyTextTitle>
            <MySpace
              style={{
                flex: 1,
                justifyContent: 'end',
                alignItems: 'flex-start',
              }}
            >
              <MyTextSecondary>{t('WORD_SPENT')}</MyTextSecondary>
              <MyText bold>
                {UtilNumber.formatDouble(Math.abs(totals?.thisMonthSpent))}
              </MyText>
              <MyTextSecondary>{t('WORD_OF')}</MyTextSecondary>
              <MyText bold>
                {UtilNumber.formatDouble(totals?.thisMonthBudgeted)}
              </MyText>
            </MySpace>
          </div>
          <MyProgressBar
            totalSpent={totals?.thisMonthSpent}
            totalBudgeted={totals?.thisMonthBudgeted}
            color={primaryColor}
            style={{ marginTop: 4 }}
          />
          <MyCollapse
            key={'1'}
            expandIconPosition="end"
            items={[getItem()]}
            defaultActiveKey={'1'}
            loading={loading}
          />
          <MyDivider />
          <MySpace fullWidth spaceBetween>
            <MyText bold>{t('WORD_READY_TO_ASSIGN')}</MyText>
            <MyText bold>{UtilNumber.formatDouble(totals?.total)}</MyText>
          </MySpace>
        </>
      )}
    </MyCard>
  );
};

export default CategoryTotalsSection;
